<nav class="navbar navbar-expand-lg" [ngClass]="{'navbar-transparent': isTransparent && isCollapsed}">
  <ng-container *ngIf="isLoggedOn else NotLoggedIn">
    <a class="mr-auto" routerLink="/secure/kickarounds/list">
      <span *ngIf="isTransparent else ShowNormal">
        <img class="navbar-brand top-menu-logo-small" src="/assets/shared/icons/home.svg">
  <!--      <img class="navbar-brand top-menu-logo-small" fxHide.gt-sm src="../../../../..{{sportConfig.smallLogoSrc}}">-->
      </span>
      <ng-template #ShowNormal>
          <img class="navbar-brand top-menu-logo" src="../../../../..{{sportConfig.logoSrc}}">
      </ng-template>
    </a>
  </ng-container>
  <ng-template #NotLoggedIn>
    <a class="mr-auto" href="https://www.footballmatcher.com">
      <span *ngIf="isTransparent else ShowNormal">
        <img class="navbar-brand top-menu-logo-small" src="../../../../..{{sportConfig.smallLogoSrc}}">
      </span>
      <ng-template #ShowNormal>
          <img class="navbar-brand top-menu-logo" src="../../../../..{{sportConfig.logoSrc}}">
      </ng-template>
    </a>
  </ng-template>
  <button class="navbar-toggler"
          type="button"
          (click)="isCollapsed= !isCollapsed"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation">
    <span>
     <fa-icon [icon]="faBars" size="sm" class="menuBars"></fa-icon>
    </span>
  </button>
  <div class="collapse navbar-collapse" [ngbCollapse]="isCollapsed" id="navbarSupportedContent" >
    <ul class="navbar-nav ml-md-auto" *ngIf="!isLoggedOn" [class.show]="!isCollapsed" >
      <li class="nav-item">
        <a class="nav-link" routerLink="/auth/login" (click)="close()">Login</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/auth/register" (click)="close()">Sign Up</a>
      </li>
    </ul>
    <ul class="navbar-nav ml-md-auto" *ngIf="isLoggedOn">
      <li class="nav-item" *ngIf="newsMenuLoaded">
        <a class="nav-link" href="javascript:void(0)" id="newsfeed-launcher" >
          <div [matBadge]="numberOfUnread" matBadgeOverlap="false" class="demo-section" matBadgeSize="small" [matBadgeHidden]="numberOfUnread === 0"  fxHide.lt-md>
            <fa-icon [icon]="faBell" size="sm"></fa-icon>
          </div>
          <div
            [matBadge]="numberOfUnread"
            matBadge="4"
            matBadgeSize="small"
            matBadgeOverlap="false"
            fxHide.gt-sm
            matBadgePosition="before">
            Notifications
          </div>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/secure/kickarounds/list" (click)="close()" routerLinkActive="active-link">Your Groups</a>
      </li>
        <li class="nav-item">
        <a class="nav-link" routerLink="/secure/new-group" (click)="close()" routerLinkActive="active-link">Create Group</a>
      </li>
<!--      <li class="nav-item">-->
<!--        <a class="nav-link" routerLink="/secure/competitions" (click)="close()" routerLinkActive="active-link">Competitions</a>-->
<!--      </li>-->
<!--      <li class="nav-item">-->
<!--        <a class="nav-link" routerLink="/secure/teams/list" (click)="close()" routerLinkActive="active-link">Teams</a>-->
<!--      </li>-->
      <li class="nav-item dropdown" ngbDropdown *ngIf="isLoggedOn">
        <a class="nav-link dropdown-toggle" id="id02" [routerLink]="null" ngbDropdownToggle>
          {{firstName}}
        </a>
        <div class="dropdown-menu menu-align-right" ngbDropdownMenu  aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" routerLink="/secure/user/profile" (click)="close()">Your Profile</a>
          <a class="dropdown-item" routerLink="/secure/user/payments" (click)="close()">Your Payments</a>
          <a class="dropdown-item" routerLink="/secure/feature-requests" (click)="close()">Feature Requests</a>
          <a class="dropdown-item" routerLink="/" (click)="logOut(); close()">Logout</a>
        </div>
      </li>
    </ul>

  </div>
</nav>
