import {Injectable} from '@angular/core';
import {Router, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

import {Store} from '@ngxs/store';
import {Observable} from 'rxjs';

import {GetUserDetails} from '../state/user.actions';
import {catchError, map} from 'rxjs/operators';
import {SnackBarService} from '../../../shared/modules/announcements/snackbar/snack-bar.service';

@Injectable({
  providedIn: 'root',
})
export class UserDetailsResolver implements Resolve<any> {
  constructor(
    private router: Router,
    private store: Store,
    private snackbar: SnackBarService, ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    ): Observable<any>  {
    return this.store.dispatch(new GetUserDetails()).pipe(map(
      (r) => r,
      (e) => {
        this.snackbar.open('An error occured when logging in, please try again');
        this.router.navigate(['/user/login']);
      }));
  }
}

