import {Action, State, StateContext, Store} from '@ngxs/store';

import {User} from '../models/user.model';
import {Player} from '../models/player.model';
import {Feature} from '../models/feature.model';
import {tap} from 'rxjs/operators';
import {LoginService} from '../services/login.service';
import {
  ChangePassword,
  CloseAccount,
  GetUserDetails,
  LoginUser,
  Logout,
  RefreshTokenLogin,
  ReLoginUser,
  ResetAttemptedSecureUrl,
  SaveAttemptedSecureUrl,
  SetAffiliateToken,
  SetCampaignToken,
  SetGameAction,
  SetGoFMToken,
  SetPublicPlayer,
  SocialLoginUser,
  SocialLoginSignOut,
  StampCampaignAffilateOnPlayer,
  UpdatePlayerDetails,
  UploadProfileImage,
  ClearProfileImage,
  SaveSocialLoginPostAuthState,
  GetOTPCode,
  AuthOTPCode,
  UpdatePlayerDetailsViaPublicGamePlayerToken,
  SetDiscountCode,
  SetOrganisation
} from './user.actions';

import {ResponseError} from '../../../shared/state/fm-state.interfaces';
import {EmtpyResponseError} from '../../../shared/state/fm-state.constants';
import {formatCognitoError} from './user.functions';
import {LocalStorageService} from '../services/local-storage.service';
import {ProgressService} from '../../progress/services/progress.service';
import {CognitoService} from '../services/cognito.service';
import {SnackBarService} from '../../../shared/modules/announcements/snackbar/snack-bar.service';
import {extractErrorMessage} from '../../../shared/helpers/error_utility';
import {Injectable} from '@angular/core';
import {SocialLoginService} from '../services/social-login.service';
import {SocialLoginRedirectService} from '../services/social-login-redirect.service';

export interface UserStateModel {
  organisation: string;
  gameAction: string;
  gameGoFMToken: string;
  discountCode: string;
  affiliateToken: string;
  campaignToken: string;
  userType: string;
  // Public only
  gamePlayerId: number;
  gamePlayerToken: string;
  isLoggedOn: boolean;
  user: User;
  upvotyToken: string;
  features: Feature[];
  player: Player;
  idToken: string;
  hasAvailability: boolean;
  payoutMode: string;
  accessToken: string;
  saveRefreshToken: boolean;
  attemptedSecureUrl: string;
  error: ResponseError;
}

@Injectable()
@State<UserStateModel>({
  name: 'authState',
  defaults: {
    organisation: '',
    gameAction: null,
    gameGoFMToken: null,
    discountCode: null,
    affiliateToken: null,
    campaignToken: null,
    gamePlayerId: null,
    gamePlayerToken: null,
    isLoggedOn: false,
    upvotyToken: '',
    user: null,
    userType: 'Email',
    payoutMode: 'MANUAL',
    features: [],
    player: null,
    idToken: '',
    accessToken: null,
    hasAvailability: false,
    saveRefreshToken: true,
    attemptedSecureUrl: '',
    error: EmtpyResponseError,
  }
})
export class UserState {

  constructor(
    private loginService: LoginService,
    private socialLoginService: SocialLoginService,
    private socialLoginRedirectService: SocialLoginRedirectService,
    private localStorageService: LocalStorageService,
    private cognitoService: CognitoService,
    private store: Store,
    private progressService: ProgressService,
    private snackbarService: SnackBarService
  ) {
  }


  @Action(SocialLoginSignOut)
  socialLoginSignOut(ctx: StateContext<UserStateModel>, action: SocialLoginSignOut) {
    if (this.socialLoginService.loggedIn) {
      this.socialLoginService.signOut();
    }
  }

  @Action(SaveSocialLoginPostAuthState)
  saveSocialLoginPostAuthState(ctx: StateContext<UserStateModel>, action: SaveSocialLoginPostAuthState) {
    this.loginService.isLoggedOn = true;
    this.cognitoService.accessToken = action.tokens.access_token;
    this.cognitoService.idToken = action.tokens.id_token;
    this.cognitoService.refreshToken = action.tokens.refresh_token;
    this.localStorageService.saveRefreshToken(this.cognitoService.refreshToken);
    ctx.patchState({
      isLoggedOn: true,
      idToken: this.cognitoService.accessToken,
      accessToken: this.cognitoService.idToken,
      error: EmtpyResponseError,
    });
  }

  @Action(SocialLoginUser)
  socialLoginUser(ctx: StateContext<UserStateModel>, action: SocialLoginUser) {
    this.progressService.showLoadingSpinner('Logging in ...');
    this.localStorageService.saveValueForKey('postRedirectRoute', action.postRedirectRoute);
    this.localStorageService.saveValueForKey('postRedirectData', '' + JSON.stringify(action.postRedirectData));
    this.localStorageService.saveValueForKey('postRedirectAction', action.postRedirectAction);
    const isWww = this.isWww();
    this.socialLoginService.socialSignIn(action.provider, action.redirectRoute, isWww);
  }

  isWww() {
    try {
      return window.location.href.indexOf('www.') !== -1;
    } catch (exception) {
      return false;
    }
  }

  @Action(LoginUser)
  loginUser(ctx: StateContext<UserStateModel>, action: LoginUser) {
    this.progressService.showLoadingSpinner('Logging in ...');
    return this.loginService.login(action.user, action.saveRefreshToken).pipe(tap(
      (userData) => {
        ctx.patchState({
          isLoggedOn: true,
          idToken: userData.AuthenticationResult.IdToken,
          accessToken: userData.AuthenticationResult.AccessToken,
          error: EmtpyResponseError,
        });
        this.loginService.isLoggedOn = true;
        if (action.saveRefreshToken) {
          this.localStorageService.saveRefreshToken(userData.AuthenticationResult.RefreshToken);
        }
        this.progressService.hideLoadingSpinner();
      },
      (serverError) => {
        ctx.patchState({
          isLoggedOn: false,
          error: formatCognitoError(serverError)
        });
        this.progressService.hideLoadingSpinner();
      }));
  }


  @Action(ReLoginUser)
  reloginUser(ctx: StateContext<UserStateModel>, action: ReLoginUser) {
    this.progressService.showLoadingSpinner('Validating password ...');
    return this.loginService.login(action.user, action.saveRefreshToken).pipe(tap(
      (userData) => {
        ctx.patchState({
          isLoggedOn: true,
          idToken: userData.AuthenticationResult.IdToken,
          accessToken: userData.AuthenticationResult.AccessToken,
          error: EmtpyResponseError,
        });
        if (action.saveRefreshToken) {
          this.localStorageService.saveRefreshToken(userData.AuthenticationResult.RefreshToken);
        }
        this.progressService.hideLoadingSpinner();
      },
      (serverError) => {
        ctx.patchState({
          isLoggedOn: true,
        });
        this.progressService.hideLoadingSpinner();
      }));
  }

  @Action(UploadProfileImage)
  uploadProfileImage(ctx: StateContext<UserStateModel>, action: UploadProfileImage) {
    return this.loginService.uploadUserProfileImage(action.file).pipe(tap(
      (user) => {
        ctx.patchState({
          user: user,
          error: EmtpyResponseError,
        });
      },
      (serverError) => {
        ctx.patchState({
          error: formatCognitoError(serverError)
        });
      }
    ));
  }

  @Action(ClearProfileImage)
  clearProfileImage(ctx: StateContext<UserStateModel>, action: ClearProfileImage) {
    return this.loginService.uploadUserProfileImage('undefined').pipe(tap(
      (user) => {
        ctx.patchState({
          error: EmtpyResponseError,
          user: user
        });
      },
      (serverError) => {
        ctx.patchState({
          error: formatCognitoError(serverError)
        });
      }
    ));
  }


  @Action(RefreshTokenLogin)
  refreshTokenLogin(ctx: StateContext<UserStateModel>, action: RefreshTokenLogin) {
    return this.loginService.refreshTokenLogin().pipe(tap(
      (response) => {
        ctx.patchState({
          isLoggedOn: true,
          idToken: response.AuthenticationResult.IdToken,
          accessToken: response.AuthenticationResult.AccessToken,
          error: EmtpyResponseError,
        });
        // ctx.dispatch(new GetUserDetails());
      },
      (serverError) => {
        ctx.patchState({
          isLoggedOn: false,
          error: formatCognitoError(serverError)
        });
      }
    ));
  }

  @Action(GetUserDetails)
  getUserDetails(ctx: StateContext<UserStateModel>, action: GetUserDetails) {
    const state = ctx.getState();
    if (!state.player) {
      this.progressService.showLoadingSpinner('Getting user details ...');
      return this.loginService.getUserDetails().pipe(tap(
        (response) => {
          this.localStorageService.saveValueForKey('KEY_REFRESH_USER', response.user.email);
          this.cognitoService.userAttributes = response;
          window['fmPlayerId'] = response.playerId;
          ctx.patchState({
            payoutMode: response.payoutMode,
            userType: response.userType,
            user: response.user,
            upvotyToken: response.upvotyToken,
            player: response.player,
            features: response.features,
            hasAvailability: response.hasAvailability,
            error: EmtpyResponseError,
          });
        },
        (serverError) => {
          ctx.patchState({
            user: null,
            player: null,
            error: serverError['message']
          });
        },
        () => this.progressService.hideLoadingSpinner()
      ));
    } else {
      this.cognitoService.userAttributes = {
        features: state.features,
        user: state.user,
        player: state.player,
      };
    }

  }

  @Action(ChangePassword)
  changePassword(ctx: StateContext<UserStateModel>, action: ChangePassword) {
    this.progressService.showLoadingSpinner('Updating password ...');
    return this.loginService.changePassword(action.previousPassword, action.proposedPassword).pipe(tap(
      (response) => {
        this.snackbarService.open('Password updated successfully');
        this.progressService.hideLoadingSpinner();
      },
      (serverError) => {
        const error = formatCognitoError(serverError);
        this.snackbarService.open(error.message);
        this.progressService.hideLoadingSpinner();
      }
    ));
  }

  @Action(GetOTPCode)
  getUserOTPCode(ctx: StateContext<UserStateModel>, action: GetOTPCode) {
    return this.loginService.sendUserUpdateOTPCode(action.currency).pipe(tap(
      (response) => {
        this.snackbarService.open('Passcode code sent to your email');
      },
      (serverError) => {
        const error = formatCognitoError(serverError);
        this.snackbarService.open(error.message);
      }
    ));
  }

  @Action(AuthOTPCode)
  authUserOTPCode(ctx: StateContext<UserStateModel>, action: AuthOTPCode) {
    return this.loginService.authUserUpdateOTPCode(action.otp, action.currency).pipe(tap(
      (response) => {
        this.snackbarService.open('Passcode validated successfully');
      },
      (serverError) => {
        const error = formatCognitoError(serverError);
        this.snackbarService.open(error.message);
      }
    ));
  }

  @Action(UpdatePlayerDetails)
  updatePlayerDetails(ctx: StateContext<UserStateModel>, action: UpdatePlayerDetails) {
    const state = ctx.getState();
    this.progressService.showLoadingSpinner('Updating profile');
    return this.loginService.updatePlayerDetails(
      action.playerId,
      action.fullName,
      action.mobile,
      action.optInFMTrackNTrace,
      action.optInSuggestedKickaroundWhatsApps,
      action.optInGameNotificationsWhatsApps,
      action.optInSuggestedKickaroundEmails,
      action.optInFMNewsEmails,
    ).pipe(tap(
      (response) => {
        this.snackbarService.open('Updated details successfully');
        const player = state.player;
        this.progressService.hideLoadingSpinner();
        ctx.setState({
          ...state,
          player: {
            ...player,
            ...{
              fullName: action.fullName,
              mobile: action.mobile,
              ...(action.optInFMTrackNTrace != null) ? {optInFMTrackNTrace: action.optInFMTrackNTrace} : {},
              ...(action.optInFMNewsEmails != null) ? {optInFMNewsEmails: action.optInFMNewsEmails} : {},
              ...(action.optInSuggestedKickaroundWhatsApps != null) ? {optInSuggestedKickaroundWhatsApps: action.optInSuggestedKickaroundWhatsApps} : {},
              ...(action.optInGameNotificationsWhatsApps != null) ? {optInGameNotificationsWhatsApps: action.optInGameNotificationsWhatsApps} : {},
              ...(action.optInSuggestedKickaroundEmails != null) ? {optInSuggestedKickaroundEmails: action.optInSuggestedKickaroundEmails} : {},
              ...(action.optInFMNewsEmails != null) ? {optInFMNewsEmails: action.optInFMNewsEmails} : {},
            }
          }
        });
      },
      (serverError) => {
        this.progressService.hideLoadingSpinner();
        const error = serverError;
        this.snackbarService.open('Unable to update user details.');
      }
    ));
  }

  @Action(UpdatePlayerDetailsViaPublicGamePlayerToken)
  updatePlayerDetailsViaPublicGamePlayerToken(ctx: StateContext<UserStateModel>, action: UpdatePlayerDetailsViaPublicGamePlayerToken) {
    const state = ctx.getState();
    this.progressService.showLoadingSpinner('Updating profile');
    return this.loginService.updatePlayerDetailsViaPublicGamePlayerToken(
      action.kickaroundId,
      action.gameId,
      action.playerId,
      action.fullName,
      action.mobile,
      action.token,
    ).pipe(tap(
      (response) => {
        this.snackbarService.open('Updated details successfully');
        const player = state.player;
        this.progressService.hideLoadingSpinner();
        ctx.setState({
          ...state,
          player: {...player, ...{fullName: action.fullName, mobile: action.mobile}},
        });

      },
      (serverError) => {
        const error = serverError;
        this.progressService.hideLoadingSpinner();
        this.snackbarService.open('Unable to update user details.');
      }
    ));
  }

  @Action(CloseAccount)
  closeAccount(ctx: StateContext<UserStateModel>, action: CloseAccount) {
    const state = ctx.getState();
    this.progressService.showLoadingSpinner('Closing your account ...');
    return this.loginService.closeAccount().pipe(tap(
      (response) => {
        this.progressService.hideLoadingSpinner();
        this.snackbarService.open('Your account has been closed successfully.');

      },
      (serverError) => {
        this.progressService.hideLoadingSpinner();
      }
    ));
  }

  @Action(StampCampaignAffilateOnPlayer)
  stampCampaignAffilateOnPlayer(ctx: StateContext<UserStateModel>, action: StampCampaignAffilateOnPlayer) {
    const state = ctx.getState();
    this.progressService.showLoadingSpinner('Setting player campaign/affiliate code ...');
    return this.loginService.stampCampaignAffilateOnPlayer(action.affiliateToken, action.campaignToken).pipe(tap(
      (response) => {
        this.progressService.hideLoadingSpinner();
      },
      (serverError) => {
        this.progressService.hideLoadingSpinner();
        this.snackbarService.open(extractErrorMessage(serverError));
      }
    ));
  }


  @Action(Logout)
  logout(ctx: StateContext<UserStateModel>, action: Logout) {
    this.loginService.logoff();
    ctx.patchState({
      isLoggedOn: false,
      user: null,
      features: [],
      player: null,
      idToken: '',
      accessToken: null,
      saveRefreshToken: false,
      error: EmtpyResponseError
    });
  }

  @Action(SetPublicPlayer)
  setPublicPlayer(ctx: StateContext<UserStateModel>, action: SetPublicPlayer) {
    ctx.patchState({
      gamePlayerToken: action.token,
      gamePlayerId: action.playerId
    });
  }

  @Action(SaveAttemptedSecureUrl)
  saveAttemptedSecureUrl(ctx: StateContext<UserStateModel>, action: SaveAttemptedSecureUrl) {
    ctx.patchState({
      attemptedSecureUrl: action.attemptedUrl,
    });
  }

  @Action(ResetAttemptedSecureUrl)
  resetAttemptedSecureUrl(ctx: StateContext<UserStateModel>, action: ResetAttemptedSecureUrl) {
    ctx.patchState({
      attemptedSecureUrl: '',
    });
  }

  @Action(SetGoFMToken)
  setGoFMToken(ctx: StateContext<UserStateModel>, action: SetGoFMToken) {
    ctx.patchState({
      gameGoFMToken: action.goFMToken
    });
  }

  @Action(SetGameAction)
  setGameAction(ctx: StateContext<UserStateModel>, action: SetGameAction) {
    ctx.patchState({
      gameAction: action.gameAction
    });
  }

  @Action(SetCampaignToken)
  setCampaignToken(ctx: StateContext<UserStateModel>, action: SetCampaignToken) {
    ctx.patchState({
      campaignToken: action.campaignToken
    });
  }

  @Action(SetAffiliateToken)
  setAffiliateToken(ctx: StateContext<UserStateModel>, action: SetAffiliateToken) {
    ctx.patchState({
      affiliateToken: action.affiliateToken
    });
  }

  @Action(SetDiscountCode)
  setDiscountCode(ctx: StateContext<UserStateModel>, action: SetDiscountCode) {
    ctx.patchState({
      discountCode: action.discountCode
    });
  }

  @Action(SetOrganisation)
  setOrganisation(ctx: StateContext<UserStateModel>, action: SetOrganisation) {
    ctx.patchState({
      organisation: action.organisation
    });
  }
}
