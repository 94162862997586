import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {NgbCollapseModule, NgbNavModule, NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

import {TopMenuComponent} from './top/top-menu/top-menu.component';
import {TopSubmenuComponent} from './top/top-submenu/top-submenu.component';
import {TopBannerComponent} from './top/top-banner/top-banner.component';
import {FooterComponent} from './bottom/footer/footer.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatIconModule} from "@angular/material/icon";
import {MatBadgeModule} from "@angular/material/badge";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    FlexLayoutModule,
    MatIconModule,
    MatBadgeModule,
    NgbCollapseModule,
    NgbNavModule,
    NgbDropdownModule
  ],
  declarations: [
    TopMenuComponent,
    TopSubmenuComponent,
    TopBannerComponent,
    FooterComponent
  ],
  exports: [
    TopMenuComponent,
    TopSubmenuComponent,
    TopBannerComponent,
    FooterComponent
  ]
})
export class FMLayoutModule { }
