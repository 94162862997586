import {ChangeDetectionStrategy, Component, Input, SimpleChanges} from '@angular/core';
import {FMSportsDetectorService} from '../../../../shared/services/fm-sports-detector.service';
import {SportConfig} from '../../../../../environments/models/sport.model';

@Component({
  selector: 'fm-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressSpinnerComponent {
  @Input()
  show;

  @Input()
  message;

  // Selected Sports Config
  sportsConfig: SportConfig;

  constructor(public sportsDetectorService: FMSportsDetectorService) {
    this.sportsConfig = sportsDetectorService.getSportConfig();
  }

}
