import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpResponse,
} from '@angular/common/http';
import OpenReplay from '@openreplay/tracker'
import {environment} from "../../../environments/environment";
import type {Options as NetworkOptions} from "@openreplay/tracker/lib/modules/network";

type ReqRespType = {
  request: HttpRequest<any>,
  response: HttpResponse<any>
}

const networkOptions: NetworkOptions = {
  captureInIframes: false,
  failuresOnly: false,
  ignoreHeaders: undefined,
  sessionTokenHeader: undefined,
  capturePayload: true
};

@Injectable({
  providedIn: 'root'
})
export class ReplaySessionService {
  tracker: OpenReplay|null = null

  constructor() {

    this.tracker = new OpenReplay({
        projectKey: environment.openReplayKey,
        network: networkOptions,
    })
    //you can set up any other OR plugins here as well

    this.tracker.start()
   }

  sendEventToReplaySession(event: string, params: ReqRespType): void {
    const {request, response} = params

    this.tracker?.event(event + "[request]", {
      method: request.method,
      url: request.url,
      params: request.params
    })
    this.tracker?.event(event + "[response]", {
      body: response.body,
      status: response.status,
      headers: response.headers
    })
  }
}
