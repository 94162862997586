import {ErrorHandler, Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {Store} from '@ngxs/store';
import {User} from '../../features/user/models/user.model';
import {Player} from '../../features/user/models/player.model';
import {environment} from '../../../environments/environment';

@Injectable()
export class FMErrorHandlerService extends ErrorHandler {
  loggedInUser: User;
  loggedInPlayer: Player;

  constructor(private logger: NGXLogger) {
    super();
  }

  handleError(error) {
    if (environment.serverLogLEveryError) {
      this.logger.error(error);
    }
    super.handleError(error);
  }
}
