import {AcceptDeclineGameResponse, GamePlayerStatusChoices} from "./kickarounds.enums";

export function mapDateIdx(idx: number) {
  switch (idx) {
    case 0:
      return '6';
    case 1:
      return '0';
    case 2:
      return '1';
    case 3:
      return '2';
    case 4:
      return '3';
    case 5:
      return '4';
    case 6:
      return '5';
    default:
      return '-1';
  }
}

export function  convertLocationToString(locationFields) {
    let locationLabel = '';
    const includedFields = ['name', 'townCity', 'postcode'];
    for (const key in locationFields) {
      if (includedFields.indexOf(key) !== -1) {
        const fieldStr = locationFields[key].toLowerCase();
        locationLabel += (fieldStr !== ' ') && (fieldStr !== '') && (fieldStr !== 'undefined') && (fieldStr !== null) ? fieldStr + ', ' : '';
      }
    }
    return locationLabel.slice(0, -2);
}


export function mapRSVPAction(action: AcceptDeclineGameResponse) {
  switch (action) {
    case AcceptDeclineGameResponse.confirm:
      return 'Confirm';
    case AcceptDeclineGameResponse.decline:
      return 'Decline';
    case AcceptDeclineGameResponse.unsubscribe:
      return 'Unsubscribe';
    case AcceptDeclineGameResponse.lookup:
      return 'Check Numbers';
  }
}

export function mapGamePlayerStatus(status: GamePlayerStatusChoices) {
  switch (status) {
    case GamePlayerStatusChoices.accepted:
      return 'Attending';
    case GamePlayerStatusChoices.declined:
      return 'Notattending';
    case GamePlayerStatusChoices.waiting:
      return 'Waiting';
    case GamePlayerStatusChoices.waiting:
      return 'Invited';
    case GamePlayerStatusChoices.needReplacement:
      return 'Pendingreplacement';
  }
}
