import {
  autoserialize,
  serialize,
  deserialize,
  deserializeAs,
  autoserializeAs, serializeAs,
} from 'cerialize';

import {FMLocation} from '../../../location/models/location.model';
import {OpenDataSite} from '../../../location/models/open-data-site.model';
import {PricingModel} from '../../../payments/models/database/pricing.model';
import {KickaroundGame} from './kickaround-game.model';
import {KickaroundPlayer} from './kickaround-player.model';

export class SuggestedKickaround {
  // Read Only
  @deserialize id: number;
  @deserialize token: string;
  @deserialize kickaroundName: string;
  @deserialize venueName: string;
  @deserialize townCity: string;
  @deserialize postCode: string;
  @deserialize level: string;
  @deserialize frequency: string;
  @deserialize gender: string;
  @deserialize pitchType: string;
  @deserialize pitchPlayersPerSide: number;
  @deserialize price: string;
  @deserialize currency: string;
  @deserialize paymentMode: string;
  @deserialize finalPerPlayerPriceMax: number;
  @deserialize finalPerPlayerPriceMin: number;
  @deserialize fixedPlayerPrice: number;
  @deserialize maxRequiredPlayers: number;
  @deserialize minRequiredPlayers: number;
  @deserialize playerId: number;
  @deserialize kickaroundDistanceKm: number;
  @deserialize kickaroundDayOfWeek: string;
  @deserialize kickaroundStartTime: string;
  @deserialize firstName: string;
  @deserialize lastName: string;
  @deserialize email: string;
  @deserialize playerMaxDistanceKm: number;
  @deserialize gofmLink: string;
  @deserialize kickaroundMatchedTime: string;
  @deserialize playerMatchedTime: string;
  @deserialize kickaroundAddress1: string;
  @deserialize gameNumberOfPayments: number;
  @deserialize gameNumberOfAcceptedPlayers: number;
  @deserialize gameStatus: string;
  @deserializeAs(Date) @serialize gameStartTime: Date | string;
  @deserializeAs(Date) @serialize gameInviteSendDatetime: Date | string;

}
