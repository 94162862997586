import { Component, OnInit } from '@angular/core';
import {FMSportsDetectorService} from '../../../../shared/services/fm-sports-detector.service';
import {SportConfig} from '../../../../../environments/models/sport.model';

@Component({
  selector: 'fm-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  // Selected Sports Config
  sportConfig: SportConfig;

  constructor(public sportsDetectorService: FMSportsDetectorService) {
    this.sportConfig = sportsDetectorService.getSportConfig();
  }

  ngOnInit() {
  }

}
