import {Inject, Injectable, OnDestroy} from '@angular/core';
import {NavigationEnd, Router, RouterEvent} from '@angular/router';
import {Subscription} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {WINDOW} from '../../shared/services/fm-windows.service';
import {filter} from 'rxjs/operators';
import {GTAG_CODE} from './fm-analytics.constants';
import {FMASignupMethod} from './fm-analytics.enums';
import {environment} from "../../../environments/environment";
import {MixpanelService} from "./mixpanel.service";
import {TrackingEvent} from "./mixpanel.constants";
declare const heap;


@Injectable({
  providedIn: 'root'
})
export class FMAnalyticsService implements OnDestroy {

  // Google Analytics
  private gtag: any;

  // Facebook Pixel
  private fbq: any;

  // Hubspot
  private hsq: any;

  // Hotjar
  private hj: any;

  private subscription: Subscription;
  private eventPrefix: string;
  private brevo: any;

  constructor(private router: Router, private logger: NGXLogger, @Inject(WINDOW) private window, private mixpanelService: MixpanelService) {
    this.logger.info('FM ANALYTICS CONTRUCTOR');

    // Subscribe to router events
    this.subscription = router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).subscribe((routerEvent: RouterEvent) => {
        this.recordURLChangeEvent(routerEvent.url);
      });

    // Get Analytics objects
    this.gtag = window['gtag'];
    this.fbq = window['fbq'];
    this.hj = window['hj'];
    this.brevo = window['sendinblue'];
    this.hsq = window._hsq = window._hsq || [];
  }

  init() {
    this.mixpanelService.init()
  }

  // Used for funnels where the location doesn't change
  recordFunnelStep(funnelName: string, stepName: string) {
    try {
      const virtual_url = `/${funnelName}/${stepName}/`;
      if (this.hj !== undefined) {
        this.hj('vpv', `/${funnelName}/${stepName}/`);
      }

      // Google Analytics Page Tracking
      if (this.gtag !== undefined) {
        this.gtag('config', GTAG_CODE, {
          'page_path': virtual_url
        });
      }
      this.logger.debug('recordFunnelStep', virtual_url);
    } catch (e) {
      this.logger.error('Unable to send recordFunnelStep event: ', funnelName, stepName);
    }
  }

  private recordURLChangeEvent(url: string) {
    try {
      // Check if secure part of app (s = secure, p = public)
      this.eventPrefix = url.includes('secure') ? 's-' : 'p-';

      // Hubspot Page Tracking
      if (this.hsq !== undefined) {
        this.hsq.push(['setPath', url]);
        this.hsq.push(['trackPageView']);
      }

      // Google Analytics Page Tracking
      if (this.gtag !== undefined) {
        this.gtag('config', GTAG_CODE, {
          'page_path': url
        });
      }

      // Facebook Pixel
      if (this.fbq !== undefined) {
        this.fbq('track', 'PageView');
      }
      this.logger.debug('recordURLChangeEvent: ', url);
    } catch (e) {
      this.logger.error('Unable to send recordURLChangeEvent event: ', url);
    }
  }

  recordEvent(category: string, action: string, eventData?:any ) {
    try {
      const fullCategory = this.eventPrefix + category;
      const fullAction = this.eventPrefix + action;

      if (this.fbq !== undefined) {
        // facebook analytics event
        this.fbq('trackCustom', fullAction, {
          content_category: fullCategory,
        });
      }

      if (this.gtag !== undefined) {
        this.gtag('event', fullAction, {
          event_category: fullCategory,
        });
      }
      this.logger.debug('recordEvent: ', fullAction, fullCategory);
    } catch (e) {
      this.logger.error('Unable to send recordEvent event: ', category, action);
    }
  }

  // Sign non reg sign up
  recordSignUp(method: FMASignupMethod) {
    try {
      if (this.fbq !== undefined) {
        // facebook analytics event
        this.fbq('track', 'CompleteRegistration', {'content_name': method});
      }
      if (this.gtag !== undefined) {
        this.gtag('event', 'sign_up', { 'method': method});
      }
      this.logger.debug('recordSignUp', method);
    } catch (e) {
      this.logger.error('Unable to send recordSignUp event: ', method);
    }
  }

  recordPurchase(category: string, action: string,  value: number) {
    const fullCategory = this.eventPrefix + category;
    const fullAction = this.eventPrefix + action;

    if (this.gtag !== undefined) {
      // google analytics events
      this.gtag('event', 'purchase', {
        event_category: fullCategory,
        event_label: fullAction,
        value: value,
      });
    }

     if (this.fbq !== undefined) {
     this.fbq('track', 'Purchase', {
       content_category: category,
       currency: 'GBP',
       value: value
     });
    }
   this.logger.debug('recordPurchase', action, category, value);
  }

  recordBrevoEvent(event_name: string, event_data) {
    try {
      const event = {
        "data": event_data
    }

      if (this.brevo !== undefined) {
        // brevo analytics event
        this.brevo.track(
        event_name,
        event)
      }

      this.logger.debug('recordEvent: ', event_name);
    } catch (e) {
      this.logger.error('Unable to send recordEvent event: ', event_name);
    }
  }

  identifyUser(userId: string) {
    this.mixpanelService.identify(userId);
  }

  recordV2Event(event: TrackingEvent) {
    this.mixpanelService.track(event.name, event.properties)
    if (environment.name == 'prod') {
      heap.track(event, event.properties)
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }



}
