import {IDMap} from '../interfaces/idmap.interface';

import {isNil} from 'lodash-es';
import {keyBy} from 'lodash-es';
import {omitBy} from 'lodash-es';
import { diff } from 'deep-object-diff';

/* Removes nulls, undefines and empty objects */
function cleanObject(obj) {
  return omitBy(obj, isNil);
}

/***
 * Calculates the difference between the existing and override object, then only updates the different key, values on the existing object
 * with the key, values from the override. By Default ignores null & undefined values.
***/
export function partiallyUpdateObject(existing: any, override: any, primaryKeyField= 'id', removeNill = true) {
  let newObject;
  if ((override && primaryKeyField in override) || (existing && (primaryKeyField in existing))) {

    // If updating existing entry
    if (existing) {

      if (existing.version && override && override.version) {
        if ( override.version < existing.version) {
          return existing;
        }
      }

      // Only update differences
      const difference = diff(existing, override);
      const cleaned = (removeNill) ? cleanObject(difference) : difference;

      // Combine objects
      newObject = {...existing, ...cleaned};

    // just add new item
    } else {
      return newObject = override;
    }
  } else {
    throw Error(`All override objects should have ${primaryKeyField} field when using partiallyUpdateArray function`);
  }
  return newObject;
}

/***
 Function adds/updates object stored in state with only different values that are in the override object. Keeps all other field references
 the same when updating. The remove nill option will remove any null or undefined values for the override object.
***/
export function   updateIdMap<Type>(existingStateIdMap: IDMap<Type>, id: number, override: any, primaryKeyField= 'id', removeNill = true): IDMap<Type> {
  const idMap: IDMap<Type> = {};

  if (existingStateIdMap === undefined) {
    existingStateIdMap = {};
  }

  // Update existing
  if (id in existingStateIdMap) {

    // Get current object from state
    const original: any = existingStateIdMap[id];

    // Create new ID map with original
    idMap[id] = partiallyUpdateObject(original, override, primaryKeyField);

    return {...existingStateIdMap, ...idMap};

  // Add new to existing ID MAP
  } else {
    idMap[id] = override;
    return {...existingStateIdMap, ...idMap};
  }
}

/***
 Function adds/updates an array objects stored in state with only different values that are in the override object. Keeps all other field references
 the same when updating. The remove nill option will remove any null or undefined values for the override object.
***/
export function bulkUpdateIdMap<Type>(existingStateIdMap: IDMap<Type>, overrideArray: any[], primaryKeyField= 'id', removeNill = true): IDMap<Type> {
  const existingIdMap = existingStateIdMap;
  const idMap = {};

  // Loop through keys of difference
  overrideArray.forEach(item => {

    // Check if item has id
    if (item[primaryKeyField]) {

      // If override is an update to existing object in state idmap.
      if (item[primaryKeyField] in existingStateIdMap) {
        idMap[item[primaryKeyField]] = partiallyUpdateObject(existingStateIdMap[item[primaryKeyField]], item, primaryKeyField, removeNill);
      } else {
        idMap[item[primaryKeyField]] = partiallyUpdateObject(null, item, primaryKeyField, removeNill);
      }
    } else {
      throw Error(`All override objects should have ${primaryKeyField} field when using partiallyUpdateArray function`);
    }
  });
  return {...existingIdMap, ...idMap};
}

export function genShowLoadingState(msg) {
  return {
    isLoading: true,
    message: `${msg}...`,
    showProgress: true
  };
}

export function genHideLoadingState() {
  return {
    isLoading: false,
    message: '',
    showProgress: false
  };
}


/***
 * Loops through an object array, partially updating each object with an override. Objects must have an id field.
***/
export function partiallyUpdateArray(existingArray: any[], overrideArrayOrObject: any[] | any, primaryKeyField= 'id', removeNill = true) {
  // Convert existing array to ID Map
  const existingIdMap = keyBy(existingArray, primaryKeyField);

  // Convert object to an array of objects
  const overrideArray = (Array.isArray(overrideArrayOrObject)) ? overrideArrayOrObject : [overrideArrayOrObject, ];

  // Convert to id map
  const updatedIdMap = bulkUpdateIdMap(existingIdMap, overrideArray, primaryKeyField, removeNill);
  return Object.values(updatedIdMap);
}

