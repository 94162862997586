import {deserializeAs, deserialize} from 'cerialize';
import {FormControl} from '@angular/forms';

export class Player {
  @deserialize id: number;
  @deserialize user?: number;
  @deserialize userId?: number;
  @deserialize fullName: string;
  @deserialize email: string;
  @deserialize registeredForPayments = false;
  @deserialize profilePicHiRes: string;
  @deserialize profilePicLowRes: string;
  @deserialize mobile: string;
  @deserialize optInFMTrackNTrace: boolean;
  @deserialize optInSuggestedKickaroundWhatsApps?: boolean;
  @deserialize optInGameNotificationsWhatsApps?: boolean;
  @deserialize optInSuggestedKickaroundEmails?: boolean;
  @deserialize optInFMNewsEmails?: boolean;
  @deserialize playerAffiliateCode: string;

  /* Auto generated constructor from IDE */
  constructor(id: number, user: number, userId: number, fullName: string, email: string, registeredForPayments: boolean, profilePicHiRes: string, profilePicLowRes: string, mobile: string, optInFMTrackNTrace?: boolean, optInSuggestedKickaroundWhatsApps?: boolean, optInGameNotificationsWhatsApps?: boolean, optInSuggestedKickaroundEmails?: boolean, optInFMNewsEmails?: boolean,) {
    this.id = id;
    this.user = user || userId;
    this.userId = userId || user;
    this.fullName = fullName;
    this.email = email;
    this.registeredForPayments = registeredForPayments;
    this.profilePicHiRes = profilePicHiRes;
    this.profilePicLowRes = profilePicLowRes;
    this.mobile = mobile;
    this.optInFMTrackNTrace = optInFMTrackNTrace;
    this.optInSuggestedKickaroundWhatsApps = optInSuggestedKickaroundWhatsApps;
    this.optInGameNotificationsWhatsApps = optInGameNotificationsWhatsApps;
    this.optInSuggestedKickaroundEmails = optInSuggestedKickaroundEmails;
    this.optInFMNewsEmails = optInFMNewsEmails;
  }
}
