import {createSelector, Selector, Store} from '@ngxs/store';
import {KickaroundSecureStateModel} from '../interfaces/kickaround-secure-state-model.interface';
import {filterIDMap} from '../../../../shared/helpers/idmap.functions';
import {KickaroundSecureState} from '../states/kickarounds-secure.state';
import {KickaroundPermissions} from '../../models/database/kickaroundPermissions.model';

export class KickaroundsSecureSelectors {

  constructor(private store: Store) {}

  @Selector([KickaroundSecureState])
  static uiSuggestedKickarounds(state: KickaroundSecureStateModel) {
    return state.uiSuggestedKickarounds;
  }

  @Selector([KickaroundSecureState])
  static isSuggestedKickaroundTabOpen(state: KickaroundSecureStateModel) {
    return state.uiSuggestedKickarounds.suggestedKickaroundTabOpen;
  }

  @Selector([KickaroundSecureState])
  static playersIdMap(state: KickaroundSecureStateModel) {
    return state.dbPlayers;
  }

  @Selector([KickaroundSecureState])
  static suggestedKickarounds(state: KickaroundSecureStateModel) {
    return state.dbSuggestedKickarounds;
  }

  @Selector([KickaroundSecureState])
  static suggestedKickaroundsLoaded(state: KickaroundSecureStateModel) {
    return state.dbSuggestedKickaroundDataLoaded;
  }

  @Selector([KickaroundSecureState])
  static updateTimeStamp(state: KickaroundSecureStateModel) {
    return state.uiUpdateTimeStamp;
  }

  @Selector([KickaroundSecureState])
  static uiJoinPage(state: KickaroundSecureStateModel) {
    return state.uiJoinPage;
  }

  /* All players the user is associated with */

 @Selector([KickaroundSecureState])
  static payments(state: KickaroundSecureStateModel) {
    return Object.values(state.dbPayments);
  }

  /* All kickarounds the user is associated with */
  @Selector([KickaroundSecureState])
  static allKickarounds(state: KickaroundSecureStateModel) {
    return Object.values(state.dbKickarounds);
  }

  /* All kickarounds the user is associated with */
  @Selector([KickaroundSecureState])
  static allKickaroundPlayers(state: KickaroundSecureStateModel) {
    return Object.values(state.dbKickaroundPlayers);
  }


  /* All games the user is associated with */
  @Selector([KickaroundSecureState])
  static allKickaroundGames(state: KickaroundSecureStateModel) {
    return Object.values(state.dbKickaroundGames);
  }

  /* All latestGame players the user is associated with */
  @Selector([KickaroundSecureState])
  static allKickaroundGamePlayers(state: KickaroundSecureStateModel) {
    return Object.values(state.dbKickaroundGamePlayers);
  }

  /* Either return all players or just players that belong to kickaround */
  static players(kickaroundId?: number) {
    return createSelector([KickaroundSecureState], (state: KickaroundSecureStateModel) => {
      // filter by kickaround
      if (kickaroundId) {
        const gamePlayers = filterIDMap(state.dbKickaroundPlayers, 'kickaroundId', kickaroundId);
        return gamePlayers.map(gp => state.dbPlayers[gp.playerId]);
      } else {
        return Object.values(state.dbPlayers);
      }
    });
  }

  /* Get logged users  permissions for kickaround */
  static kickaroundPermissions(kickaroundId: number) {
    return createSelector([KickaroundSecureState], (state: KickaroundSecureStateModel) => {
      if (kickaroundId in state.dbKickaroundPermissions) {
        return state.dbKickaroundPermissions[kickaroundId];
      }
      const permissions: KickaroundPermissions = {hasGameHostControls: false};
      return permissions;
    });
  }

  /* Kickaround Players that belong to each kickaround the usser is associated with*/
  static kickaroundPlayers(kickaroundId: number) {
    return createSelector([KickaroundSecureState], (state: KickaroundSecureStateModel) => {
      return filterIDMap(state.dbKickaroundPlayers, 'kickaroundId', kickaroundId);
    });
  }

  static uiKickaroundGame(kickroundId: number, gameId: number) {
    return createSelector([KickaroundSecureState], (state: KickaroundSecureStateModel) => {
      return state.uiGame[gameId];
    });
  }

  /* Returns fmLocation */
  static location(locationId: number) {
    return createSelector([KickaroundSecureState], (state: KickaroundSecureStateModel) => {
      return state.dbLocations[locationId];
    });
  }

  static openDataSite(openDateSiteId: number) {
    return createSelector([KickaroundSecureState], (state: KickaroundSecureStateModel) => {
      return state.dbOpenDataSites[openDateSiteId];
    });
  }

  /* Returns kickaround latestGame */
  static kickaround(kickaroundId: number) {
    return createSelector([KickaroundSecureState], (state: KickaroundSecureStateModel) => {
      return state.dbKickarounds[kickaroundId];
    });
  }

  /* Returns kickaround latestGame */
  static kickaroundGame(gameId: number) {
    return createSelector([KickaroundSecureState], (state: KickaroundSecureStateModel) => {
      return state.dbKickaroundGames[gameId];
    });
  }

  /* Returns kickaround latestGame players */
  static kickaroundGamePlayers(gameId: number) {
    return createSelector([KickaroundSecureState], (state: KickaroundSecureStateModel) => {
      return filterIDMap(state.dbKickaroundGamePlayers, 'gameId', gameId);
    });
  }

  /* Get logged in player */
  static loggedInGamePlayer(gameId: number, playerId: number) {
    return createSelector([KickaroundSecureState], (state: KickaroundSecureStateModel) => {
      return filterIDMap(state.dbKickaroundGamePlayers, 'gameId', gameId).find(gp => gp.playerId === playerId);
    });
  }
}
