import {Injectable, OnDestroy} from '@angular/core';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {SwUpdate} from '@angular/service-worker';

import {environment} from '../../../environments/environment';
import {NGXLogger} from 'ngx-logger';
import {HttpClient} from '@angular/common/http';
import {Subscription} from 'rxjs';


/** Forces reload of service worker if new version of the web app is available **/
@Injectable()
export class FMSWUpdaterService implements OnDestroy {
  subscription: Subscription;

  constructor(private swUpdate: SwUpdate, private snackbar: MatSnackBar, private logger: NGXLogger, private http: HttpClient) {
    // Disabled
    // this.checkAndUpdateApp();
  }

  async checkAndUpdateApp() {
    const swConfig = await this.getSWConfig();
    const swUpdateMethod = swConfig['sw-update-method'];

    this.logger.info('Init SW worker updater');
    this.logger.info(`SW Updating method = ${swUpdateMethod}`);

    this.subscription = this.swUpdate.available.subscribe(evt => {
      this.logger.info('New version of web app detected.');

      // If new version detect, force the update
      if (swUpdateMethod === 'force') {
        this.logger.info('Version contains breaking changes so forcing reload.');
        this.snackbar.open('Updating App');
        window.location.reload();

      // Otherwise, ask the user if they want the updated version
      } else if (swUpdateMethod  === 'optional') {
        this.logger.info('Version contains optional major changes.');
        const snack = this.snackbar.open('Update Available', 'Reload', { duration: 6000 });

        snack
          .onAction()
          .subscribe(() => {
            window.location.reload();
          });
      } else if (swUpdateMethod === 'onNextLoad') {
        this.logger.info('Version contains minor changes, will wait for next load to update.');
      }
    });
  }

  getSWConfig(): Promise<any> {
    return this.http.get<any>
    (`/assets/config/sw-config.json`)
      .toPromise().then(config => {
        this.logger.info('Retrieved sw config for assets/config ');
        return config;
      }).catch(e => {
          this.logger.error('Unable to retrieve service worker config from assets/config. Defaulting to optional update.');
          return { 'sw-update-method': 'optional' };
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
