import {Component} from '@angular/core';

@Component({
  selector: 'fm-public',
  templateUrl: './public.component.html',
})
export class PublicComponent {
  constructor() {

  }


}
