import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {LocalStorageService} from './local-storage.service';
import {
  CreateKickaround,
  CreateNextGame,
  GetKickaroundUserData
} from '../../kickarounds/store/actions/kickarounds-secure.actions';
import {SnackBarService} from '../../../shared/modules/announcements/snackbar/snack-bar.service';
import {ProgressService} from '../../progress/services/progress.service';
import {ActivatedRoute, Router} from '@angular/router';
import {GetUserDetails} from '../state/user.actions';

@Injectable({
  providedIn: 'root'
})
export class SocialLoginRedirectService {
  constructor(private store: Store,
              private snackBar: SnackBarService,
              private route: ActivatedRoute,
              private router: Router,
              private loadingProgressService: ProgressService,
              private localStorageService: LocalStorageService) {
  }


  async processSocialLoginPostRedirectWorkflow(): Promise<any> {
    const postRedirectRoute = this.localStorageService.getValueForKey('postRedirectRoute');
    const postRedirectAction = this.localStorageService.getValueForKey('postRedirectAction');
    const postRedirectData = this.localStorageService.getValueForKey('postRedirectData');
    this.clearRedirectState();

    switch (postRedirectAction) {
      case 'RedirectToKickaroundList':
        return {'redirectRoute': ['/secure', 'kickarounds', 'list'], 'extras': {}};
      case 'ActivatePendingKickaround':
        await this.store.dispatch(new GetUserDetails());
        const savedKickaroundPlusData = await this.postSocialLoginKickaroundSave(postRedirectData);
        const redirectData = await this.postSocialLoginKickaroundGameSave(savedKickaroundPlusData.data, savedKickaroundPlusData.savedKickaround);
        return {
          'redirectRoute': redirectData.redirectRoute,
          'extras': {
            queryParams: {
              kickaroundId: savedKickaroundPlusData.savedKickaround.id,
              gofmlink: savedKickaroundPlusData.savedKickaround.gofmLink
            }
          }
        };
      case 'KickaroundSignup':
        const data = JSON.parse(postRedirectData);
        const postRedirectRouteArray = postRedirectRoute.split('/');
        return {'redirectRoute': postRedirectRouteArray, 'extras': {queryParams: {inviteToken: data.token}}};
      case 'RedirectToPaymentCardDetails':
        const data2 = JSON.parse(postRedirectData);
        const postRedirectRouteArray2 = postRedirectRoute.split('/');
        return {'redirectRoute': postRedirectRouteArray2, 'extras': {queryParams: {context: data2.context}}};
      case 'ConfirmInviteSecure':
        const data3 = JSON.parse(postRedirectData);
        const postRedirectRouteArray3 = postRedirectRoute.split('/');
        return {'redirectRoute': postRedirectRouteArray3, 'extras': {queryParams: {action: data3.action}}};
      case 'RedirectToPlayerAvailability':
        return {'redirectRoute': ['/secure', 'kickarounds', 'list'], 'extras': {}};
      default:
        return {'redirectRoute': ['/secure', 'kickarounds', 'list'], 'extras': {}};
    }
  }

  clearRedirectState() {
    this.localStorageService.removeValueForKey('postRedirectAction');
    this.localStorageService.removeValueForKey('postRedirectData');
    this.localStorageService.removeValueForKey('postRedirectRoute');
  }

  async postSocialLoginKickaroundSave(kickaroundPlusNewGameDate): Promise<any> {
    const data = JSON.parse(kickaroundPlusNewGameDate);
    data.nextGameDate = new Date(data.nextGameDate);
    return await this.store.dispatch(new CreateKickaround(data.newKickaround)).toPromise()
      .then((newState) => {
        const savedKickaroundId = newState.secureKickarounds.uiCreatedKickaroundId;
        if (savedKickaroundId === undefined) {
          return undefined;
        }
        return {savedKickaround: newState.secureKickarounds.dbKickarounds[savedKickaroundId], data: data};
      })
      .catch(e => {
        console.error(e);
        return undefined;
      });
  }

  async postSocialLoginKickaroundGameSave(data, savedKickaround) {
    if (!data.nextGameDate) {
      this.snackBar.open('Kickaround created successfully');
      return {
        'redirectRoute': ['/secure', 'kickarounds', savedKickaround.id, 'created'],
        'extras': {queryParams: {gofmlink: savedKickaround.gofmLink}}
      };
    } else {
      // Create next latest game
      if (data.nextGameDate) {
        return await this.store.dispatch(new CreateNextGame(savedKickaround.id, data.nextGameDate)).toPromise().then(() => {
          return this.store.dispatch(new GetKickaroundUserData(true, true)).toPromise().then(() => {
            this.snackBar.open('Latest game created successfully');
            this.loadingProgressService.hideLoadingSpinner();
            return {
              'redirectRoute': ['/secure', 'kickarounds', savedKickaround.id, 'created'],
              'extras': {queryParams: {gofmlink: savedKickaround.gofmLink}}
            };
          });
        }, (e) => {
          this.loadingProgressService.hideLoadingSpinner();
          console.error(e);
          return {'redirectRoute': ['/secure', 'kickarounds', 'list'], 'extras': {}};
        });
      }
    }
  }

}
