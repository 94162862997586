import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';

// import * as Sentry from '@sentry/browser';
import {Observable, of} from 'rxjs';
import {catchError, tap, flatMap, map} from 'rxjs/operators';
import {LocalStorageService} from './local-storage.service';
import {CognitoAction, CognitoAuthFlow, CognitoService} from './cognito.service';
import {User} from '../models/user.model';
import {environment} from '../../../../environments/environment';
import {UserDetails} from '../models/user-details.model';
import {SRPAuthService} from './srp-auth-service';
import DeviceAuthService from './device-auth-service';
import {NGXLogger} from 'ngx-logger';
import {MOCK_USER_DATA} from './mock/mock-user-data';

@Injectable({
  providedIn: 'root',
})
export class LoginService implements OnInit {
  public isLoggedOn = false;
  public refreshTokenAuth = false;
  public nonConfirmedUser: User;
  public apiUrl;
  public baseUrl;
  public postLoginSuccessSnackBarText = 'Login Successful, redirecting to your kickarounds';

  constructor(private http: HttpClient,
              private srpAuthService: SRPAuthService,
              private deviceAuthService: DeviceAuthService,
              private localStorageService: LocalStorageService,
              private cognitoService: CognitoService,
              private logger: NGXLogger,
  ) {
    this.apiUrl = environment.api;
    this.baseUrl = environment.baseUrl;
  }

  registerCognitoUserContext(email: string, operation: string, context: string, redirectUrl: string): Observable<any> {
    const endpoint = `/public/users/registerCognitoUserContext`;
    const data = {
      'email': email,
      'operation': operation,
      'context': context,
      'redirectUrl': redirectUrl
    };
    return this.http.post(this.apiUrl + endpoint, data);
  }

  register(user: User, callerContext: string, redirectUrl: string): Observable<any> {
    this.nonConfirmedUser = user;
    const registerUserObservable = this.registerCognitoUserContext(user.email, 'sign-up', callerContext, redirectUrl);
    return registerUserObservable.pipe(
      catchError((error) => {
        console.error(error);
        throw error;
      }),
      flatMap(() => {
        return this.http.post(
          this.cognitoService.BASE_URL,
          JSON.stringify({
            ClientId: this.cognitoService.CLIENT_ID,
            Username: user.email,
            Password: user.password,
            UserAttributes: [
              {
                Name: 'email',
                Value: user.email
              },
              {
                Name: 'custom:fullname',
                Value: user.fullName
              },
              {
                Name: 'custom:usertype',
                Value: user.userType,
              },
              {
                Name: 'custom:mobile',
                Value: user.mobile
              },
              {
                Name: 'custom:callerfunc',
                Value: callerContext
              }
            ]
          }),
          {headers: this.cognitoService.getCommonHeaders(CognitoAction.ACTION_SIGN_UP)}
        );
      }),
      catchError((error) => {
        console.error(error);
        throw error;
      })
    );
  }

  login(user: User, saveRefreshToken: boolean): Observable<any> {
    this.nonConfirmedUser = user;
    let deviceKey = this.localStorageService.getValueForKey('dk');
    let deviceGroupKey = this.localStorageService.getValueForKey('dgk');
    if (!deviceKey || !deviceGroupKey) {
      deviceKey = null;
      deviceGroupKey = null;
    }
    return this.srpAuthService.authUser(user.email, user.password, deviceKey, deviceGroupKey, this.cognitoService.CLIENT_ID)
      .pipe(
        catchError((error) => {
          console.error(error);
          throw error;
        }),
        map((loginData: any) => {
          this.isLoggedOn = true;
          if (!deviceKey || loginData.AuthenticationResult.NewDeviceMetadata) {
            this.deviceAuthService.confirmNewDevice(user.email, loginData, environment.cognitoUserPoolId);
          }
          this.cognitoService.accessToken = loginData.AuthenticationResult.AccessToken;
          this.cognitoService.idToken = loginData.AuthenticationResult.IdToken;
          this.cognitoService.refreshToken = loginData.AuthenticationResult.RefreshToken;
          if (saveRefreshToken) {
            this.localStorageService.saveRefreshToken(this.cognitoService.refreshToken);
          }
          return loginData;
        }));
  }

  refreshTokenLogin(): Observable<any> {
    const refreshToken = this.localStorageService.getRefreshToken();
    const deviceKey = this.localStorageService.getValueForKey('dk');
    this.logger.info('running refresh token login');
    this.logger.debug('Headers : ' + this.cognitoService.getCommonHeaders(CognitoAction.ACTION_INITIATE_AUTH));
    return this.http.post(
      this.cognitoService.BASE_URL,
      JSON.stringify({
        AuthFlow: CognitoAuthFlow.AUTH_REFRESH.valueOf(),
        ClientId: this.cognitoService.CLIENT_ID,
        AuthParameters: {
          REFRESH_TOKEN: refreshToken,
          DEVICE_KEY: deviceKey,
        }
      }),
      {headers: this.cognitoService.getCommonHeaders(CognitoAction.ACTION_INITIATE_AUTH)}
    ).pipe(
      catchError((refresherror: any) => {
        this.localStorageService.deleteRefreshToken();
        throw refresherror;
      }),
      map((loginData: any) => {
        this.cognitoService.accessToken = loginData.AuthenticationResult.AccessToken;
        this.cognitoService.idToken = loginData.AuthenticationResult.IdToken;
        this.isLoggedOn = true;
        return loginData;
      })
    );
  }

  uploadUserProfileImage(file: File | string): Observable<any> {
    const url = environment.api + '/secure/profileimg';
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(
      url,
      formData
    ).pipe(
      map((savedUrl: any) => {
        return savedUrl;
      })
    );
  }

  confirmSignup(user: User, confirmationCode: String): Observable<any> {
    return this.http.post(
      this.cognitoService.BASE_URL,
      JSON.stringify({
        ClientId: this.cognitoService.CLIENT_ID,
        ConfirmationCode: confirmationCode,
        Username: user.email
      }),
      {headers: this.cognitoService.getCommonHeaders(CognitoAction.ACTION_CONFIRM_SIGN_UP)}
    )
      .pipe(
        tap((data: any) => {
        }),
        catchError((error) => {
          console.error(error);
          throw error;
        })
      );
  }

  forgotPassword(email: string, callerContext: string, redirectUrl: string): Observable<any> {
    const forgotPasswordObservable = this.registerCognitoUserContext(email, 'forgot-password', callerContext, redirectUrl);
    return forgotPasswordObservable.pipe(
      catchError((error) => {
        console.error(error);
        throw error;
      }),
      flatMap(() => {
        return this.http.post(
          this.cognitoService.BASE_URL,
          JSON.stringify({
            ClientId: this.cognitoService.CLIENT_ID,
            Username: email
          }),
          {headers: this.cognitoService.getCommonHeaders(CognitoAction.ACTION_FORGOT_PSWD)}
        )
          .pipe(
            tap((data: any) => {

            }),
            catchError((error) => {
              console.error(error);
              throw error;
            })
          );
      }),
      catchError((error) => {
        console.error(error);
        throw error;
      }));
  }

  changePassword(previousPassword: string, proposedPassword: string): Observable<any> {
    return this.http.post(
      this.cognitoService.BASE_URL,

      JSON.stringify({
        AccessToken: this.cognitoService.accessToken,
        PreviousPassword: previousPassword,
        ProposedPassword: proposedPassword,
      }),
      {headers: this.cognitoService.getCommonHeaders(CognitoAction.ACTION_CHANGE_PASSWORD)}
    )
      .pipe(
        tap((data: any) => {

        }),
        catchError((error) => {
          console.error(error);
          throw error;
        })
      );
  }

  updatePlayerDetails(playerId: number, fullName: string, mobile: string, optInFMTrackNTrace?: boolean, optInSuggestedKickaroundWhatsApps?: boolean, optInGameNotificationsWhatsApps?: boolean, optInSuggestedKickaroundEmails?: boolean, optInFMNewsEmails?: boolean): Observable<any> {
    const endpoint = `${this.apiUrl}/secure/players/${playerId}/update`;
    const data = {
      fullName: fullName,
      mobile: mobile,
      ...(optInFMTrackNTrace != null) ? {optInFMTrackNTrace: optInFMTrackNTrace} : {},
      ...(optInSuggestedKickaroundWhatsApps != null) ? {optInSuggestedKickaroundWhatsApps: optInSuggestedKickaroundWhatsApps} : {},
      ...(optInGameNotificationsWhatsApps != null) ? {optInGameNotificationsWhatsApps: optInGameNotificationsWhatsApps} : {},
      ...(optInSuggestedKickaroundEmails != null) ? {optInSuggestedKickaroundEmails: optInSuggestedKickaroundEmails} : {},
      ...(optInFMNewsEmails != null) ? {optInFMNewsEmails: optInFMNewsEmails} : {},

    };
    return this.http.put(
      endpoint,
      data
    );
  }

  updatePlayerDetailsViaPublicGamePlayerToken(kickaroundId: number, gameId: number, playerId: number, fullName: string, mobile: string, token): Observable<any> {
    const endpoint = `${this.apiUrl}/public/kickarounds/${kickaroundId}/games/${gameId}/players/${playerId}/updatePlayer?token=${token}`;
    const data = {
      fullName: fullName,
      mobile: mobile,
    };
    return this.http.put(
      endpoint,
      data
    );
  }

  sendUserUpdateOTPCode(currency: string): Observable<any> {
    const endpoint = `${this.apiUrl}/secure/user/otp`;
    const data = {currency: currency};
    return this.http.post(
      endpoint,
      data
    );
  }

  authUserUpdateOTPCode(otp, currency: string): Observable<any> {
    const endpoint = `${this.apiUrl}/secure/user/otp`;
    const params = {otp: otp, currency: currency};
    return this.http.get(
      endpoint,
      {params}
    );
  }

  closeAccount(): Observable<any> {
    const endpoint = `${this.apiUrl}/secure/userDetails/closeAccount`;
    return this.http.post(
      endpoint,
      {}
    );
  }

  stampCampaignAffilateOnPlayer(affiliateToken: string, campaignToken: string): Observable<any> {
    const endpoint = `${this.apiUrl}/secure/players/stampAffiliateCampaignCodes`;
    const data = {
      affiliateToken: affiliateToken,
      campaignToken: campaignToken,
    };
    return this.http.put(
      endpoint,
      data
    );
  }

  confirmForgotPassword(username: string, password: string, confirmationCode: String): Observable<any> {
    return this.http.post(
      this.cognitoService.BASE_URL,
      JSON.stringify({
        ClientId: this.cognitoService.CLIENT_ID,
        ConfirmationCode: confirmationCode,
        Username: username,
        Password: password
      }),
      {headers: this.cognitoService.getCommonHeaders(CognitoAction.ACTION_CONFIRM_FORGOT_PSWD)}
    )
      .pipe(
        tap((data: any) => {

        }),
        catchError((error) => {
          console.error(error);
          throw error;
        })
      );
  }

  resendCode(callerContext: string, redirectUrl: string) {
    const user = this.nonConfirmedUser;
    const forgotPasswordObservable = this.registerCognitoUserContext(user.email, 'resend-code', callerContext, redirectUrl);
    return forgotPasswordObservable.pipe(
      catchError((error) => {
        console.error(error);
        throw error;
      }),
      flatMap(() => {
        return this.http.post(
          this.cognitoService.BASE_URL,
          JSON.stringify({
            ClientId: this.cognitoService.CLIENT_ID,
            Username: user.email
          }),
          {headers: this.cognitoService.getCommonHeaders(CognitoAction.ACTION_RESEND_CODE)}
        ).pipe(
          tap((data: any) => {
            this.logger.info('Code successfully sent');
          }),
          catchError((error) => {
            this.logger.error(error);
            throw error;
          })
        );
      }),
      catchError((error) => {
        console.error(error);
        throw error;
      }));
  }

  resendCodeToResetPassword(email: string, callerContext: string, redirectUrl: string): Observable<any> {
    const forgotPasswordObservable = this.registerCognitoUserContext(email, 'resend-code', callerContext, redirectUrl);
    return forgotPasswordObservable.pipe(
      catchError((error) => {
        console.error(error);
        throw error;
      }),
      flatMap(() => {
        return this.http.post(
          this.cognitoService.BASE_URL,
          JSON.stringify({
            ClientId: this.cognitoService.CLIENT_ID,
            Username: email
          }),
          {headers: this.cognitoService.getCommonHeaders(CognitoAction.ACTION_FORGOT_PSWD)}
        )
          .pipe(
            tap((data: any) => {

            }),
            catchError((error) => {
              console.error(error);
              throw error;
            })
          );
      }),
      catchError((error) => {
        console.error(error);
        throw error;
      }));
  }

  getUser() {
    return this.http.post(
      this.cognitoService.BASE_URL,
      JSON.stringify({
        AccessToken: this.cognitoService.accessToken
      }),
      {headers: this.cognitoService.getCommonHeaders(CognitoAction.ACTION_GET_USER)}
    )
      .pipe(
        tap((data: any) => {
          this.logger.info('Retrieved user details : ' + data);
        }),
        catchError((error) => {
          this.logger.error(error);
          throw error;
        })
      );
  }

  getUserDetails(): Observable<UserDetails> {
    const url = environment.api + '/secure/userDetails/';
    if (environment.mockUserData) {
      return of(MOCK_USER_DATA).pipe(tap((data: any) => {
      }));
    }
    return this.http.get(url).pipe(tap((data: any) => {
    }));
  }

  logoff() {
    this.refreshTokenAuth = false;
    this.isLoggedOn = false;
    this.cognitoService.accessToken = 'NOT SET';
    this.cognitoService.idToken = 'NOT SET';
    this.cognitoService.refreshToken = 'NOT SET';
    this.localStorageService.deleteRefreshToken();
    this.localStorageService.removeValueForKey('KEY_REFRESH_USER');
    // Sentry.setUser(null);
  }

  ngOnInit(): void {
  }


}
