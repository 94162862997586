import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Action, State, StateContext, Store} from '@ngxs/store';
import {tap} from 'rxjs/operators';

import {filterIDMap} from '../../../../shared/helpers/idmap.functions';
import {KickaroundsService} from '../../services/kickarounds.service';
import {
  CancelGame,
  UpdateKickaroundStatus,
  ConfirmGame,
  CreateKickaround,
  CreateLocation,
  CreateNextGame,
  DeleteKickaround,
  GetKickaround,
  GetKickaroundGame,
  GetKickaroundGamePlayers,
  GetKickaroundUserData,
  PostAcceptDeclineGameInvitation,
  ResetSecureKickaroundState,
  SendGamePlayerList,
  SendInvites,
  SendMessage,
  UpdateKickaround,
  UpdateKickaroundFromWSEvent,
  UpdateKickaroundGameFromWSEvent,
  UpdateKickaroundGamePlayerFromWSEvent,
  UpdateKickaroundGamePlayersFromWSEvent,
  ActivatePendingKickaround,
  JoinKickaround,
  KASUpdatePlayer,
  SendGameParticipationTrackNTraceEmail,
  GetSuggestedKickarounds,
  SetSuggestedKickaroundTab,
  ResetSuggestedKickaroundDataFlags,
  JoinKickaroundSecure, UnsubscribeFromKickaround, UpdatePlayerDisplayName,
} from '../actions/kickarounds-secure.actions';
import {generateAllKickaroundGameUIStates, generateKickaroundGameUIState} from '../kickarounds.state.utils';
import { GameStatusChoices} from '../../kickarounds.enums';
import {KickaroundSecureStateModel} from '../interfaces/kickaround-secure-state-model.interface';
import {bulkUpdateIdMap, partiallyUpdateArray, partiallyUpdateObject, updateIdMap} from '../../../../shared/state/fm-state.functions';
import {ProgressService} from '../../../progress/services/progress.service';
import {throwError} from 'rxjs/internal/observable/throwError';
import {UserSelectors} from '../../../user/state/user.selectors';
import {KickaroundGamePlayer} from '../../models/database/kickaround-game-player.model';
import {Deserialize} from 'cerialize';
import {Kickaround} from '../../models/database/kickaround.model';
import {SnackBarService} from '../../../../shared/modules/announcements/snackbar/snack-bar.service';
import {Player} from '../../../user/models/player.model';
import {NGXLogger} from 'ngx-logger';
import {SuggestedKickaroudsService} from '../../services/suggested-kickarouds.service';
import {
  ViewGameNumbers,
  RSVPAttemptEvent,
} from '../../../analytics/mixpanel.constants';
import {FMAnalyticsService} from '../../../analytics/fm-analytics.service';
import {mapGamePlayerStatus, mapRSVPAction} from '../../kickarounds.utils';

@Injectable()
@State<KickaroundSecureStateModel>({
  name: 'secureKickarounds',
  defaults: {
    uiUpdateTimeStamp: new Date(),
    uiCreatedKickaroundId: null,
    uiGame: {},
    uiJoinPage: {
      isError: false,
      joinMessage: '',
      hasJoined: false
    },
    uiSuggestedKickarounds: {
      isLoading: true,
      hasError: false,
      suggestedKickaroundTabOpen: false
    },
    dbOpenDataSites: {},
    dbLocations: {},
    dbPayments: {},
    dbPlayers: {},
    dbKickarounds: {},
    dbKickaroundPermissions: {},
    dbKickaroundGames: {},
    dbSuggestedKickarounds: [],
    dbKickaroundPlayers: {},
    dbKickaroundGamePlayers: {},
    dbKickaroundDataLoaded: false,
    dbKickaroundHistoricDataLoaded: false,
    dbSuggestedKickaroundDataLoaded: false
  }
})
export class KickaroundSecureState {

  constructor(
    private kickaroundService: KickaroundsService,
    private suggestedKickaroundService: SuggestedKickaroudsService,
    private progressService: ProgressService,
    private snackBarService: SnackBarService,
    private router: Router,
    private store: Store,
    private logger: NGXLogger,
    private fmas: FMAnalyticsService,
    ) {}

  @Action(ResetSuggestedKickaroundDataFlags)
  resetSuggestedKickaroundDataFlags(ctx: StateContext<KickaroundSecureStateModel>, action: ResetSecureKickaroundState) {
    ctx.patchState( {
      uiSuggestedKickarounds: {
        suggestedKickaroundTabOpen: false,
        isLoading: true,
        hasError: false
      },
    });
  }

  @Action(GetSuggestedKickarounds)
  getSuggestedKickarounds(ctx: StateContext<KickaroundSecureStateModel>, action: GetSuggestedKickarounds) {
    const preState = ctx.getState();
    const suggestedKickaroundsLoaded = preState.dbSuggestedKickaroundDataLoaded;

    // If data cached already
    if (suggestedKickaroundsLoaded && !action.refreshData) {
      ctx.patchState({
        uiSuggestedKickarounds: {
          suggestedKickaroundTabOpen: false,
          isLoading: false,
          hasError: false
        }
      });
      return;
    }
    return this.suggestedKickaroundService.getSuggestedKickarounds().pipe(tap(
    (data) => {
      const suggestedKickarounds = data;
      const state = ctx.getState();

      // Display message
      let snackBarRef;
      if (action.refreshData && action.showPopUp) {
        const difference = suggestedKickarounds.length - state.dbSuggestedKickarounds.length;

        if (difference > 0) {
          const msg = `${difference} new games discovered.`;
          const event: { name: string; properties: { numberOfGroupsFound: number } } = {
              name: 'Suggested Groups Found',
              properties: {
                numberOfGroupsFound: difference
              }
          };
          this.fmas.recordV2Event(event);
          snackBarRef = this.snackBarService.openWithAction(msg, 'Go to suggested');
        } else {
          const msg = `No new games discovered. We'll notify you once more start!`;
          snackBarRef = this.snackBarService.openWithAction(msg, 'Go to suggested');
        }

        snackBarRef.onAction().subscribe(() => {
          this.router.navigateByUrl('/secure/kickarounds/suggested');
        });
      }


      ctx.setState({
        ...state,
        uiSuggestedKickarounds: {
          ...state.uiSuggestedKickarounds,
          isLoading: false,
          hasError: false,
        },
        dbSuggestedKickarounds: suggestedKickarounds,
        dbSuggestedKickaroundDataLoaded: true
      });
    },
    (err) => {
      const state = ctx.getState();
      ctx.setState({
        ...state,
        dbSuggestedKickaroundDataLoaded: false,
        uiSuggestedKickarounds: {
          ...state.uiSuggestedKickarounds,
          isLoading: false,
          hasError: false,
        },
      });
    }));
  }

  @Action(GetKickaroundUserData)
  getKickaroundUserData(ctx: StateContext<KickaroundSecureStateModel>, action: GetKickaroundUserData) {
    const preState = ctx.getState();
    if (!preState.dbKickaroundDataLoaded || action.refreshData) {
      if (action.showLoading) {
        this.progressService.showLoadingSpinner('Loading group data...');
      }
      return this.kickaroundService.getLatestKickaroundUserData().pipe(tap(
      (userData) => {
        const state = ctx.getState();
        const data = userData;

        const kickaroundGameUIState = generateAllKickaroundGameUIStates(
          Object.values(data.kickarounds),
          Object.values(data.kickaroundGames),
          Object.values(data.kickaroundGamePlayers),
          this.store.selectSnapshot(UserSelectors.player),
          this.store.selectSnapshot(UserSelectors.features),
        );
        this.progressService.hideLoadingSpinner();
        ctx.setState({
          ...state,
          uiGame: kickaroundGameUIState,
          dbOpenDataSites: data.openDataSites,
          dbLocations: data.locations,
          dbPlayers: data.players,
          dbKickarounds: data.kickarounds,
          dbKickaroundPermissions: data.kickaroundPermissions,
          dbKickaroundPlayers: data.kickaroundPlayers,
          dbKickaroundGames: data.kickaroundGames,
          dbKickaroundGamePlayers: data.kickaroundGamePlayers,
          dbKickaroundDataLoaded: true
        });
      },
      (err) => {
        this.progressService.hideLoadingSpinner();
        ctx.patchState({
          dbKickaroundDataLoaded: false,
        });
      }));
    }
  }

  @Action(PostAcceptDeclineGameInvitation)
  postAcceptDeclineKicaroundGame(ctx: StateContext<KickaroundSecureStateModel>, action: PostAcceptDeclineGameInvitation) {
    const preState = ctx.getState();
    const kickaround = preState.dbKickarounds[action.kickaroundId];
    const game = preState.dbKickaroundGames[action.gameId];
    const gofm = action.gofm;

    if (!(kickaround && game && action.acceptDeclineGameResponse === null)) {
      this.progressService.showLoadingSpinner('Updating invitation status...');

      return this.kickaroundService.confirmDeclineLatestGameInvite(
        action.kickaroundId, action.gameId, action.acceptDeclineGameResponse, action.gofm).pipe(tap(
      (response) => {
        const state = ctx.getState();
        const data = response.userData;

        // Update
        const gamePlayers: KickaroundGamePlayer[] = Deserialize(response['recalcPlayerList'], KickaroundGamePlayer);
        const loggedInGamePlayer = Deserialize(response['loggedInGamePlayer'], KickaroundGamePlayer);
        const loggedInGamePlayerIdx = gamePlayers.findIndex(gp => gp.id === loggedInGamePlayer.id);
        gamePlayers[loggedInGamePlayerIdx] = {...gamePlayers[loggedInGamePlayerIdx], ...loggedInGamePlayer};

        const kickaroundGameUIState = generateKickaroundGameUIState(
          data.kickarounds[action.kickaroundId],
          data.kickaroundGames[action.gameId],
          gamePlayers,
          this.store.selectSnapshot(UserSelectors.player).id,
          this.store.selectSnapshot(UserSelectors.features),
        );

        if ((action.acceptDeclineGameResponse === 'confirm') || (action.acceptDeclineGameResponse === 'decline')) {
          const event: RSVPAttemptEvent = {
            name: 'RSVP Attempt',
            properties: {
              playerId: loggedInGamePlayer.playerId,
              groupId: kickaround.id,
              groupName: kickaround.name,
              GROUP: kickaround.id,
              ORGANISER: kickaround.createdBy,
              gameId: game.id,
              origin: (action.viaWhatsApp) ? 'SECURE_VIA_WHATSAPP' : 'SECURE_VIA_APP',
              gameDate: game.start.toISOString(),
              rsvpAction: mapRSVPAction(action.acceptDeclineGameResponse),
              rsvpDate: (new Date()).toISOString(),
              gameStatus: mapGamePlayerStatus(loggedInGamePlayer.gameStatus),
              previousGameStatus: mapGamePlayerStatus(loggedInGamePlayer.previousGameStatus),
              prePaid: loggedInGamePlayer.prePaid,
              prePaidRemainingGames: loggedInGamePlayer.prePaidRemainingGames,
              numberOfAcceptances: game.numberOfAcceptances,
              numberOfPayments: game.numberOfPayments,
              minPlayers: game.minRequiredPlayers,
              maxPlayers: game.maxRequiredPlayers,
            }
          };
          this.fmas.recordV2Event(event);
        } else if (action.acceptDeclineGameResponse === 'lookup') {
          const event: ViewGameNumbers = {
            name: 'View Game Numbers',
            properties: {
              playerId: loggedInGamePlayer.id,
              groupId: kickaround.id,
              groupName: kickaround.name,
              GROUP: kickaround.id,
              gameId: game.id,
              origin: (action.viaWhatsApp) ? 'SECURE_VIA_WHATSAPP' : 'SECURE_VIA_APP',
              gameDate: game.start.toISOString(),
              numberOfAcceptances: game.numberOfAcceptances,
              numberOfPayments: game.numberOfPayments,
              minPlayers: game.minRequiredPlayers,
              maxPlayers: game.maxRequiredPlayers,
              useGOFMLink: true,
            }
          };
          this.fmas.recordV2Event(event);
        }
        this.progressService.hideLoadingSpinner();
        ctx.setState({
          ...state,
          uiGame: updateIdMap(state.uiGame, action.gameId, kickaroundGameUIState),
          dbOpenDataSites: bulkUpdateIdMap(state.dbOpenDataSites, Object.values(data.openDataSites)),
          dbLocations: bulkUpdateIdMap(state.dbLocations, Object.values(data.locations)),
          dbPlayers: bulkUpdateIdMap(state.dbPlayers, Object.values(data.players)),
          dbKickarounds: bulkUpdateIdMap(state.dbKickarounds, Object.values(data.kickarounds)),
          dbKickaroundPlayers: bulkUpdateIdMap(state.dbKickaroundPlayers, Object.values(data.kickaroundPlayers)),
          dbKickaroundGames: bulkUpdateIdMap(state.dbKickaroundGames, Object.values(data.kickaroundGames)),
          dbKickaroundGamePlayers: bulkUpdateIdMap(state.dbKickaroundGamePlayers, Object.values(data.kickaroundGamePlayers)),
        });
      },
      (err) => {
        this.progressService.hideLoadingSpinner();
        ctx.patchState({
          dbKickaroundDataLoaded: false,
        });
      }));
    }
  }

  @Action(GetKickaround)
  getKickaround(ctx: StateContext<KickaroundSecureStateModel>, action: GetKickaround) {
    this.progressService.showLoadingSpinner('Getting group data...');
    let state = ctx.getState();

    // Check if kickaround has already been fetched
    const storedKickaround = state.dbKickarounds[action.kickaroundId];
    if (storedKickaround && !action.refetch) {
      this.progressService.hideLoadingSpinner();
      return storedKickaround;
    }

    // If not found, fetched and store in state
    return this.kickaroundService.getKickaround(action.kickaroundId, undefined, action.gofmToken).pipe(tap((kickaround) => {
      // refetch state
      state = ctx.getState();

      // Update kickaround ui state
      let openDataSiteId = 0;
      if (kickaround.openDataSite) {
        openDataSiteId = kickaround.openDataSite.id;
      }

      let gamePlayers = [];
      let kickaroundGameUIState;

      if (  kickaround.latestGame ) {
        gamePlayers = kickaround.latestGame.player_statuses;
        const loggedInPlayerId = this.store.selectSnapshot(UserSelectors.player).id;
        kickaroundGameUIState = generateKickaroundGameUIState(
          kickaround,
          kickaround.latestGame,
          gamePlayers,
          loggedInPlayerId,
          this.store.selectSnapshot(UserSelectors.features),
        );

        this.progressService.hideLoadingSpinner();
        ctx.setState({
          ...state,
          uiGame:  updateIdMap(state.uiGame, kickaround.latestGameId, kickaroundGameUIState ),
          dbLocations: updateIdMap(state.dbLocations, kickaround.location.id, kickaround.location),
          dbOpenDataSites: updateIdMap(state.dbOpenDataSites, openDataSiteId, kickaround.openDataSite),
          dbPlayers: bulkUpdateIdMap(state.dbPlayers, kickaround.players.map(kp => kp.player)),
          dbKickaroundPlayers: bulkUpdateIdMap(state.dbKickaroundPlayers, kickaround.players),
          dbKickarounds: updateIdMap(state.dbKickarounds, action.kickaroundId, kickaround),
          dbKickaroundGames: updateIdMap(state.dbKickaroundGames, kickaround.latestGameId, kickaround.latestGame),
          dbKickaroundGamePlayers: bulkUpdateIdMap(state.dbKickaroundGamePlayers, gamePlayers),
        });
      } else {
        this.progressService.hideLoadingSpinner();
        ctx.setState({
          ...state,
          dbLocations: updateIdMap(state.dbLocations, kickaround.location.id, kickaround.location),
          dbOpenDataSites: updateIdMap(state.dbOpenDataSites, openDataSiteId, kickaround.openDataSite),
          dbPlayers: bulkUpdateIdMap(state.dbPlayers, kickaround.players.map(kp => kp.player)),
          dbKickaroundPlayers: bulkUpdateIdMap(state.dbKickaroundPlayers, kickaround.players),
          dbKickarounds: updateIdMap(state.dbKickarounds, action.kickaroundId, kickaround),
        });
      }
    },
  e => {
          this.progressService.hideLoadingSpinner();
          throwError(e);
        },
    ));
  }

  @Action(GetKickaroundGame)
  getKickaroundGame(ctx: StateContext<KickaroundSecureStateModel>, action: GetKickaroundGame) {
    let state = ctx.getState();

    // Check state for kickaround latestGame
    const storedKickaroundGame = state.dbKickaroundGames[action.gameId];
    if (storedKickaroundGame && !action.refetch) {
      this.progressService.hideLoadingSpinner();
      return storedKickaroundGame;
    }

    this.progressService.showLoadingSpinner('Getting game data...');

    // If not found, fetched and store in state
    const loggedInPlayerId = this.store.selectSnapshot(UserSelectors.player).id;
    return this.kickaroundService.getKickaroundGame(action.kickaroundId, action.gameId, 'secure', loggedInPlayerId).pipe(tap((game) => {

      // Get latestGame from state
      state = ctx.getState();

      // Update gameIDss
      if (game && game.player_statuses) {
        game.player_statuses.forEach(gp => {
          gp.gameId = action.gameId;
        });

        // Update kickaround ui stateokok
        const gamePlayers = game.player_statuses;
        const kickaroundGameUIState = generateKickaroundGameUIState(
          state.dbKickarounds[action.kickaroundId],
          game,
          gamePlayers,
          loggedInPlayerId,
          this.store.selectSnapshot(UserSelectors.features),
        );

        this.progressService.hideLoadingSpinner();
        ctx.setState({
          ...state,
          uiGame: updateIdMap(state.uiGame, game.id, kickaroundGameUIState ),
          dbKickaroundGames: updateIdMap(state.dbKickaroundGames, game.id, game),
          dbKickaroundGamePlayers: bulkUpdateIdMap(state.dbKickaroundGamePlayers, gamePlayers),
        });
      } else {
        this.router.navigate(['/error']);
      }
    },
  e => {
          this.progressService.hideLoadingSpinner();
          throwError(e);
        },
    ));
  }


  @Action(GetKickaroundGamePlayers)
  getKickaroundGamePlayers(ctx: StateContext<KickaroundSecureStateModel>, action: GetKickaroundGamePlayers) {
    this.progressService.showLoadingSpinner('Getting latest game data...');
    return this.kickaroundService.getPlayersForKickaroundGame(action.kickaroundId, action.gameId, ).pipe(tap((gamePlayers) => {
      const state = ctx.getState();

      // Update UI Flags
      const loggedInPlayerId = this.store.selectSnapshot(UserSelectors.player).id;
      const loggedInGamePlayer = gamePlayers.find(gp => (gp.player.id === loggedInPlayerId));
      const kickaround = state.dbKickarounds[action.kickaroundId];
      const game = state.dbKickaroundGames[action.gameId];
      const kickaroundGameUIState = generateKickaroundGameUIState(
        kickaround,
        game,
        gamePlayers,
        loggedInPlayerId,
        this.store.selectSnapshot(UserSelectors.features),
      );

      this.progressService.hideLoadingSpinner();
      ctx.setState({
        ...state,
        uiGame: updateIdMap(state.uiGame, action.gameId, kickaroundGameUIState),
        dbKickaroundGames: updateIdMap(state.dbKickaroundGames, action.gameId, game),
        dbKickaroundGamePlayers: bulkUpdateIdMap(state.dbKickaroundGamePlayers, gamePlayers)
      });
    },
  e => {
          this.progressService.hideLoadingSpinner();
          throwError(e);
        },
    ));
  }

  @Action(CreateLocation)
  createLocation(ctx: StateContext<KickaroundSecureStateModel>, action: CreateLocation) {
    this.progressService.showLoadingSpinner('Creating location...');
    return this.kickaroundService.createLocation(action.newLocation).pipe(tap((location) => {
      const state = ctx.getState();
      this.progressService.hideLoadingSpinner();
      ctx.setState({
        ...state,
        dbLocations: updateIdMap(state.dbLocations, location.id, location)
      });
    }));
  }

  @Action(UpdateKickaroundStatus)
  updateKickaroundStatus(ctx: StateContext<KickaroundSecureStateModel>, action: UpdateKickaroundStatus) {
    this.progressService.showLoadingSpinner('Updating group...');
    return this.kickaroundService.updateKickaroundStatus(action.kickaroundId, action.status, action.reason, action.startDateTime).pipe(tap((location) => {
      this.progressService.hideLoadingSpinner();
    },
        e => {
          this.progressService.hideLoadingSpinner();
          throwError(e);
        },
      ));
  }

  @Action(ActivatePendingKickaround)
  activatePendingKickaround(ctx: StateContext<KickaroundSecureStateModel>, action: ActivatePendingKickaround) {
    this.progressService.showLoadingSpinner('Activating kickaround ...');
    return this.kickaroundService.activateKickaround(action.kaId).pipe(tap((result) => {
      this.progressService.hideLoadingSpinner();
    },
        e => {
          this.progressService.hideLoadingSpinner();
          throwError(e);
        },
      ));
  }

  @Action(CreateKickaround)
  createKickaround(ctx: StateContext<KickaroundSecureStateModel>, action: CreateKickaround) {
    this.progressService.showLoadingSpinner('Creating group...');
    return this.kickaroundService.createKickaround(action.kickaround).pipe(tap((kickaround) => {
     const state = ctx.getState();
     const players = kickaround.players.map(kp => kp.player);

     // Handle kickaround with manual site
     let updatedOpenDataSites = state.dbOpenDataSites;
     if (kickaround.openDataSite) {
       updatedOpenDataSites = updateIdMap(state.dbOpenDataSites, kickaround.openDataSite.id, kickaround.openDataSite);
     }

     this.progressService.hideLoadingSpinner();
      ctx.setState({
        ...state,
        uiCreatedKickaroundId: kickaround.id,
        dbKickarounds: updateIdMap(state.dbKickarounds, kickaround.id, kickaround),
        dbLocations: updateIdMap(state.dbLocations, kickaround.location.id, kickaround.location),
        dbOpenDataSites: updatedOpenDataSites,
        dbKickaroundPlayers: bulkUpdateIdMap(state.dbKickaroundPlayers, kickaround.players),
        dbPlayers: bulkUpdateIdMap(state.dbPlayers, players),
      });
    },
  e => {
          this.progressService.hideLoadingSpinner();
          throwError(e);
        },
    ));
  }

  @Action(UpdateKickaround)
  updateKickaround(ctx: StateContext<KickaroundSecureStateModel>, action: UpdateKickaround) {
    this.progressService.showLoadingSpinner('Updating group...');

    // Format Kickaround values
    const updatedKickaround: Kickaround = {...action.kickaround};
    updatedKickaround.price = +(+updatedKickaround.price).toFixed(2);
    updatedKickaround.fixedPlayerPrice = +(+updatedKickaround.fixedPlayerPrice).toFixed(2);

    return this.kickaroundService.updateKickaround(updatedKickaround, action.applyEditToGame).pipe(tap((kickaround) => {
      this.progressService.hideLoadingSpinner();
      },
        e => {
          this.progressService.hideLoadingSpinner();
          throwError(e);
        },
    ));
  }

  @Action(DeleteKickaround)
  deleteKickaround(ctx: StateContext<KickaroundSecureStateModel>, action: DeleteKickaround) {
    this.progressService.showLoadingSpinner('Updating group...');
    return this.kickaroundService.deleteKickaround(action.kickaroundId).pipe(tap(() => {
      this.progressService.hideLoadingSpinner();
    },
      e => {
        this.progressService.hideLoadingSpinner();
        throwError(e);
      },
    ));
  }

  @Action(CreateNextGame)
  createNextGame(ctx: StateContext<KickaroundSecureStateModel>, action: CreateNextGame) {
    this.progressService.showLoadingSpinner('Creating next game...');
    return this.kickaroundService.createNextGame(action.kickaroundId, action.nextGameDate).pipe(tap((game) => {
      // Must call user data
      this.progressService.hideLoadingSpinner();
    },
    e => {
      this.progressService.hideLoadingSpinner();
      throwError(e);
    },
    ));
  }

  @Action(ConfirmGame)
  confirmGame(ctx: StateContext<KickaroundSecureStateModel>, action: ConfirmGame) {
    this.progressService.showLoadingSpinner('Confirming game...');
    return this.kickaroundService.confirmGame(action.kickaroundId).pipe(tap((r) => {
      const state = ctx.getState();

      // Update UI Flags
      const game = {...state.dbKickaroundGames[action.gameId], ...{ status: GameStatusChoices.confirmed }};
      const gamePlayers = filterIDMap(state.dbKickaroundGamePlayers, 'gameId', game.id);
      const kickaroundGameUIState = generateKickaroundGameUIState(
        state.dbKickarounds[action.kickaroundId],
        game,
        gamePlayers,
        this.store.selectSnapshot(UserSelectors.player).id,
        this.store.selectSnapshot(UserSelectors.features),
      );

      this.progressService.hideLoadingSpinner();
      ctx.setState({
        ...state,
        uiGame: updateIdMap(state.uiGame, action.gameId, kickaroundGameUIState),
        dbKickaroundGames: updateIdMap(state.dbKickaroundGames, action.gameId, game),
      });
    },
        e => {
          this.progressService.hideLoadingSpinner();
          throwError(e);
        },
    ));
  }

  @Action(CancelGame)
  cancelGame(ctx: StateContext<KickaroundSecureStateModel>, action: CancelGame) {
  this.progressService.showLoadingSpinner('Cancelling game...');
    return this.kickaroundService.cancelGame(action.kickaroundId, action.reason).pipe(tap((r) => {
      const state = ctx.getState();

      // Update UI Flags
      const game = {...state.dbKickaroundGames[action.gameId], ...{ status: GameStatusChoices.cancelled }};
      const gamePlayers = filterIDMap(state.dbKickaroundGamePlayers, 'gameId', game.id);
      const kickaroundGameUIState = generateKickaroundGameUIState(
        state.dbKickarounds[action.kickaroundId],
        game,
        gamePlayers,
        this.store.selectSnapshot(UserSelectors.player).id,
        this.store.selectSnapshot(UserSelectors.features),
      );

      this.progressService.hideLoadingSpinner();
      ctx.setState({
        ...state,
        uiGame: updateIdMap(state.uiGame, action.gameId, kickaroundGameUIState),
        dbKickaroundGames: updateIdMap(state.dbKickaroundGames, action.gameId, game),
      });
    },
        e => {
          this.progressService.hideLoadingSpinner();
          throwError(e);
        },
    ));
  }

  @Action(SendInvites)
  sendInvites(ctx: StateContext<KickaroundSecureStateModel>, action: SendInvites) {
    this.progressService.showLoadingSpinner('Sending invites...');
    return this.kickaroundService.sendInvites(action.kickaroundId).pipe(tap((r) => {
      const state = ctx.getState();

      // Update UI Flags
      const game = {...state.dbKickaroundGames[action.gameId], ...{ status: GameStatusChoices.invites_sent_manually }};
      const kickaroundGameUIState = generateKickaroundGameUIState(
        state.dbKickarounds[action.kickaroundId],
        game,
        [],
        this.store.selectSnapshot(UserSelectors.player).id,
        this.store.selectSnapshot(UserSelectors.features),
      );

      this.progressService.hideLoadingSpinner();
      ctx.setState({
        ...state,
        uiGame: updateIdMap(state.uiGame, action.gameId, kickaroundGameUIState),
        dbKickaroundGames: updateIdMap(state.dbKickaroundGames, action.gameId, game),
      });
    },
        e => {
          this.progressService.hideLoadingSpinner();
          throwError(e);
        },
    ));
  }

  @Action(SendMessage)
  sendMessage(ctx: StateContext<KickaroundSecureStateModel>, action: SendMessage) {
    this.progressService.showLoadingSpinner('Sending message...');
    return this.kickaroundService.sendMessage(action.kickaroundId, action.message, action.toJustConfirmed).pipe(tap((r) => {
      this.progressService.hideLoadingSpinner();
    },
   e => {
          this.progressService.hideLoadingSpinner();
          throwError(e);
        },
    ));
  }

  @Action(SendGameParticipationTrackNTraceEmail)
  sendGameParticipationTrackNTraceEmail(ctx: StateContext<KickaroundSecureStateModel>, action: SendGameParticipationTrackNTraceEmail) {
    this.progressService.showLoadingSpinner('Sending Game Participation Email...');
    return this.kickaroundService.sendGameParticipationDetailsTrackNTraceEmail(action.kickaroundId, action.gameId, action.optionalEmailAddress).pipe(tap((r) => {
      this.progressService.hideLoadingSpinner();
    },
   e => {
          this.progressService.hideLoadingSpinner();
          throwError(e);
        },
    ));
  }

  @Action(SendGamePlayerList)
  sendGamePlayerList(ctx: StateContext<KickaroundSecureStateModel>, action: SendGamePlayerList) {
    this.progressService.showLoadingSpinner('Sending player list to whatsApp...');
    return this.kickaroundService.sendGamePlayerListToWhatsApp(action.gameId).pipe(tap((r) => {
      this.progressService.hideLoadingSpinner();
    },
   e => {
          this.progressService.hideLoadingSpinner();
          throwError(e);
        },
    ));
  }

  @Action(UpdateKickaroundFromWSEvent)
  updateKickaroundFromWSEvent(ctx: StateContext<KickaroundSecureStateModel>, action: UpdateKickaroundFromWSEvent) {
    const state = ctx.getState();

    const loggedInPlayerId = this.store.selectSnapshot(UserSelectors.player).id;
    const updatedKickaround = action.kickaround;
    const existingKickaround = state.dbKickarounds[updatedKickaround.id];

    // Check to see if receiving the latest version
    if (updatedKickaround.version > existingKickaround.version) {

      // Generate UI state delta
      const game = state.dbKickaroundGames[action.kickaround.latestGameId];
      const gamePlayers = filterIDMap(state.dbKickaroundGamePlayers, 'gameId', game.id);
      const kickaroundGameUIState = generateKickaroundGameUIState(
        action.kickaround,
        game,
        gamePlayers,
        loggedInPlayerId,
        this.store.selectSnapshot(UserSelectors.features),
      );

      // Updates state
      ctx.setState({
        ...state,
        uiGame: updateIdMap(state.uiGame, action.kickaround.latestGameId, kickaroundGameUIState ),
        dbKickarounds: updateIdMap(state.dbKickarounds, updatedKickaround.id, updatedKickaround),
      });
    } else {
      this.logger.info(`Ignoring stale realtime update for kickaround ${existingKickaround.id}.
      Received Version:${updatedKickaround.version} =< Existing Version:${existingKickaround.version}`);
    }
  }

  @Action(UpdateKickaroundGameFromWSEvent)
  updateKickaroundGameFromWSEvent(ctx: StateContext<KickaroundSecureStateModel>, action: UpdateKickaroundGameFromWSEvent) {
    const state = ctx.getState();

    // Update kickaround ui state
    const loggedInPlayerId = this.store.selectSnapshot(UserSelectors.player).id;
    const kickaround = state.dbKickarounds[action.kickaroundGame.kickaroundId];
    const updatedGame = action.kickaroundGame;
    const existingGame = state.dbKickaroundGames[action.kickaroundGame.id];

    // Check to see if receiving the latest version
    if (!existingGame || (updatedGame.version > existingGame.version)) {
      // Generate UI state deltas
      const gamePlayers = filterIDMap(state.dbKickaroundGamePlayers, 'gameId', updatedGame.id);
      const kickaroundGameUIState = generateKickaroundGameUIState(
        kickaround,
        updatedGame,
        gamePlayers,
        loggedInPlayerId,
        this.store.selectSnapshot(UserSelectors.features),
      );

      // Updates state
      ctx.setState({
        ...state,
        uiGame: updateIdMap(state.uiGame, updatedGame.id, kickaroundGameUIState ),
        dbKickaroundGames: updateIdMap(state.dbKickaroundGames, updatedGame.id, updatedGame),
      });
    } else {
      this.logger.info(`Ignoring stale realtime update for game ${existingGame.id}.
      Received Version:${updatedGame.version} =< Existing Version:${existingGame.version}`);
    }
  }

  @Action(UpdateKickaroundGamePlayerFromWSEvent)
  updateKickaroundGamePlayerFromWSEvent(ctx: StateContext<KickaroundSecureStateModel>, action: UpdateKickaroundGamePlayerFromWSEvent) {
    const state = ctx.getState();

    // Update kickaround ui state
    const loggedInPlayerId = this.store.selectSnapshot(UserSelectors.player).id;
    const game = state.dbKickaroundGames[action.kickaroundGamePlayer.gameId];
    const kickaround = state.dbKickarounds[game.kickaroundId];
    const existingGamePlayer = state.dbKickaroundGamePlayers[action.kickaroundGamePlayer.id];
    const updatedGamePlayer = action.kickaroundGamePlayer;
    const updatedPlayer = state.dbPlayers[action.kickaroundGamePlayer.playerId];
    const updatedPlayerName = action.kickaroundGamePlayer.playerName;

    // If we have a new kickaround player, we need to add a new player record too
    const players = [
      new Player(
        action.kickaroundGamePlayer.playerId,
        undefined,
        undefined,
        updatedPlayerName,
        undefined,
        false,
        '',
        '',
        '',
        true,
      )];

    // Check to see if receiving the latest version
    if (!existingGamePlayer || (updatedGamePlayer.version > existingGamePlayer.version)) {

      const gamePlayers = [...filterIDMap(state.dbKickaroundGamePlayers, 'gameId', game.id)];

      // Find update latestGame player in existing
      const existingIdx = gamePlayers.findIndex(gp => gp.id === updatedGamePlayer.id);

      // Update existing if found
      if (existingIdx > -1) {
        gamePlayers[existingIdx] = {...gamePlayers[existingIdx], ...updatedGamePlayer};
      // Else add to gamePlayers
      } else {
        gamePlayers.push(updatedGamePlayer);
      }

       // Generate UI state delta
      const kickaroundGameUIState = generateKickaroundGameUIState(
        kickaround,
        game,
        gamePlayers,
        loggedInPlayerId,
        this.store.selectSnapshot(UserSelectors.features),
      );

      // Updates state
      ctx.setState({
        ...state,
        uiGame: updateIdMap(state.uiGame, game.id, kickaroundGameUIState ),
        dbKickaroundGamePlayers: bulkUpdateIdMap(state.dbKickaroundGamePlayers, gamePlayers),
        dbPlayers: bulkUpdateIdMap(state.dbPlayers, players),
      });
    } else {
      this.logger.info(`Ignoring stale realtime update for game ${existingGamePlayer.id}.
        Received Version:${updatedGamePlayer.version} =< Existing Version:${existingGamePlayer.version}`);
    }
  }

  @Action(UpdateKickaroundGamePlayersFromWSEvent)
  updateKickaroundGamePlayersFromWSEvent(ctx: StateContext<KickaroundSecureStateModel>, action: UpdateKickaroundGamePlayersFromWSEvent) {
    const state = ctx.getState();
    const dbKickaroundGamePlayers = filterIDMap(state.dbKickaroundGamePlayers, 'gameId', action.gameId);
    const loggedInPlayer = this.store.selectSnapshot(UserSelectors.player);
    const dbLoggedInKickaroundGamePlayer = dbKickaroundGamePlayers.find(gamePlayer => gamePlayer.playerId === loggedInPlayer.id);

    // Update kickaround ui state
    if ( action && action.kickaroundGamePlayers && action.kickaroundGamePlayers.length > 0) {
      const gameId = action.kickaroundGamePlayers[0].gameId;
      const game = state.dbKickaroundGames[gameId];
      const kickaround = state.dbKickarounds[game.kickaroundId];

      // Check to see if receiving the latest version
      const gamePlayers = action.kickaroundGamePlayers;
      const partialUpdatedGamePlayer = partiallyUpdateObject(dbLoggedInKickaroundGamePlayer, gamePlayers.find(gamePlayer => gamePlayer.playerId === loggedInPlayer.id));
      const updatedGamePlayers = partiallyUpdateArray(gamePlayers, partialUpdatedGamePlayer);

      // Generate UI state delta
      const kickaroundGameUIState = generateKickaroundGameUIState(
        kickaround,
        game,
        updatedGamePlayers,
        this.store.selectSnapshot(UserSelectors.player).id,
        this.store.selectSnapshot(UserSelectors.features),
      );

      // Updates state
      ctx.setState({
        ...state,
        uiGame: updateIdMap(state.uiGame, gameId,  kickaroundGameUIState),
        dbKickaroundGamePlayers: bulkUpdateIdMap(state.dbKickaroundGamePlayers, updatedGamePlayers),
      });
    }
  }

  @Action(JoinKickaroundSecure)
  joinKickaroundSecure(ctx: StateContext<KickaroundSecureStateModel>, action: JoinKickaroundSecure) {
    this.progressService.showLoadingSpinner('Joining Kickaround ...');
    let state = ctx.getState();

    return this.kickaroundService.joinKickaroundSecure(action.kickaroundId, action.token).pipe(tap((response) => {
      state = ctx.getState();
      const kickaroundPlayers = response['players'];
      const players = kickaroundPlayers.map(kp => kp.player);

      this.progressService.hideLoadingSpinner();
      ctx.setState({
        ...state,
        uiJoinPage: {
          isError: response['isError'],
          joinMessage: response['message'],
          hasJoined: response['hasJoined']
        },
        dbPlayers: bulkUpdateIdMap(state.dbPlayers, players),
        dbKickaroundPlayers: bulkUpdateIdMap(state.dbKickaroundPlayers, kickaroundPlayers),
      });
      this.router.navigate(['/secure', 'kickarounds', action.kickaroundId, 'joined' ]);
    },
      (error) => {
      ctx.setState({
        ...state,
        uiJoinPage: {
          isError: true,
          joinMessage: error.message,
          hasJoined: false
        }});
      this.progressService.hideLoadingSpinner();
      this.snackBarService.open(error);
    }));
  }

  @Action(JoinKickaround)
  joinKickaround(ctx: StateContext<KickaroundSecureStateModel>, action: JoinKickaround) {
    this.progressService.showLoadingSpinner('Joining Kickaround...');
    let state = ctx.getState();

    return this.kickaroundService.joinKickaround(action.kickaround, action.latestGame, action.token, action.email, action.name, action.affiliateToken, action.campaignToken).pipe(tap((response) => {
      state = ctx.getState();
      const kicaround = response['kicaround'];
      const kickaroundPlayers = response['players'];
      const players = kickaroundPlayers.map(kp => kp.player);

      this.progressService.hideLoadingSpinner();
      ctx.setState({
        ...state,
        dbPlayers: players,
        dbKickaroundPlayers: kickaroundPlayers
      });
    }, error => {
      this.progressService.hideLoadingSpinner();
      this.snackBarService.open(error);
    }));
  }

  @Action(UnsubscribeFromKickaround)
  unsubscribeFromKickaround(ctx: StateContext<KickaroundSecureStateModel>, action: UnsubscribeFromKickaround) {
    this.progressService.showLoadingSpinner('Unsubscribing from kickaround...');
    let state = ctx.getState();

    return this.kickaroundService.basicUnsubscribe(action.kickaroundId).pipe(tap((response) => {
      state = ctx.getState();
      this.progressService.hideLoadingSpinner();
      this.snackBarService.open('Successfully unsubscribed from kickaround.');
    }, error => {
      this.progressService.hideLoadingSpinner();
      this.snackBarService.open(error);
    }));
  }

  @Action(KASUpdatePlayer)
  updatePlayer(ctx: StateContext<KickaroundSecureStateModel>, action: KASUpdatePlayer) {
    const state = ctx.getState();
    ctx.patchState( {
      dbPlayers: updateIdMap(state.dbPlayers, action.playerId, {
        fullName: action.fullName,
      }),
    });
  }

  @Action(SetSuggestedKickaroundTab)
  setSuggestedKickaroundTab(ctx: StateContext<KickaroundSecureStateModel>, action: SetSuggestedKickaroundTab) {
    const state = ctx.getState();
    ctx.setState( {
      ...state,
      uiSuggestedKickarounds: {
        ...state.uiSuggestedKickarounds,
        suggestedKickaroundTabOpen: action.isTabOpen,
      }
    });
  }

  @Action(UpdatePlayerDisplayName)
  updatePlayerDisplayName(ctx: StateContext<KickaroundSecureStateModel>, action: UpdatePlayerDisplayName) {
    this.progressService.showLoadingSpinner('Updating player display name...');
    let state = ctx.getState();

    return this.kickaroundService.updateDisplayName(action.kickaroundId, action.kickaroundPlayerId, action.displayName, action.onBehalfOfDisplayName).pipe(tap((response) => {
      state = ctx.getState();
      this.progressService.hideLoadingSpinner();
      this.snackBarService.open('Successfully updated player name.');
    }, error => {
      this.progressService.hideLoadingSpinner();
      this.snackBarService.open(error);
    }));
  }

  @Action(ResetSecureKickaroundState)
  resetSecureState(ctx: StateContext<KickaroundSecureStateModel>, action: ResetSecureKickaroundState) {
    ctx.patchState( {
      uiCreatedKickaroundId: null,
      uiGame: {},
      uiSuggestedKickarounds: {
        suggestedKickaroundTabOpen: false,
        isLoading: true,
        hasError: false
      },
      dbOpenDataSites: {},
      dbLocations: {},
      dbPayments: {},
      dbPlayers: {},
      dbKickarounds: {},
      dbKickaroundGames: {},
      dbKickaroundPlayers: {},
      dbKickaroundGamePlayers: {},
      dbKickaroundDataLoaded: false
    });
  }
}
