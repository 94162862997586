import { Injectable, NgZone } from '@angular/core';

import Tracker from '@openreplay/tracker';
import trackerAssist from '@openreplay/tracker-assist';
import type {Options as NetworkOptions} from "@openreplay/tracker/lib/modules/network";
import {environment} from "../../../environments/environment";

const networkOptions: NetworkOptions = {
  captureInIframes: false,
  failuresOnly: false,
  ignoreHeaders: undefined,
  sessionTokenHeader: undefined,
  capturePayload: true
};

@Injectable({
  providedIn: 'root',
})
export class OpenReplayService {
  public tracker?: Tracker | null;

  constructor(private zone: NgZone) {
    this.zone.runOutsideAngular(() => {
      this.tracker = new Tracker({
        projectKey: environment.openReplayKey,
        network: networkOptions,
      });

      this.tracker.use(
        trackerAssist({
          confirmText: `You have an incoming call from FootballMatcher Support. Do you want to answer?`,
        })
      );
    });
  }

  public async start() {
    this.zone.runOutsideAngular(() => {
      if (this.tracker) {
        return this.tracker.start();
      } else {
        return {
          sessionID: null,
          sessionToken: null,
          userUUID: null,
        };
      }
    });
  }

  public setUserData(playerId: string, name: string, email: string): void {
    this.zone.runOutsideAngular(() => {
      if (this.tracker && email) {
        this.tracker.setUserID(String(email));
        this.tracker.setMetadata('name', name);
        this.tracker.setMetadata('playerId', playerId);
      }
    });
  }
}
