import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  public errorMessage = 'An error has occurred, please try again.'
  public fromUrl: string;
  public returnUrl: string;

  public handleAndRouteAfterError(router, error) {
      this.errorMessage = error;
      router.navigate([ 'error' ], {});
  }
}
