import {ResponseError} from '../../../shared/state/fm-state.interfaces';

export function formatCognitoError(serverError: any): ResponseError {
  const errorType = serverError['error'] !== undefined ? serverError['error']['__type'] : undefined;
  const errorMessage = serverError['error'] !== undefined ? serverError['error']['message'] : undefined;
  return {
    show: true,
    type: errorType,
    message: errorMessage
  };
}
