import {Environment} from '../models/environment.model';
import {sportConfig} from '../sport';
import {NgxLoggerLevel} from 'ngx-logger';

export const environment: Environment = {
  name: 'dev',
  production: false,
  oauthBaseDomain: `auth-dev.footballmatcher.io`,
  authBaseUrl: `https://api-dev.${sportConfig.domain}/auth/user`,
  api: `https://api-dev.${sportConfig.domain}`,
  apiEb: `https://api-dev-eb.${sportConfig.domain}`,
  baseUrl: `https://app-dev.${sportConfig.domain}`,
  baseWwwUrl: `https://app-dev.${sportConfig.domain}`,
  gofmUrl: 'https://gofm.io',
  worldpayClientKey: 'T_C_8eaf56c9-79af-4505-9f54-10efd2cd17d8',
  cognitoClientId: '4to0mcmdp4i51brol1244ospus',
  cognitoRegion: 'eu-west-1',
  cognitoUserPoolId: 'eu-west-1_xLp4rCasH',
  vapd_public_key: 'BBF4KAf6u_DjhVbzCVfkSUIcsAT965ldo4pOqTlACb8gmLFTP53GQB40FTWXl6-HW9IR0_MTMO1Ex4i6vOb4PKA',
  hmr: false,
  swUpdateType: 'optional',
  loggingLevel: NgxLoggerLevel.TRACE,
  disableLogging: false,
  serverLoggingUrl: `https://api-dev-eb.${sportConfig.domain}/public/monitoring/errors`,
  serverLogLevel: NgxLoggerLevel.WARN,
  serverLogLEveryError: false,
  mockUserData: false,
  disableLandingPage: true,
  openReplayKey: 'E2eCuaP9XG6pqwYLSjGp',
  defaultLocationId: 185763,
  mixpanelKey: '94b1608cda6304762ff8dcd3d72ac571',
};
