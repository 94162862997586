import {Inject, Injectable} from '@angular/core';
import {Subscription} from "rxjs";
import {FMOnboardingService, OnboardingEvents} from "../fm-onboarding.service";
import {Store} from "@ngxs/store";
import {WINDOW} from "../../../shared/services/fm-windows.service";
import {KickaroundsSecureSelectors} from "../../kickarounds/store/selectors/kickarounds-secure.selectors";
import {Kickaround} from "../../kickarounds/models/database/kickaround.model";
import {UserSelectors} from "../../user/state/user.selectors";
import {Player} from "../../user/models/player.model";
import {Router} from "@angular/router";
import {GetKickaround, GetKickaroundUserData} from "../../kickarounds/store/actions/kickarounds-secure.actions";

const CHECKLIST_NAME = 'New Group Checklist'
const CHECKLIST_ID = '3981'
const ITEM_ID_SCHEDULE_GAME = 'newGroupChecklist-ScheduleGame'
const ITEM_ID_CUSTOMISE_GROUP = 'newGroupChecklist-CustomiseGroup'
const ITEM_ID_INVITE_TO_GROUP = 'newGroupChecklist-InviteToGroup'
const ITEM_ID_SEND_GAME_INVITES = 'newGroupChecklist-SendGameInvites'
const ITEM_ID_CONFIRM_GAME = 'newGroupChecklist-ConfirmGame'
const ITEM_ID_SELECT_TEAMS = 'newGroupChecklist-SelectTeams'
const ITEM_ID_ADD_BANK_DETAILS = 'newGroupChecklist-AddBankDetails'
const ITEM_ID_RECORD_SCORE = 'newGroupChecklist-RecordScore'

const TOUR_SCHEDULE_GAME_ID = 60830;

@Injectable({
  providedIn: 'root'
})
export class FMGroupOnboardingChecklistService {

  subscriptions: Subscription[] = [];
  kickarounds: Kickaround[];
  latestKickaround: Kickaround;
  loggedInUser;
  loggedInPlayer: Player;

  constructor(
    private fMOnboardingService: FMOnboardingService,
    private store: Store, @Inject(WINDOW)
    private window,
    private router: Router
  ) {
    this.getSnapshots();
    this.setupStoreSubscriptions();
    this.setupEventSubscriptions();
    this.setupChecklistItemOpenSubscriptions();
  }

  getSnapshots(){
    this.kickarounds = this.store.selectSnapshot(KickaroundsSecureSelectors.allKickarounds);
    this.loggedInUser = this.store.selectSnapshot(UserSelectors.user);
    this.loggedInPlayer = this.store.selectSnapshot(UserSelectors.player);
    if(this.kickarounds.length > 0) {
      this.latestKickaround = this.kickarounds[this.kickarounds.length-1];
    }
  }

  setupStoreSubscriptions(){
    this.subscriptions.push(this.store.select(KickaroundsSecureSelectors.allKickarounds).subscribe(
      kas => {
        this.kickarounds = kas;
        if(this.kickarounds.length > 0) {
          this.latestKickaround = this.kickarounds[this.kickarounds.length-1];
        }
      }));
  }

  setupEventSubscriptions(){
    this.subscriptions.push(this.fMOnboardingService.getEventSubject$().subscribe(
      // This is where you setup what you want to happen when an item is published in the stream.
      onboardingEvent => {
        this.handleEvent(onboardingEvent);
      },
      error => {
        console.error('Received an error from the stream:', error);
      },
      () => {
        console.log('The stream has been completed.');
      }
    ));
  }

  setupChecklistItemOpenSubscriptions(){
    this.subscriptions.push(this.fMOnboardingService.checklistItemLaunchedEventsSubject$().subscribe(
      internalId => {
        console.log('Received data from the checklist item opened stream:', internalId);
        this.handleChecklistItemLaunchedEvent(internalId);
      },
      error => {
        console.error('Received an error from the stream:', error);
      },
      () => {
        console.log('The stream has been completed.');
      }
    ));
  }

  handleEvent(onboardingEvent: OnboardingEvents) {
    switch (onboardingEvent) {
      case OnboardingEvents.ScheduleGame:
        this.fMOnboardingService.markItemAsDone(ITEM_ID_SCHEDULE_GAME, false);
        break;
      case OnboardingEvents.CustomizeGroup:
        this.fMOnboardingService.markItemAsDone(ITEM_ID_CUSTOMISE_GROUP, false);
        break;
      case OnboardingEvents.InviteToGroup:
        this.fMOnboardingService.markItemAsDone(ITEM_ID_INVITE_TO_GROUP, false);
        break;
      case OnboardingEvents.SendGameInvites:
        this.fMOnboardingService.markItemAsDone(ITEM_ID_SEND_GAME_INVITES, false);
        break;
      case OnboardingEvents.ConfirmGame:
        this.fMOnboardingService.markItemAsDone(ITEM_ID_CONFIRM_GAME, false);
        break;
      case OnboardingEvents.SelectTeams:
        this.fMOnboardingService.markItemAsDone(ITEM_ID_SELECT_TEAMS, false);
        break;
      case OnboardingEvents.UpdateBank:
        this.fMOnboardingService.markItemAsDone(ITEM_ID_ADD_BANK_DETAILS, false);
        break;
      case OnboardingEvents.RecordScore:
        this.fMOnboardingService.markItemAsDone(ITEM_ID_RECORD_SCORE, false);
        break;
    }
  }

  handleChecklistItemLaunchedEvent(internalId){
    console.log(internalId)
    switch (internalId) {
      case ITEM_ID_SCHEDULE_GAME:
        console.log('ITEM_ID_SCHEDULE_GAME')
        this.router.navigateByUrl(`/secure/kickarounds/${this.latestKickaround.id}/info`);
        this.fMOnboardingService.launchTour(60830);
        break;
      case ITEM_ID_CUSTOMISE_GROUP:
        // this.router.navigate(['/secure/kickarounds', this.latestKickaround.id]);
        this.fMOnboardingService.launchTour(60834);
        this.router.navigateByUrl(`/secure/kickarounds/${this.latestKickaround.id}/games/${this.latestKickaround.latestGameId}`);
        break;
      case ITEM_ID_INVITE_TO_GROUP:
        this.router.navigateByUrl(`/secure/kickarounds/${this.latestKickaround.id}/games/${this.latestKickaround.latestGameId}`);
        this.fMOnboardingService.launchTour(60835);
        break;
      case ITEM_ID_SEND_GAME_INVITES:
        console.log('Navigate to send game')
        this.router.navigateByUrl(`/secure/kickarounds/${this.latestKickaround.id}/games/${this.latestKickaround.latestGameId}`);
        this.fMOnboardingService.launchTour(60836);
        break;
      case ITEM_ID_CONFIRM_GAME:
        this.router.navigateByUrl(`/secure/kickarounds/${this.latestKickaround.id}/games/${this.latestKickaround.latestGameId}`);
        this.fMOnboardingService.launchTour(60837);
        break;
      case ITEM_ID_SELECT_TEAMS:
        this.router.navigateByUrl(`/secure/kickarounds/${this.latestKickaround.id}/games/${this.latestKickaround.latestGameId}`);
        this.fMOnboardingService.launchTour(60838);
        break;
      case ITEM_ID_ADD_BANK_DETAILS:
        this.router.navigateByUrl(`/secure/user/payments`);
        this.fMOnboardingService.launchTour(60839);
        break;
      case ITEM_ID_RECORD_SCORE:
        this.store.dispatch(new GetKickaroundUserData()).toPromise().then(
          () => {
            this.router.navigateByUrl(`/secure/kickarounds/${this.latestKickaround.id}/games/${this.latestKickaround.latestGameId}`);
            this.fMOnboardingService.launchTour(60839);
          }
        )
        break;
    }
  }

  ngOnDestroy() {
    this.subscriptions.map(s => s.unsubscribe());
  }
}
