import {autoserialize, autoserializeAs, deserialize, deserializeAs} from 'cerialize';
import {KickaroundGamePlayer} from './kickaround-game-player.model';
import {ConfirmationModeChoices, PaymentProcessors, WaitingListModeChoices} from '../../kickarounds.enums';

export class KickaroundGame {
  @deserialize pk?: number;
  @deserialize id?: number;
  @deserialize version?: number;
  @deserialize kickaround?: number;
  @deserialize kickaroundId?: number;
  @deserialize gameHost?: number;
  @deserialize duration: number;
  @deserialize cancelReason: string;
  @deserialize status: string;
  @deserializeAs(KickaroundGamePlayer) player_statuses?: KickaroundGamePlayer[];
  @deserializeAs(Date) start: Date;
  @deserialize organiser?: number;
  @deserializeAs(Date) inviteSendDatetime?: Date;
  @deserializeAs(Number) price?: number;
  @deserialize fixedPlayerPrice?: number;
  @deserialize dynamicPlayerPrice?: number;
  @deserialize numberOfPayments?: number;
  @deserialize numberOfAcceptances?: number;
  @deserialize prePaidRemainingGames?: number;
  @deserialize prePaid?: boolean;
  @deserialize numberOfInvitedPlayers?: number;
  @deserialize bannerUrl?: string;
  @deserialize logoUrl?: string;
  @deserialize badgeUrl?: string;
  @deserialize paymentProcessor?: PaymentProcessors;
  @deserializeAs(ConfirmationModeChoices) confirmGameMode?: ConfirmationModeChoices;
  @deserialize autoConfirmBeforeGameDelayHours: number;
  @deserializeAs(WaitingListModeChoices) waitingListMode?: WaitingListModeChoices;
  @deserialize waitingLeadTimeMins?: number;
  @deserialize waitingListPay2PlayExpiryMins?: number;
  @deserialize waitingListBeforeGameClearHours?: number;
  @deserialize priorityInviteExpiryHours: number;
  @deserialize pay2PlayExpiryMins: number;
  @deserializeAs(Number) minRequiredPlayers?: number;
  @deserializeAs(Number) maxRequiredPlayers?: number;
  @deserializeAs(Number) totalCashPayments?: number;
  @deserializeAs(Number) totalOnlinePayments?: number;
  @deserializeAs(Number) finalPlayerFee?: number;
  @deserializeAs(Number) finalPlayerPrice?: number;
  @deserializeAs(Number) minPricePerPlayer?: number;
  @deserializeAs(Number) maxPricePerPlayer?: number;
  @deserialize finalPlayerPriceAudit?: any;
  @deserialize finalPlayerPriceCurrency?: string;
  @deserialize pricingModel?: string;
  @deserialize latestRelativeGameId?: number;
  @deserialize currency?: string;
  @deserialize playerPricingMode?: string;
  @deserialize numberOfTeams?: number;
  @autoserialize paymentMode: string;
  @deserializeAs(Date) estimatedPayoutDate: Date;

  // Generated on frontend
  totalTransferrable?: number;
  totalReceived?: number;
  totalFees?: number;
  expectedPaymentDate?: Date;
  transferStatus?: string;
}

