import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnackBarComponent } from './snackbar/snack-bar.component';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';

@NgModule({
    declarations: [
        SnackBarComponent
    ],
    imports: [
        CommonModule,
        MatSnackBarModule,
    ]
})
export class FMAnnouncementsModule { }
