import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatLegacyMenuTrigger as MatMenuTrigger} from '@angular/material/legacy-menu';
import {faBars} from '@fortawesome/free-solid-svg-icons/faBars';
import {faBell} from '@fortawesome/free-solid-svg-icons/faBell';

import {LoginService} from '../../../user/services/login.service';
import {Router} from '@angular/router';
import {Store} from '@ngxs/store';
import {UserSelectors} from '../../../user/state/user.selectors';
import {Subscription} from 'rxjs';
import {Logout, SocialLoginSignOut} from '../../../user/state/user.actions';
import {ResetSecureKickaroundState} from '../../../kickarounds/store/actions/kickarounds-secure.actions';
import {FMSportsDetectorService} from '../../../../shared/services/fm-sports-detector.service';
import {SportConfig} from '../../../../../environments/models/sport.model';
import {SocialLoginService} from '../../../user/services/social-login.service';
import {productFruits} from "product-fruits";
import {faUser} from "@fortawesome/free-solid-svg-icons/faUser";

@Component({
  selector: 'fm-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent implements OnInit, OnDestroy {

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  @Input()
  isTransparent = false;

  subscriptions: Subscription[] = [];
  hasCreateKickaroundFeature = false;
  isLoggedOn = false;
  isCollapsed = true;
  firstName = '';

  // icons
  faBars = faBars;
  faBell = faBell;
  faUser = faUser;
  numberOfUnread = 0;

  // config
  sportConfig: SportConfig;
  newsMenuLoaded = false;

  constructor(
    public loginService: LoginService,
    public socialLoginService: SocialLoginService,
    public router: Router,
    public store: Store,
    public sportsDetectorService: FMSportsDetectorService
  ) {
    this.isLoggedOn = loginService.isLoggedOn;
    this.sportConfig = sportsDetectorService.getSportConfig();
    this.setUpNewsFeed();
  }

  ngOnInit() {
    this.subscriptions.push(this.store.select<boolean>(UserSelectors.hasFeature('CREATE_KICKAROUND'))
      .subscribe(hasFeature => this.hasCreateKickaroundFeature = hasFeature)
    );
      this.subscriptions.push(this.store.select<boolean>(UserSelectors.isLoggedOn)
      .subscribe(isLoggedOn => this.isLoggedOn = isLoggedOn)
    );
    this.subscriptions.push(this.store.select(UserSelectors.player)
      .subscribe(p => {
        if (p) {
          const splitName = p.fullName.split(' ')
          this.firstName = splitName[0];
        }
      }));
    this.newsMenuLoaded = false;
  }

  async logOut() {
    await this.store.dispatch(new Logout());
    await this.store.dispatch(new ResetSecureKickaroundState());
    if (this.socialLoginService.loggedIn) {
      this.store.dispatch(new SocialLoginSignOut());
    }
  }

  close() {
    this.isCollapsed = true;
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe());
  }

  setUpNewsFeed() {
    productFruits.safeExec(($productFruits) => {
      // When your custom element is rendered in your application.
      // If you use React, get a "ref" is the launcher element
      const customLauncher = document.getElementById('newsfeed-launcher');

      // If you want to render a badge with number of unread items...
      //
      // If you want to get the initial number of unread items,
      // attach this event BEFORE the attachNewsWidgetToElement method call.
      window['productFruits'].api.announcementsV2.listen('newsfeed-unread-count-changed', (data) => {
        this.numberOfUnread = data.count;
      });

      // Later, when the PF JS API is available, call the following API method and pass the element instance.
      try {
        window['productFruits'].api.announcementsV2.attachNewsWidgetToElement(customLauncher);
        this.newsMenuLoaded = true;
      } catch (e) {
        this.newsMenuLoaded = false
      }
    });
  }
}
