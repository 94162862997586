import {Action, State, StateContext} from '@ngxs/store';

import {Injectable} from '@angular/core';
import {SocialLoginRedirectService} from '../services/social-login-redirect.service';
import {ProcessSocialLoginPostRedirectWorkflow} from './social-login-redirect.actions';
import {Observable} from 'rxjs';

export interface SocialLoginRedirectStateModel {
  redirectRoute: any;
  redirectRouteExtras: any;
}

@Injectable()
@State<SocialLoginRedirectStateModel>({
  name: 'socialLoginRedirectState',
  defaults: {
    redirectRoute: null,
    redirectRouteExtras: null,
  }
})
export class SocialLoginRedirectState {

  constructor(
    private socialLoginRedirectService: SocialLoginRedirectService,
  ) {
  }

  @Action(ProcessSocialLoginPostRedirectWorkflow)
  processSocialLoginPostRedirectWorkflow(ctx: StateContext<SocialLoginRedirectStateModel>, action: ProcessSocialLoginPostRedirectWorkflow) {
    return new Observable(observer => {
      this.socialLoginRedirectService.processSocialLoginPostRedirectWorkflow().then(redirectData => {
        ctx.patchState({
          redirectRoute: redirectData.redirectRoute,
          redirectRouteExtras: redirectData.extras
        });
        observer.next(redirectData);
        observer.complete();
      }, (error) => {
        observer.error(error);
        observer.complete();
      });
    });
  }
}
