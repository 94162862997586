import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Router, RouterModule} from '@angular/router';
import uk from '@angular/common/locales/uk';
import {registerLocaleData} from '@angular/common';
import {ServiceWorkerModule} from '@angular/service-worker';

import {NgxsModule} from '@ngxs/store';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {MarkdownModule} from 'ngx-markdown';
import {LoggerModule} from 'ngx-logger';
// import * as Sentry from "@sentry/angular-ivy";

import {environment} from '../environments/environment';
import {AppComponent} from './app.component';
import {routes} from './app.routing';
import {SecureComponent} from './routes/secure/secure.component';
import {PublicComponent} from './routes/public/public.component';
import {ProgressService} from './features/progress/services/progress.service';
import {FMSWUpdaterService} from './shared/services/fm-sw-updater';
import {HttpTimeoutInterceptor} from './shared/interceptors/http-timeout-interceptor';
import {HttpCognitoTokenInterceptor} from './features/user/interceptors/http-cognito-token-interceptor';
import {ProgressSpinnerModule} from './features/progress/progress.module';
import {FMLayoutModule} from './features/layout/layout.module';
import {WINDOW_PROVIDERS} from './shared/services/fm-windows.service';
import {UserState} from './features/user/state/user.state';
import {SocialLoginRedirectState} from './features/user/state/social-login-redirect.state';
import {FMAnnouncementsModule} from './shared/modules/announcements/announcements.module';
import {CookieLawModule} from 'angular2-cookie-law';
import {PerformanceService} from './shared/services/fm-performance.service';
import {FMAnalyticsService} from './features/analytics/fm-analytics.service';
import {RepeatDirective} from './shared/directives/fm-repeater.directive';
import {FMErrorHandlerService} from './shared/services/fm-error-handler';
import {NgxsFormPluginModule} from '@ngxs/form-plugin';
import {NgxsRouterPluginModule} from '@ngxs/router-plugin';
import {MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions} from "@angular/material/tooltip";
import {HttpConfigInterceptor} from "./shared/interceptors/openreplay";
import {OpenReplayService} from "./shared/services/fm-openreplay-service";
import {ReplaySessionService} from "./shared/services/fm-replay-service";

registerLocaleData(uk);

@NgModule({
  declarations: [
    AppComponent,
    SecureComponent,
    PublicComponent,
    RepeatDirective,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FMAnnouncementsModule,
    HttpClientModule,
    NgxsRouterPluginModule.forRoot(),
    NgxsModule.forRoot([UserState, SocialLoginRedirectState], {
      developmentMode: !environment.production
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production
    }),
    NgxsFormPluginModule.forRoot(),
    RouterModule.forRoot(routes, {
    enableTracing: false,
    scrollOffset: [0, 0],
    scrollPositionRestoration: 'disabled',
    onSameUrlNavigation: 'reload',
    anchorScrolling: 'disabled',
    paramsInheritanceStrategy: 'always'
    }),
    // ServiceWorkerModule.register('/ngsw-worker.js',       {
    //   enabled: (!environment.hmr && environment.name !== 'local')
    // }),
    CookieLawModule,
    FMLayoutModule,
    ProgressSpinnerModule,
    LoggerModule.forRoot({
      level: environment.loggingLevel,
      // serverLogLevel: environment.serverLogLevel,
      // serverLoggingUrl: environment.serverLoggingUrl,
      httpResponseType: 'text',
      disableConsoleLogging: false
    })
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true},
    // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     showDialog: true,
    //   }),
    // },
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => {},
    //   deps: [Sentry.TraceService],
    //   multi: true,
    // },
    ...WINDOW_PROVIDERS,
    FMSWUpdaterService,
    FMAnalyticsService,
    PerformanceService,
    ProgressService,
    OpenReplayService,
    ReplaySessionService,
    {
      provide: LOCALE_ID, useValue: 'en-GB',
    },
    {
      provide: Window, useValue: window,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTimeoutInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCognitoTokenInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: FMErrorHandlerService
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
