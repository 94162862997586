import {Buffer} from 'buffer';
import CryptoJS from 'crypto-js/core';
import Base64 from 'crypto-js/enc-base64';
import HmacSHA256 from 'crypto-js/hmac-sha256';
import BigInteger from './BigInteger';
import SRPMaths from './SRPMaths';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LocalStorageService} from './local-storage.service';
import {CognitoAction, CognitoService} from './cognito.service';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {Subject} from 'rxjs';

;
import DeviceAuthService from './device-auth-service';

@Injectable({
  providedIn: 'root',
})
export class SRPAuthService {
  private tokensReceived = new Subject<any>();
  private deviceKey = undefined;
  private authenticationFlowType = 'USER_SRP_AUTH';

  constructor(private http: HttpClient,
              private deviceAuthService: DeviceAuthService,
              private localStorageService: LocalStorageService,
              private cognitoService: CognitoService) {
  }

  authUser(username, password, deviceKey, deviceGroupKey, clientId): Observable<any> {
    return new Observable((observer) => {
      try {
        this.tokensReceived = new Subject<any>();
        this.authenticateUserDefaultAuth(username, password, deviceKey, deviceGroupKey);
        this.tokensReceived.asObservable().subscribe(tokens => {
            observer.next(tokens);
            observer.complete();
          },
          error1 => {
            observer.error(error1);
            observer.complete();
          });
      } catch (err) {
        observer.error(err);
        observer.complete();
      }
    });
  }

  authenticateUserDefaultAuth(username, password, deviceKey, deviceGroupKey) {
    const srpMaths = new SRPMaths(environment.cognitoUserPoolId.split('_')[1]);
    const authParameters: any = {};

    if (deviceKey != null) {
      authParameters.DEVICE_KEY = deviceKey;
    }

    authParameters.USERNAME = username;
    srpMaths.getLargeAValue((errOnAValue, aValue) => {
      authParameters.SRP_A = aValue.toString(16);

      if (this.authenticationFlowType === 'CUSTOM_AUTH') {
        authParameters.CHALLENGE_NAME = 'SRP_A';
      }

      const clientMetaData = {};
      const jsonReq = {
        AuthFlow: this.authenticationFlowType,
        ClientId: this.cognitoService.CLIENT_ID,
        AuthParameters: authParameters,
        ClientMetadata: clientMetaData,
      };

      this.http.post(this.cognitoService.BASE_URL,
        jsonReq,
        {headers: this.cognitoService.getCommonHeaders(CognitoAction.ACTION_INITIATE_AUTH)}).subscribe(
        data => {
          this.processSRPchallenge(username, password, deviceKey, deviceGroupKey, srpMaths, environment.cognitoUserPoolId, this.cognitoService.CLIENT_ID, clientMetaData, data);
        }, err => {
          this.tokensReceived.error(err);
        });
    });
  }

  processSRPchallenge(username, password, deviceKey, deviceGroupKey, srpMaths, userPoolId, clientId, clientMetaData, data) {
    const challengeParameters = data.ChallengeParameters;
    const serverBValue = new BigInteger(challengeParameters.SRP_B, 16);
    const salt = new BigInteger(challengeParameters.SALT, 16);

    srpMaths.getPasswordAuthenticationKey(
      username,
      password,
      serverBValue,
      salt,
      (errOnHkdf, hkdf) => {
        const dateNow = srpMaths.getNowString();
        const message = CryptoJS.lib.WordArray.create(
          Buffer.concat([
            Buffer.from(userPoolId.split('_')[1], 'utf8'),
            Buffer.from(username, 'utf8'),
            Buffer.from(challengeParameters.SECRET_BLOCK, 'base64'),
            Buffer.from(dateNow, 'utf8'),
          ])
        );
        const key = CryptoJS.lib.WordArray.create(hkdf);
        const signatureString = Base64.stringify(HmacSHA256(message, key));
        const challengeResponses: any = {};

        challengeResponses.USERNAME = username;
        challengeResponses.PASSWORD_CLAIM_SECRET_BLOCK = challengeParameters.SECRET_BLOCK;
        challengeResponses.TIMESTAMP = dateNow;
        challengeResponses.PASSWORD_CLAIM_SIGNATURE = signatureString;

        if (deviceKey != null) {
          challengeResponses.DEVICE_KEY = deviceKey;
        }

        const jsonReqResp = {
          ChallengeName: 'PASSWORD_VERIFIER',
          ClientId: clientId,
          ChallengeResponses: challengeResponses,
          Session: data.Session,
          ClientMetadata: clientMetaData,
        };

        this.http.post(this.cognitoService.BASE_URL,
          jsonReqResp,
          {headers: this.cognitoService.getCommonHeaders(CognitoAction.ACTION_RESPOND_TO_AUTH_CHALLENGE)}).subscribe(
          dataTokensOrChallange => {
            const tokensOrChallenge: any = dataTokensOrChallange;
            if (tokensOrChallenge.ChallengeName === 'DEVICE_SRP_AUTH') {
              this.deviceAuthService.authDevice(username, deviceKey, deviceGroupKey, clientId).subscribe(
                tokens => {
                  this.tokensReceived.next(tokens);
                },
                error => {
                  this.handleDeviceNotRegistered(username, password, deviceKey, deviceGroupKey, clientId, error);
                },
              );
            } else {
              this.tokensReceived.next(tokensOrChallenge);
              return tokensOrChallenge;
            }
          }, err => {
            this.handleDeviceNotRegistered(username, password, deviceKey, deviceGroupKey, clientId, err);
          });
      });
  }

  private handleDeviceNotRegistered(username, password, deviceKey, deviceGroupKey, clientId, err) {
    if (err && err.error && err.error.message && err.error.message.includes('Device does not exist')) {
      this.localStorageService.removeValueForKey('dgk');
      this.localStorageService.removeValueForKey('dk');
      this.localStorageService.removeValueForKey('dkp');
      this.authenticateUserDefaultAuth(username, password, null, null);
    } else {
      this.tokensReceived.error(err);
    }
  }
}
