import {deserialize, deserializeAs} from 'cerialize';
import {Player} from '../../../user/models/player.model';
import {InvitePriorityDBChoices} from '../../enums/invite-priority.enum';

export class KickaroundGamePlayer {
  @deserialize id?: number;
  @deserialize onBehalfOfDisplayName: boolean;
  @deserialize displayName: string;
  @deserialize version?: number;
  @deserialize kickaroundId?: number;
  @deserialize game?: number;
  @deserialize gameId?: number;
  @deserialize fmFundingPercentage?: number;
  @deserialize previousGameStatus: string;
  @deserialize gameStatus: string;
  @deserialize playerId?: number;
  @deserialize playerName?: string;
  @deserializeAs(Player) player: Player;
  @deserialize paymentStatus: string;
  @deserialize paymentRecord?: number;
  @deserializeAs(Date) lastUpdatedOn: Date;
  @deserializeAs(Date) expiryDate: Date;
  @deserialize inviteToken: string;
  @deserialize paymentContext: string;
  @deserializeAs(InvitePriorityDBChoices) priority?: InvitePriorityDBChoices;
  @deserialize prePaid?: boolean;
  @deserialize prePaidRemainingGames?: number;
  @deserialize isBanned?: boolean;
  @deserialize noYellowCards?: number;
  @deserialize organiserDiscountPercentage: number;
}
