import {createSelector, Selector} from '@ngxs/store';
import {UserState, UserStateModel} from './user.state';

export class UserSelectors {
  // Public only
  @Selector([UserState])
  static gamePlayerId(state: UserStateModel) {
    return state.gamePlayerId;
  }

  // Public only
  @Selector([UserState])
  static gamePlayerToken(state: UserStateModel) {
    return state.gamePlayerToken;
  }

  @Selector([UserState])
  static attemptedSecureUrl(state: UserStateModel) {
    return state.attemptedSecureUrl;
  }

  @Selector([UserState])
  static upvotyToken(state: UserStateModel) {
    return state.upvotyToken;
  }

  @Selector([UserState])
  static discountCode(state: UserStateModel) {
    return state.discountCode;
  }

  @Selector([UserState])
  static organisation(state: UserStateModel) {
    return state.organisation;
  }

  @Selector([UserState])
  static hasAvailability(state: UserStateModel) {
    return state.hasAvailability;
  }

  @Selector([UserState])
  static userFeatures(state: UserStateModel) {
    return state.features;
  }

  @Selector([UserState])
  static payoutMode(state: UserStateModel) {
    return state.payoutMode;
  }

  @Selector([UserState])
  static isLoggedOn(state: UserStateModel) {
    return state.isLoggedOn;
  }

  @Selector([UserState])
  static user(state: UserStateModel) {
    return state.user;
  }

  @Selector([UserState])
  static userType(state: UserStateModel) {
    return state.userType;
  }

  @Selector([UserState])
  static player(state: UserStateModel) {
    return state.player;
  }

  @Selector([UserState])
  static features(state: UserStateModel) {
    return state.features;
  }

  @Selector([UserState])
  static gameGoFMToken(state: UserStateModel) {
    return state.gameGoFMToken;
  }

  @Selector([UserState])
  static campaignToken(state: UserStateModel) {
    return state.campaignToken;
  }

  @Selector([UserState])
  static affiliateToken(state: UserStateModel) {
    return state.affiliateToken;
  }

  @Selector([UserState])
  static gameAction(state: UserStateModel) {
    return state.gameAction;
  }

  static hasFeature(featureName: string) {
    return createSelector([UserState], (state: UserStateModel) => {
      return state.features.find(f => f.featureName === featureName) !== undefined;
    });
  };

}
