import {
  deserializeIndexable,
} from 'cerialize';

import {Player} from '../../../user/models/player.model';
import {KickaroundGamePlayer} from '../database/kickaround-game-player.model';
import {OpenDataSite} from '../../../location/models/open-data-site.model';
import {Payment} from '../../../payments/models/database/payment.model';
import {Kickaround} from '../database/kickaround.model';
import {KickaroundPlayer} from '../database/kickaround-player.model';
import {FMLocation} from '../../../location/models/location.model';
import {KickaroundGame} from '../database/kickaround-game.model';
import {IDMap} from '../../../../shared/interfaces/idmap.interface';
import {KickaroundPermissions} from '../database/kickaroundPermissions.model';

export class KickaroundUserData {
  @deserializeIndexable(KickaroundGamePlayer) loggedInGamePlayers: IDMap<KickaroundGamePlayer>;
  @deserializeIndexable(OpenDataSite) openDataSites: IDMap<OpenDataSite>;
  @deserializeIndexable(FMLocation) locations: IDMap<FMLocation>;
  @deserializeIndexable(Payment) payments: IDMap<Payment>;
  @deserializeIndexable(Player) players: IDMap<Player>;
  @deserializeIndexable(Kickaround) kickarounds: IDMap<Kickaround>;
  @deserializeIndexable(KickaroundPermissions) kickaroundPermissions: IDMap<KickaroundPermissions>;
  @deserializeIndexable(KickaroundGame) kickaroundGames: IDMap<KickaroundGame>;
  @deserializeIndexable(KickaroundPlayer) kickaroundPlayers: IDMap<KickaroundPlayer>;
  @deserializeIndexable(KickaroundGamePlayer) kickaroundGamePlayers: IDMap<KickaroundGamePlayer>;
}
