import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Store} from '@ngxs/store';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {LocalStorageService} from './local-storage.service';
import {SaveSocialLoginPostAuthState} from '../state/user.actions';
import {NGXLogger} from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class SocialLoginService {
  constructor(private store: Store,
              private httpClient: HttpClient,
              private localStorageService: LocalStorageService,
              private logger: NGXLogger,
  ) {
  }

  private signOutBaseUrl = 'https://' + environment.oauthBaseDomain + '/';
  private authBaseUrl = 'https://' + environment.oauthBaseDomain + '/oauth2';
  public loggedIn = false;
  public GOOGLE = FMSocialIdentityProvider.Google;
  public FACEBOOK = FMSocialIdentityProvider.Facebook;
  public AMAZON = FMSocialIdentityProvider.Amazon;
  public APPLE = FMSocialIdentityProvider.Apple;

  static cognitoTokenHeaders(): HttpHeaders {
    return new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
  }

  static getProvider(provider) {
    if (provider === 'Google') {
      return FMSocialIdentityProvider.Google;
    } else if (provider === 'Facebook') {
      return FMSocialIdentityProvider.Facebook;
    } else if (provider === 'LoginWithAmazon') {
      return FMSocialIdentityProvider.Amazon;
    } else if (provider === 'Apple') {
      return FMSocialIdentityProvider.Apple;
    } else {
      return FMSocialIdentityProvider.Cognito;
    }
  }

  generateOAuthSignInUri(provider: FMSocialIdentityProvider, redirectUrl: string) {
    return this.authBaseUrl + '/authorize?' + 'redirect_uri=' + redirectUrl + '&identity_provider=' + provider + '&response_type=code' + '&client_id=' + environment.cognitoClientId + '&scope=phone%20email%20profile%20openid%20aws.cognito.signin.user.admin';
  }

  generateOAuthSignOutUrl(logoutRoute: string) {
    const logoutUrl = environment.baseUrl + logoutRoute;
    return this.signOutBaseUrl + '/logout?' + 'logout_uri=' + logoutUrl + '&client_id=' + environment.cognitoClientId;
  }

  signOut(logoutUrl = '/') {
    this.loggedIn = false;
    window.location.href = this.generateOAuthSignOutUrl(logoutUrl);
    this.logger.info('Signed Out of Social Network');
  }

  socialSignIn(provider: FMSocialIdentityProvider, redirectRoute: string, isWwww: boolean) {
    this.localStorageService.saveValueForKey('OATH2_RETRY_STATE', 'VALID');
    this.localStorageService.saveValueForKey('OATH2_PROVIDER', provider);
    let redirectUrl = environment.baseUrl + redirectRoute;
    if (isWwww) {
      redirectUrl = environment.baseWwwUrl + redirectRoute;
    }
    this.localStorageService.saveValueForKey('OATH2_REDIRECT', redirectUrl);
    window.location.href = this.generateOAuthSignInUri(provider, redirectUrl);
  }

  validForRetry() {
    const retryState = this.localStorageService.getValueForKey('OATH2_RETRY_STATE');
    return retryState === 'VALID';
  }

  retrySocialSignIn() {
    if (this.validForRetry()) {
      this.logger.info('retry requested - going ahead with retry ...');
      const redirectRoute = this.localStorageService.getValueForKey('OATH2_REDIRECT');
      const provider = this.localStorageService.getValueForKey('OATH2_PROVIDER');
      this.clearOauthState();
      const fmProvider = SocialLoginService.getProvider(provider);
      const redirectUrl = environment.baseUrl + redirectRoute;
      this.localStorageService.saveValueForKey('OATH2_REDIRECT', redirectUrl);
      this.localStorageService.saveValueForKey('OATH2_PROVIDER', provider);
      window.location.href = this.generateOAuthSignInUri(fmProvider, redirectRoute);
    } else {
      this.clearOauthState();
      this.logger.info('retry requested but not valid for social login retry');
    }
  }

  clearOauthState() {
    this.localStorageService.removeValueForKey('OATH2_RETRY_STATE');
    this.localStorageService.removeValueForKey('OATH2_REDIRECT');
    this.localStorageService.removeValueForKey('OATH2_PROVIDER');
  }

  getCognitoTokens(socialLoginCode: string, redirectRoute: string) {
    const url = this.authBaseUrl + '/token';
    const encodeString = 'grant_type=authorization_code&code=' + socialLoginCode + '&redirect_uri=' + redirectRoute + '&code_verifier=' + socialLoginCode + '&client_id=' + environment.cognitoClientId;
    return this.httpClient.post(
      url,
      encodeString,
      {headers: SocialLoginService.cognitoTokenHeaders()}
    ).pipe(
      map((tokens: any) => {
        this.loggedIn = true;
        this.store.dispatch(new SaveSocialLoginPostAuthState(tokens));
        this.logger.info('Signed In Via Social Network');
        this.clearOauthState();
        return tokens;
      }), catchError((error, caught) => {
        this.logger.error('social_login_service error - clear state' + error);
        this.clearOauthState();
        throw error;
      })
    );
  }
}

export enum FMSocialIdentityProvider {
  Cognito = 'COGNITO',
  Google = 'Google',
  Facebook = 'Facebook',
  Apple = 'Apple',
  Amazon = 'LoginWithAmazon'
}

export enum FMSocialRedirectAction {
  KickaroundSignup = 'KickaroundSignup',
  ActivatePendingKickaround = 'ActivatePendingKickaround',
  ConfirmInviteSecure = 'ConfirmInviteSecure',
  RedirectToPaymentCardDetails = 'RedirectToPaymentCardDetails',
  RedirectToKickaroundList = 'RedirectToKickaroundList'
}
