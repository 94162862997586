import { Routes } from '@angular/router';

import {AuthGuard} from './features/user/guards/auth.guard';
import {SecureComponent} from './routes/secure/secure.component';
import {PublicComponent} from './routes/public/public.component';
import {UserDetailsResolver} from './features/user/resolvers/user-details.resolver';
import {ExtractTokensGuard} from './features/user/guards/extract-tokens.guard';


export const routes: Routes = [
  {
    path: '',
    component: PublicComponent,
    canActivate: [
      ExtractTokensGuard
    ],
    loadChildren: () => import('./routes/public/public.module').then(m => m.PublicModule),
  },
  {
    path: 'secure',
    canActivate: [AuthGuard, ExtractTokensGuard],
    component: SecureComponent,
    loadChildren: () => import('./routes/secure/secure.module').then(m => m.SecureModule),
    resolve: {
      userDetails: UserDetailsResolver
    },
  },
  { path: '**', redirectTo: '' }
];
