import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Subject} from 'rxjs';;

@Injectable({
  providedIn: 'root'
})
export class ProgressService {
  public enabled = true;
  public loadingInProgress: boolean;
  public displayMessage: string;
  public loadingSubject: Subject<{message: string, show: boolean}> = new Subject();

  constructor() {
    this.loadingInProgress = false;
    this.displayMessage = '';
   }

  enable() {
    this.enabled = true;
  }

  disable(source?) {
    this.enabled = false;
  }

  showLoadingSpinner(message: string) {
    if (this.enabled) {
      this.loadingInProgress = true;
    }
    this.displayMessage = message;
    this.loadingSubject.next({ message: this.displayMessage, show: this.enabled });
  }

  updateMessage(message) {
    this.loadingSubject.next({ message: message, show: this.enabled });
  }

  getUpdate(): Observable<any> {
    return this.loadingSubject.asObservable();
  }

  hideLoadingSpinner() {
    this.loadingInProgress = false;
    this.loadingSubject.next({ message: '', show: false });
  }

  getLoadingInProgress() {
    return this.loadingInProgress;
  }
}
