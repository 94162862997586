import {
  deserialize,
  deserializeAs,
} from 'cerialize';

import {KickaroundGamePlayer} from '../database/kickaround-game-player.model';
import {KickaroundUserData} from './kickaround-user-data.model';

export class ConfirmDeclineGameInvitationData {
  @deserialize result: string;
  @deserializeAs(KickaroundGamePlayer) recalcPlayerList: KickaroundGamePlayer[];
  @deserializeAs(KickaroundGamePlayer) loggedInGamePlayer: KickaroundGamePlayer;
  @deserializeAs(KickaroundUserData) userData: KickaroundUserData;
}
