import { Injectable } from '@angular/core';
import { Store} from '@ngxs/store';
import {LocalStorageService} from './local-storage.service';
import {UserSelectors} from '../state/user.selectors';
import {NGXLogger} from 'ngx-logger';
@Injectable({
  providedIn: 'root'
})
export class SocialLoginTokenExtractService {
  constructor(
    private store: Store,
    private localStorageService: LocalStorageService,
    private logger: NGXLogger,
    ) {
  }

  extractTokensForPostSocialLoginRedirectRecovery(gofmToken, gameAction, affiliateToken, campaignToken) {
    if (gofmToken) {
      this.logger.info('saving gofmToken');
      this.localStorageService.saveValueForKey('gofmToken', gofmToken);
    }
    if (gameAction) {
      this.logger.info('saving gameAction');
      this.localStorageService.saveValueForKey('action', gameAction);
    }
    if (affiliateToken) {
      this.logger.info('saving affiliateToken');
      this.localStorageService.saveValueForKey('at', affiliateToken);
    }
    if (campaignToken) {
      this.logger.info('saving campaignToken');
      this.localStorageService.saveValueForKey('ct', campaignToken);
    }
  }
}
