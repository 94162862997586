import {deserialize, deserializeAs} from 'cerialize';

import {Player} from '../../../user/models/player.model';

export class KickaroundPlayer {
  @deserialize id: number;
  @deserialize onBehalfOfDisplayName: boolean;
  @deserialize displayName: string;
  @deserialize version?: number;
  @deserialize token: string;
  @deserialize role: string;
  // TODO remove kickaround
  @deserialize kickaround: number;
  @deserialize kickaroundId: number;
  @deserialize playerId: number;
  @deserializeAs(Player) player?: Player;
  @deserialize priority?: number;
  @deserialize prePaid?: boolean;
  @deserialize prePaidRemainingGames?: number;
  @deserialize allowProfileReminder: boolean;
  @deserialize profileComplete: boolean;
  @deserialize isBanned?: boolean;
  @deserialize noYellowCards?: number;
  @deserialize organiserDiscountPercentage: number;
}
