export const GTAG_CODE = 'UA-126258265-1';

// Event Categories
export const EVENT_CATEGORY_USER = 'user';
export const EVENT_CATEGORY_LANDING_PAGE = 'landing-page';
export const EVENT_CATEGORY_LANDING_PAGE_FIND = 'landing-page-find';
export const EVENT_CATEGORY_LANDING_PAGE_ORGANISE = 'landing-page-find';
export const EVENT_CATEGORY_JOIN_PLAYER_MATCHER = 'player-matcher-join';
export const EVENT_CATEGORY_KICKAROUND_CREATE = 'kickaround-create';
export const EVENT_CATEGORY_JOIN_KICKAROUND = 'kickaround-join';
export const EVENT_CATEGORY_KICKAROUND_ORGANISE = 'kickaround-organise';
export const EVENT_CATEGORY_KICKAROUND_PAYMENTS = 'kickaround-payments';
export const EVENT_CATEGORY_USER_PAYMENTS = 'user-payments';
export const EVENT_CATEGORY_RSVP_KICKAROUND_GAME = 'kickaround-game-rsvp';
export const EVENT_CATEGORY_RSVP_KICKAROUND_LIST = 'kickaround-list-rsvp';
export const EVENT_CATEGORY_WHATSAPP_RSVP_KICKAROUND_GAME = 'kickaround-game-whatsapp';

// Landing Page Find Funnel
export const LP_FIND_FUNNEL_NAME = 'landing-page-find-funnel';
export const LP_FIND_FUNNEL_STEP1 = 'step-1-where';
export const LP_FIND_FUNNEL_STEP2 = 'step-2-when';
export const LP_FIND_FUNNEL_STEP3 = 'step-3-get-notified';
export const LP_FIND_FUNNEL_STEP4 = 'step-4-success';
export const LP_FIND_FUNNEL_STAGES = [
  LP_FIND_FUNNEL_STEP1,
  LP_FIND_FUNNEL_STEP2,
  LP_FIND_FUNNEL_STEP3,
  LP_FIND_FUNNEL_STEP4
];

// Landing Page Organise Funnel
export const LP_ORGANISE_FUNNEL_NAME = 'landing-page-organise-funnel';
export const LP_ORGANISE_FUNNEL_STEP1 = 'step-1-what';
export const LP_ORGANISE_FUNNEL_STEP2 = 'step-2-when';
export const LP_ORGANISE_FUNNEL_STEP3 = 'step-3-where';
export const LP_ORGANISE_FUNNEL_STEP4 = 'step-4-cost';
export const LP_ORGANISE_FUNNEL_STEP5 = 'step-5-register';
export const LP_ORGANISE_FUNNEL_STEP6 = 'step-6-success';
export const LP_ORGANISE_FUNNEL_STAGES = [
  LP_ORGANISE_FUNNEL_STEP1,
  LP_ORGANISE_FUNNEL_STEP2,
  LP_ORGANISE_FUNNEL_STEP3,
  LP_ORGANISE_FUNNEL_STEP4,
  LP_ORGANISE_FUNNEL_STEP5,
  LP_ORGANISE_FUNNEL_STEP6
];

// Landing Page Organise Funnel
export const KICKAROUND_CREATE_FUNNEL_NAME = 'kickaround-create-funnel';
export const KICKAROUND_CREATE_FUNNEL_STEP1 = 'step-1-what';
export const KICKAROUND_CREATE_FUNNEL_STEP2 = 'step-2-when';
export const KICKAROUND_CREATE_FUNNEL_STEP3 = 'step-3-where';
export const KICKAROUND_CREATE_FUNNEL_STEP4 = 'step-4-cost';
export const KICKAROUND_CREATE_FUNNEL_STEP5 = 'step-5-success';
export const KICKAROUND_CREATE_STAGES = [
  KICKAROUND_CREATE_FUNNEL_STEP1,
  KICKAROUND_CREATE_FUNNEL_STEP2,
  KICKAROUND_CREATE_FUNNEL_STEP3,
  KICKAROUND_CREATE_FUNNEL_STEP4,
  KICKAROUND_CREATE_FUNNEL_STEP5,
];

// Non Registered Payment Flow
export const KA_PAYMENT_NON_REG_FUNNEL = 'ka-non-registered-payment-flow-funnel';
export const KA_PAYMENT_NON_REG_FUNNEL_STEP1 = 'step-1-pay-online-from-p-ka';
export const KA_PAYMENT_NON_REG_FUNNEL_STEP2 = 'step-1-register';
export const KA_PAYMENT_NON_REG_FUNNEL_STEP3 = 'step-2-confirm';
export const KA_PAYMENT_NON_REG_FUNNEL_STEP4 = 'step-3-login';
export const KA_PAYMENT_NON_REG_FUNNEL_STEP5 = 'step-4-card-details';
export const KA_PAYMENT_NON_REG_FUNNEL_STEP6 = 'step-5-pay';
export const KA_PAYMENT_NON_REG_FUNNEL_STEP7 = 'step-6-success';

// Logged Out Payment Flow
export const KA_PAYMENT_LOGGED_OUT_FUNNEL = 'ka-logged-out-payment-flow-funnel';
export const KA_PAYMENT_LOGGED_OUT_FUNNEL_STEP1 = 'step-1-pay-online-from-p-ka';
export const KA_PAYMENT_LOGGED_OUT_FUNNEL_STEP2 = 'step-2-login';
export const KA_PAYMENT_LOGGED_OUT_FUNNEL_STEP3 = 'step-3-card-details';
export const KA_PAYMENT_LOGGED_OUT_FUNNEL_STEP4 = 'step-4-pay';
export const KA_PAYMENT_LOGGED_OUT_FUNNEL_STEP6 = 'step-5-success';

// Logged In Payment Flow
export const KA_PAYMENT_LOGGED_IN_FUNNEL = 'ka-logged-in-payment-flow-funnel';
export const KA_PAYMENT_LOGGED_IN_FUNNEL_STEP1 = 'step-1-pay-online-from-ka';
export const KA_PAYMENT_LOGGED_IN_FUNNEL_STEP3 = 'step-2-card-details';
export const KA_PAYMENT_LOGGED_IN_FUNNEL_STEP4 = 'step-3-pay';
export const KA_PAYMENT_LOGGED_IN_FUNNEL_STEP6 = 'step-4-success';

// Events for EVENT_CATEGORY_USER
export const USER_NORMAL_EMAIL_LOGIN = 'lp-email-login';
export const USER_NORMAL_FACEBOOK_LOGIN = 'lp-facebook-login';
export const USER_NORMAL_GOOGLE_LOGIN = 'lp-gmail-login';
export const USER_NORMAL_AUTO_TOKEN_LOGIN = 'lp-auto-token-login';
export const USER_NORMAL_EMAIL_REGISTER_INITIAL = 'lp-email-register-initial';
export const USER_NORMAL_EMAIL_REGISTER_CONFIRM = 'lp-email-register-confirm';
export const USER_NORMAL_FACEBOOK_REGISTER  = 'lp-facebook-register';
export const USER_NORMAL_GOOGLE_REGISTER = 'lp-gmail-register';
export const USER_NORMAL_RESET_PASSWORD_INITIAL = 'lp-gmail-reset-password-initial';
export const USER_NORMAL_RESET_PASSWORD_CONFIRM = 'lp-gmail-reset-password-confirm';
export const USER_UPDATE_PROFILE_PIC = 'usr-update-profile-pic';
export const USER_CLEAR_PROFILE_PIC = 'usr-clear-profile-pic';
export const USER_UPDATE_DETAILS = 'usr-update-details';
export const USER_CHANGE_PASSWORD = 'usr-change-password';
export const USER_CLOSE_ACCOUNT = 'usr-close-account';
export const USER_OPEN_FEEDBACK = 'usr-open-feedback';
export const USER_SEND_FEEDBACK = 'usr-send-feedback';

// Events for EVENT_CATEGORY_JOIN_PLAYER_MATCHER
export const PLAYER_MATCHER_JOINED_VIA_EMAIL = 'player-matcher-joined-via-email';
export const PLAYER_MATCHER_JOINED_VIA_FACEBOOK = 'player-matcher-joined-via-facebook';
export const PLAYER_MATCHER_JOINED_VIA_GOOGLE = 'player-matcher-joined-via-google';

// Events for EVENT_CATEGORY_JOIN_KICKAROUND
export const KICKAROUND_JOINED_VIA_EMAIL = 'kickaround-joined-via-email';
export const KICKAROUND_JOINED_VIA_FACEBOOK = 'kickaround-joined-via-facebook';
export const KICKAROUND_JOINED_VIA_GOOGLE = 'kickaround-joined-via-google';

// Events for EVENT_CATEGORY_RSVP_KICKAROUND
export const KA_RVSP_EMAIL_VIEW_PLAYER_LIST = 'ka-rsvp-view-via-email';
export const KA_RVSP_EMAIL_CONFIRM = 'ka-rsvp-confirm-via-email';
export const KA_RVSP_EMAIL_DECLINE = 'ka-rsvp-decline-via-email';
export const KA_RVSP_EMAIL_UNSUBSCRIBE = 'ka-rsvp-unsubscribe-via-email';
export const KA_RVSP_PAY_ONLINE = 'ka-rsvp-pay-online-via-btn';
export const KA_RVSP_YES_RESERVE_SPOT = 'ka-rsvp-yes-reserve-spot-via-btn';
export const KA_RVSP_NO_CANT_MAKE_IT = 'ka-rsvp-no-cant-make-it-via-btn';
export const KA_RVSP_NO_DROP_OUT = 'ka-rsvp-no-drop-out-via-btn';
export const KA_RVSP_NO_WITH_REFUND = 'ka-rsvp-no-with-refund-via-btn';
export const KA_UPDATE_COVID_CONTACT_DETAILS = 'ka-update-covid-contact-details';

// Events for EVENT_CATEGORY_RSVP_KICKAROUND
export const KAL_RVSP_SHOW_PAYMENT = 'kal-show-payment';
export const KAL_RVSP_PAY_ONLINE = 'kal-rsvp-pay-online-via-btn';
export const KAL_RVSP_YES_RESERVE_SPOT = 'kal-rsvp-yes-reserve-spot-via-btn';
export const KAL_RVSP_NO_CANT_MAKE_IT = 'kal-rsvp-no-cant-make-it-via-btn';

// Events for EVENT_CATEGORY_WHATSAPP_RSVP_KICKAROUND
export const KA_WHATSAPP_AUTO_LOGIN_2_CONFIRM = 'ka-whatapp-auto-login-2-confirm';
export const KA_WHATSAPP_AUTO_LOGIN_2_DECLINE = 'ka-whatapp-auto-login-2-decline';
export const KA_WHATSAPP_AUTO_LOGIN_2_VIEW = 'ka-whatapp-auto-login-2-view';
export const KA_WHATSAPP_LOGIN_2_CONFIRM_VIA_EMAIL = 'ka-whatapp-login-2-confirm-via-email';
export const KA_WHATSAPP_LOGIN_2_CONFIRM_VIA_GOOGLE = 'ka-whatapp-login-2-confirm-via-google';
export const KA_WHATSAPP_LOGIN_2_CONFIRM_VIA_FACEBOOK = 'ka-whatapp-login-2-confirm-facebook';
export const KA_WHATSAPP_REGISTER_2_CONFIRM_VIA_EMAIL_INITIAL = 'ka-whatapp-register-2-confirm-via-email';
export const KA_WHATSAPP_REGISTER_2_CONFIRM_VIA_EMAIL_CONFIRM_ACCOUNT = 'ka-whatapp-register-2-confirm-via-email-confirm';
export const KA_WHATSAPP_REGISTER_2_CONFIRM_VIA_FACEBOOK = 'ka-whatapp-register-2-confirm-via-facebook';
export const KA_WHATSAPP_REGISTER_2_CONFIRM_VIA_GOOGLE = 'ka-whatapp-register-2-confirm-via-facebook';

// Events for EVENT_CATEGORY_KICKAROUND_ORGANISE
export const KA_ORGANISE_EDIT_KICKAROUND = 'ka-organiser-edit-kickaround';
export const KA_ORGANISE_PAUSE_KICKAROUND = 'ka-organiser-pause-kickaround';
export const KA_ORGANISE_ENABLE_KICKAROUND = 'ka-organiser-enable-kickaround';
export const KA_ORGANISE_DELETE_KICKAROUND = 'ka-organiser-delete-kickaround';
export const KA_ORGANISE_EDIT_GAME = 'ka-organiser-edit-game';
export const KA_ORGANISE_TRACK_N_TRACE_EMAIL = 'ka-organiser-send-game-participation-trackntrack-email';
export const KA_ORGANISE_MANUAL_SEND_INVITES = 'ka-organiser-manual-send-invites';
export const KA_ORGANISE_CONFIRM_GAME = 'ka-organiser-confirm-game';
export const KA_ORGANISE_CREATE_NEXT_GAME = 'ka-organiser-create-next-game';
export const KA_ORGANISE_CANCEL_GAME = 'ka-organiser-cancel-game';
export const KA_ORGANISE_SEND_WHATSAPP_PLAYER_LIST = 'ka-organiser-send-whatsapp-player-list';
export const KA_ORGANISE_SEND_MESSAGE_TO_GROUP = 'ka-organiser-send-message-to-group';
export const KA_ORGANISE_SEND_MESSAGE_TO_PLAYER = 'ka-organiser-send-message-to-player';
export const KA_ORGANISE_REMOVE_PLAYER_FROM_GAME = 'ka-organiser-remove-player-from-game';
export const KA_ORGANISE_OPEN_SELECT_TEAMS = 'ka-organiser-open-team-select';
export const KA_ORGANISE_SAVED_TEAM_SELECT = 'ka-organiser-saved-team-select';
export const KA_ORGANISE_SAVE_GAME_STATS = 'ka-organiser-save-game-stats';

// Events for EVENT_CATEGORY_KICKAROUND_PAYMENTS
export const KA_PLAYER_FULL_PAYMENT = 'ka-player-full-payment';

// Events for EVENT_CATEGORY_USER_PAYMENTS
export const USER_PAYMENTS_SEE_PLAYER_PAYMENT_DETAILS = 'user-payments-see-player-payment-details';
export const USER_PAYMENTS_SEE_GAME_PAYMENT_BREAKDOWN = 'user-payments-see-game-payment-breakdown';
export const USER_PAYMENTS_ENABLE_AUTO_PAYOUTS = 'user-payments-enable-auto-payouts';
export const USER_PAYMENTS_DISABLE_AUTO_PAYOUTS = 'user-payments-disable-auto-payouts';
export const USER_PAYMENTS_UPDATE_BANK_DETAILS_INITIAL = 'user-payments-update-bank-details-initial';
export const USER_PAYMENTS_UPDATE_BANK_DETAILS_SUCCESS = 'user-payments-update-bank-details-success';
export const USER_PAYMENTS_WITHDRAW_MONEY_INITIAL = 'user-payments-update-bank-details-initial';
export const USER_PAYMENTS_WITHDRAW_MONEY_SUCCESS = 'user-payments-update-bank-details-success';
export const USER_PAYMENTS_OPEN_CARDS = 'user-payments-open-cards';

// Events for EVENT_CATEGORY_KICKAROUND_CREATE
export const KICKAROUND_CREATE_STEP_1 = 'ka-create-1-what';
export const KICKAROUND_CREATE_STEP_2 = 'ka-create-step-2-when';
export const KICKAROUND_CREATE_STEP_3 = 'ka-create-step-3-where';
export const KICKAROUND_CREATE_STEP_4 = 'ka-create-step-4-cost';
export const KICKAROUND_CREATE_STEP_5 = 'ka-create-step-5-success';
export const KICKAROUND_CREATE_STEPS = [
  KICKAROUND_CREATE_STEP_1,
  KICKAROUND_CREATE_STEP_2,
  KICKAROUND_CREATE_STEP_3,
  KICKAROUND_CREATE_STEP_4,
  KICKAROUND_CREATE_STEP_5,
];


// Events for EVENT_CATEGORY_LANDING_PAGE_ORGANISE
export const LANDING_PAGE_SPLASH_ORGANISE_CALL_TO_ACTION = 'lp-splash-organise-call-2-action';
export const LANDING_PAGE_ORGANISE_STEP_1 = 'lp-organise-step-1-what';
export const LANDING_PAGE_ORGANISE_STEP_2 = 'lp-organise-step-2-when';
export const LANDING_PAGE_ORGANISE_STEP_3 = 'lp-organise-step-3-where';
export const LANDING_PAGE_ORGANISE_STEP_4 = 'lp-organise-step-4-cost';
export const LANDING_PAGE_ORGANISE_STEP_5 = 'lp-organise-step-5-register';
export const LANDING_PAGE_ORGANISE_STEP_6 = 'lp-organise-step-6-success';
export const LANDING_PAGE_ORGANISE_SUCCESS_VIA_EMAIL = 'lp-organise-success-via-email';
export const LANDING_PAGE_ORGANISE_SUCCESS_VIA_FACEBOOK = 'lp-organise-success-via-facebook';
export const LANDING_PAGE_ORGANISE_SUCCESS_VIA_GOOGLE = 'lp-organise-success-via-google';
export const LANDING_PAGE_ORGANISE_STEPS = [
  LANDING_PAGE_ORGANISE_STEP_1,
  LANDING_PAGE_ORGANISE_STEP_2,
  LANDING_PAGE_ORGANISE_STEP_3,
  LANDING_PAGE_ORGANISE_STEP_4,
  LANDING_PAGE_ORGANISE_STEP_5,
  LANDING_PAGE_ORGANISE_STEP_6,
];

// Events for EVENT_CATEGORY_LANDING_PAGE_FIND
export const LANDING_PAGE_SPLASH_FIND_CALL_TO_ACTION = 'lp-splash-find-call-2-action';
export const LANDING_PAGE_NEED_MORE_FOOTBALL_CALL_TO_ACTION = 'lp-need-more-football-call-2-action';
export const LANDING_PAGE_FIND_STEP_1 = 'lp-find-step-1-where';
export const LANDING_PAGE_FIND_STEP_2 = 'lp-find-step-2-when';
export const LANDING_PAGE_FIND_STEP_3 = 'lp-find-step-3-get-notified';
export const LANDING_PAGE_FIND_STEP_4 = 'lp-find-step-4-cost';
export const LANDING_PAGE_FIND_SUCCESS_VIA_EMAIL = 'lp-find-success-via-email';
export const LANDING_PAGE_FIND_SUCCESS_VIA_FACEBOOK = 'lp-find-success-via-facebook';
export const LANDING_PAGE_FIND_SUCCESS_VIA_GOOGLE = 'lp-find-success-via-google';
export const LANDING_PAGE_FIND_EDIT_AVAILABILITY = 'lp-find-add-availability';
export const LANDING_PAGE_FIND_ADD_LOCATION = 'lp-find-add-location';
export const LANDING_PAGE_FIND_REMOVE_LOCATION = 'lp-find-remove-location';

export const LANDING_PAGE_FIND_STEPS = [
  LANDING_PAGE_FIND_STEP_1,
  LANDING_PAGE_FIND_STEP_2,
  LANDING_PAGE_FIND_STEP_3,
  LANDING_PAGE_FIND_STEP_4,
];




