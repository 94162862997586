import {HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {environment} from '../../../../environments/environment';
import {LocalStorageService} from './local-storage.service';
import {Store} from '@ngxs/store';
import {NGXLogger} from 'ngx-logger';

export enum CognitoAction {
  ACTION_INITIATE_AUTH = 'AWSCognitoIdentityProviderService.InitiateAuth',
  ACTION_RESEND_CODE = 'AWSCognitoIdentityProviderService.ResendConfirmationCode',
  ACTION_SIGN_UP = 'AWSCognitoIdentityProviderService.SignUp',
  ACTION_CONFIRM_SIGN_UP = 'AWSCognitoIdentityProviderService.ConfirmSignUp',
  ACTION_CONFIRM_DEVICE = 'AWSCognitoIdentityProviderService.ConfirmDevice',
  ACTION_FORGOT_PSWD = 'AWSCognitoIdentityProviderService.ForgotPassword',
  ACTION_CONFIRM_FORGOT_PSWD = 'AWSCognitoIdentityProviderService.ConfirmForgotPassword',
  ACTION_GET_USER = 'AWSCognitoIdentityProviderService.GetUser',
  ACTION_UPD_USR_ATTR = 'AWSCognitoIdentityProviderService.UpdateUserAttributes',
  ACTION_DELETE_USER = 'AWSCognitoIdentityProviderService.DeleteUser',
  ACTION_CHANGE_PASSWORD = 'AWSCognitoIdentityProviderService.ChangePassword',
  ACTION_RESPOND_TO_AUTH_CHALLENGE = 'AWSCognitoIdentityProviderService.RespondToAuthChallenge'
}

export enum CognitoAuthFlow {
  AUTH_PSWD = 'USER_PASSWORD_AUTH',
  AUTH_SRP = 'USER_SRP_AUTH',
  PASSWORD_VERIFIER = 'PASSWORD_VERIFIER',
  DEVICE_AUTH_SRP = 'DEVICE_SRP_AUTH',
  AUTH_REFRESH = 'REFRESH_TOKEN_AUTH'
}

@Injectable({
  providedIn: 'root',
})
export class CognitoService {
  BASE_URL = environment.authBaseUrl;
  CLIENT_ID = environment.cognitoClientId;
  HEADER_CONTENT_TYPE = 'application/x-amz-json-1.1';
  HEADER_USER_AGENT = 'aws-amplify/0.1.x js';

  public accessToken: string;
  public idToken: string;
  public refreshToken: string;
  public userAttributes: any;

  constructor(
    public localStorageService: LocalStorageService,
    public store: Store,
    public logger: NGXLogger,
  ) {
    if (this.localStorageService.hasRefreshToken()) {
      this.logger.info('Found a refetch token locally for use if auto refetch token confirmAndLogin called');
      this.refreshToken = this.localStorageService.getRefreshToken();
    } else {
      this.logger.info('No refetch token found');
    }
  }

  public userHasFeature(featureName) {
    return this.userAttributes['features'].find(currentFeature => currentFeature.featureName === featureName) !== undefined;
  }

  public getCommonHeaders(cognitoAction: CognitoAction) {
    this.logger.debug('Header resolver : ' + cognitoAction.valueOf());
    return new HttpHeaders({
      'Content-Type': this.HEADER_CONTENT_TYPE,
      'X-Amz-User-Agent': this.HEADER_USER_AGENT,
      'X-Amz-Target': cognitoAction.valueOf()
    });
  }
}
