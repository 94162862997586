import {environment} from "../../../../../environments/environment";

export const ORGANISATIONS = {
  'football-coop': {
    bannerUrl: 'https://cdn.footballmatcher.io/images/group-images/footy-coop-banner.jpeg',
    badgeUrl: 'https://cdn.footballmatcher.io/images/group-images/footy-coop.png',
  }
};

export const defaultLocation = {
    id: environment.defaultLocationId,
    name: "WEMBLEY STADIUM",
    address: "WEMBLEY STADIUM, HA9 0WS",
    posttown: "WEMBLEY",
    postcode: "HA9 0WS",
    email: "No Data",
    website: "http://www.wembleystadium.com/",
    phone: "0844 980 8001",
    longitude: "-0.279666",
    latitude: "51.556692",
    halllength: "0.00",
    hallwidth: "0.00",
    hallarea: "0.00",
    hallcount: 0,
    opendataid: "30006416",
    grasspitchcount: 1,
    artificialgrasspitchcount: 0
}

export const CURRENCIES_SUPPORTED = [
  {label: 'GBP', value: 'GBP', symbol: '£'},
  {label: 'EUR', value: 'EUR', symbol: '€'},
];
export const DEFAULT_CURRENCY = CURRENCIES_SUPPORTED[0];


export const PITCH_PLAYERS_3_A_SIDE_OPTION = {label: '3 a side', value: 3}
export const PITCH_PLAYERS_4_A_SIDE_OPTION = {label: '4 a side', value: 4}
export const PITCH_PLAYERS_5_A_SIDE_OPTION = {label: '5 a side', value: 5}
export const PITCH_PLAYERS_6_A_SIDE_OPTION = {label: '6 a side', value: 6}
export const PITCH_PLAYERS_7_A_SIDE_OPTION = {label: '7 a side', value: 7}
export const PITCH_PLAYERS_8_A_SIDE_OPTION = {label: '8 a side', value: 8}

export const PITCH_PLAYERS_9_A_SIDE_OPTION = {label: '9 a side', value: 9}

export const PITCH_PLAYERS_10_A_SIDE_OPTION = {label: '10 a side', value: 10}

export const PITCH_PLAYERS_11_A_SIDE_OPTION = {label: '11 a side', value: 11}

export const PITCH_PLAYERS_PER_SIDE_OPTIONS = [
  PITCH_PLAYERS_3_A_SIDE_OPTION,
  PITCH_PLAYERS_4_A_SIDE_OPTION,
  PITCH_PLAYERS_5_A_SIDE_OPTION,
  PITCH_PLAYERS_6_A_SIDE_OPTION,
  PITCH_PLAYERS_7_A_SIDE_OPTION,
  PITCH_PLAYERS_8_A_SIDE_OPTION,
  PITCH_PLAYERS_9_A_SIDE_OPTION,
  PITCH_PLAYERS_10_A_SIDE_OPTION,
  PITCH_PLAYERS_11_A_SIDE_OPTION
];

export const PITCH_TYPES = [
  {label: 'Astro (2G)', value: 'a2g'},
  {label: 'Astro (3G)', value: 'a3g'},
  {label: 'Astro (4G)', value: 'a4g'},
  {label: 'Concrete', value: 'con'},
  {label: 'Grass', value: 'grs'},
  {label: 'Indoor', value: 'ind'},
];

export const GENDER_TYPES = [
  {label: 'Mixed', value: 'mi'},
  {label: 'Women only', value: 'fm'},
  {label: 'Men only', value: 'ma'},
];

export const AGE_RANGES = [
  {label: 'Under 18s', value: {minValue: 16, maxValue: 18 }},
  {label: '18 - 34', value: {minValue: 18, maxValue: 34}},
  {label: '35 - 54', value: {minValue: 35, maxValue: 54}},
  {label: 'Over 55s', value: {minValue: 55, maxValue: 100}},
];


export const SKILL_LEVEL_CASUAL = {label: 'Casual', value: 'cas'};
export const SKILL_LEVEL_COMPETITIVE = {label: 'Competitive', value: 'com'};
export const SKILL_LEVELS = [
  SKILL_LEVEL_CASUAL,
  SKILL_LEVEL_COMPETITIVE,
];
export const DAYS_OF_WEEK = [
  {label: 'Mondays', value: '0'},
  {label: 'Tuesdays', value: '1'},
  {label: 'Wednesdays', value: '2'},
  {label: 'Thursdays', value: '3'},
  {label: 'Fridays', value: '4'},
  {label: 'Saturdays', value: '5'},
  {label: 'Sundays', value: '6'},
];

export const DAYS_BEFORE = [
  {label: '1 Day', value: '1'},
  {label: '2 Days', value: '2'},
  {label: '3 Days', value: '3'},
  {label: '4 Days', value: '4'},
  {label: '5 Days', value: '5'},
  {label: '6 Days', value: '6'},
];

export const NO_REUND_DAYS_BEFORE = [
  {label: 'Disabled', value: 0},
  {label: '1 Day', value: 24},
  {label: '2 Days', value: 48},
  {label: '3 Days', value: 72},
  {label: '4 Days', value: 96},
  {label: '5 Days', value: 120},
  {label: '6 Days', value: 144},
  {label: '7 Days', value: 168},
];

// How often does the group play?
export const FREQUENCY_ADHOC = {label: 'Just once', value: 'ADHOC'};
export const FREQUENCY_WEEKLY = {label: 'Weekly', value: 'WEEKLY'};
export const FREQUENCY_FORTNIGHTLY = {label: 'Fortnightly', value: 'FORTNIGHTLY'};
export const FREQUENCY_MONTHLY = {label: 'Monthly', value: 'MONTHLY'};
export const FREQUENCY = [
  FREQUENCY_ADHOC,
  FREQUENCY_WEEKLY,
  FREQUENCY_FORTNIGHTLY,
  FREQUENCY_MONTHLY
];

export const GROUP_CHAT_NEW_WHATSAPP = {label: 'Create New Group', value: 'new_whatsapp'};
export const GROUP_CHAT_EXISTING_WHATSAPP = {label: 'Use Existing Group', value: 'existing_whatsapp'};
export const GROUP_CHAT_NO_WHATSAPP = {label: 'None', value: 'none'};
export const GROUP_CHAT_OPTIONS = [
  GROUP_CHAT_EXISTING_WHATSAPP,
  GROUP_CHAT_NO_WHATSAPP,
];


// Pricing modes
export const PRICING_MODE_FIXED = {label: 'Fixed player price', value: 'f'};
export const PRICING_MODE_VARIABLE = {label: 'Variable player price', value: 'd'};
export const PRICING_MODE_ALL = [
  PRICING_MODE_FIXED,
  PRICING_MODE_VARIABLE,
];

// Payment method options
export const PAYMENT_METHOD_FREE_GAME = {label: 'No charge, its free', value: 'f'};
export const PAYMENT_METHOD_CASH_ONLY = {label: 'Cash at pitch only', value: 'c'};
export const PAYMENT_METHOD_ANYTIME = {label: 'Pay anytime (cash + online)', value: 'a'};
export const PAYMENT_METHOD_BEFORE = {label: 'Pay before play (online only)', value: 'p'};

export const PAYMENT_METHODS_ALL = [
  PAYMENT_METHOD_FREE_GAME,
  // PAYMENT_METHOD_CASH_ONLY,
  PAYMENT_METHOD_ANYTIME,
  PAYMENT_METHOD_BEFORE,
];


export const WHATS_APP_GROUP_MAX_NAME_CHARS = 25;
export const PAY_2_PLAY_EXPIRY_MINS = 10;

export const PRIORITY_PAY_2_PLAY_EXPIRY_HOURS = 24;

export const WAITING_LIST_LEAD_TIME = 120;
export const OFF_WAITING_LIST_PAY_2_PLAY_EXPIRY_MINS = 15;

export const AUTO_CONFIRM_HOURS = 24;

// CASH COUPON CODE (Zero fees)
export const CASH_COUPON = 'FM4EVAFIXEDFREEP544';

