import {FMLocation} from '../../../location/models/location.model';
import {Kickaround} from '../../models/database/kickaround.model';
import {AcceptDeclineGameResponse, KickaroundStatusActionChoices} from '../../kickarounds.enums';
import {KickaroundGame} from '../../models/database/kickaround-game.model';
import {KickaroundGamePlayer} from '../../models/database/kickaround-game-player.model';

export class UpdatePlayerDisplayName {
  static readonly type = '[Secure KA] UpdatePlayerDisplayName';
  constructor(public kickaroundId: number, public kickaroundPlayerId: number, public displayName: string, public onBehalfOfDisplayName: boolean) {}
}


export class GetSuggestedKickarounds {
  static readonly type = '[Secure KA] GetSuggestedKickarounds';
  constructor(public refreshData = false, public showPopUp = false) {}
}

export class GetKickaroundUserData {
  static readonly type = '[Secure KA] GetKickaroundUserData';
  constructor(public refreshData = false, public showLoading = true) {}
}

export class PostAcceptDeclineGameInvitation {
  static readonly type = '[Secure KA] PostAcceptDeclineGameInvitation';
  constructor(public kickaroundId: number, public gameId: number, public acceptDeclineGameResponse: AcceptDeclineGameResponse, public gofm?: string, public viaWhatsApp: boolean = false) {
  }
}
export class UpdateKickaroundFromWSEvent {
  static readonly type = '[Secure KA WS Event] UpdateSecureKickaround';
  constructor(public kickaround: Kickaround) {}
}

export class UpdateKickaroundGameFromWSEvent {
  static readonly type = '[Secure KA WS Event] UpdateSecureKickaroundGame';
  constructor(public kickaroundGame: KickaroundGame) {}
}

export class UpdateKickaroundGamePlayerFromWSEvent {
  static readonly type = '[Secure KA WS Event] UpdateSecureKickaroundGamePlayer';
  constructor(public kickaroundGamePlayer: KickaroundGamePlayer) {}
}

export class UpdateKickaroundGamePlayersFromWSEvent {
  static readonly type = '[Secure KA WS Event] UpdateSecureKickaroundGamePlayers';
  constructor(public kickaroundGamePlayers: KickaroundGamePlayer[], public gameId: number) {}
}

export class RefreshRealtimeKickaroundSubsriptions {
  static readonly type = '[Secure KA Detail] RefreshRealtimeKickaroundSubsriptions';
  constructor() {}
}

export class CreateLocation {
  static readonly type = '[New KA Page] CreateLocation';
  constructor(public newLocation: FMLocation) {}
}

export class GetKickaround {
  static readonly type = '[KA Secure] GetKickaround';
  constructor(public kickaroundId: number, public gofmToken: string, public refetch = false) {}
}

export class GetKickaroundPlayers {
  static readonly type = '[KA Secure] GetKickaroundPlayers';
  constructor(public kickaroundId: number) {}
}

export class GetKickaroundGame {
  static readonly type = '[KA Secure] GetKickaroundGame';
  constructor(public kickaroundId: number, public gameId: number, public refetch = false) {}
}

export class GetKickaroundGamePlayers {
  static readonly type = '[KA Secure] GetKickaroundGamePlayers';
  constructor(public kickaroundId: number, public gameId: number, public refetch = false) {}
}

export class CreateKickaround {
  static readonly type = '[KA Secure] CreateKickaround';
  constructor(public kickaround: Kickaround, public nextGameDate?: Date) {}
}

export class ActivatePendingKickaround {
  static readonly type = '[KA Secure] ActivatePendingKickaround';
  constructor(public kaId: string) {}
}


export class UpdateKickaround {
  static readonly type = '[KA Secure] UpdateKickaround';
  constructor(public kickaround: Kickaround, public applyEditToGame: boolean) {}
}

export class DeleteKickaround {
  static readonly type = '[KA Secure] DeleteKickaround';
  constructor(public kickaroundId: number) {}
}


export class CreateNextGame {
  static readonly type = '[KA Secure] CreateNextGame';
  constructor(public kickaroundId: number, public nextGameDate: Date) {
  }
}

export class SendGamePlayerList {
  static readonly type = '[KA Secure] SendGamePlayerList';
  constructor(public gameId: number) {
  }
}

export class ConfirmGame {
  static readonly type = '[KA Secure] ConfirmGame';
  constructor(public kickaroundId: number, public gameId: number) {
  }
}

export class CancelGame {
  static readonly type = '[KA Secure] CancelGame';
  constructor(public kickaroundId: number, public gameId: number, public reason?: String) {
  }
}

export class SendInvites {
  static readonly type = '[KA Secure] SendInvites';
  constructor(public kickaroundId: number, public gameId: number) {
  }
}

export class SendGameParticipationTrackNTraceEmail {
  static readonly type = '[KA Secure] SendGameParticipationTrackNTraceEmail';
  constructor(public kickaroundId: number, public gameId: number, public optionalEmailAddress?: string) {
  }
}

export class SendMessage {
  static readonly type = '[KA Secure] SendMessage';
  constructor(public kickaroundId: number, public message: string, public toJustConfirmed: boolean) {
  }
}

export class ResetSecureKickaroundState {
  static readonly type = '[KA Secure] ResetSecureKickaroundState';
  constructor() {
  }
}

export class UpdateKickaroundStatus {
  static readonly type = '[KA Secure] ChangeKickaroundStatus';
  constructor(public kickaroundId: number, public status: KickaroundStatusActionChoices, public reason: string, public startDateTime: string) {
  }
}

export class ResetJoinKickaroundPage {
  static readonly type = '[KA Secure] ResetJoinKickaroundPage';
  constructor() {}
}

export class JoinKickaround {
  static readonly type = '[KA Secure] JoinKickaround';
  constructor(
    public kickaround: Kickaround,
    public latestGame: KickaroundGame,
    public token: string,
    public name: string,
    public email: string,
    public affiliateToken: string,
    public campaignToken: string,
    ) {}
}

export class JoinKickaroundSecure {
  static readonly type = '[KA Secure] JoinKickaroundSecure';
  constructor(
    public kickaroundId,
    public token: string
    ) {}
}

export class KASUpdatePlayer {
  static readonly type = '[KA Secure] UpdatePlayer';
  constructor(public playerId: number, public fullName: string) {
  }
}

export class SetSuggestedKickaroundTab {
  static readonly type = '[KAS Secure] SetSuggestedKickaroundTab';
  constructor(public isTabOpen: boolean) {}
}

export class ResetSuggestedKickaroundDataFlags {
  static readonly type = '[KAS Secure] ResetSuggestedKickaroundDataFlags';
  constructor() {}
}

export class UnsubscribeFromKickaround {
  static readonly type = '[KAS Secure] UnsubscribeFromKickaround';
  constructor(public kickaroundId: number) {}
}
