import {autoserialize, deserialize} from 'cerialize';

export class FMLocation {
  // Readonly
  @deserialize id?: number;

  // Read and write
  @autoserialize name: string;
  @autoserialize address1: string;
  @autoserialize address2: string;
  @autoserialize townCity: string;
  @autoserialize postcode: string;
  @autoserialize longitude?: number | string;
  @autoserialize latitude?: number | string;

  /* Auto generated constructor from IDE */
  constructor(id: number, name: string, address1: string, address2: string, townCity: string, postcode: string, longitude: number, latitude: number) {
    this.id = id;
    this.name = name;
    this.address1 = address1;
    this.address2 = address2;
    this.townCity = townCity;
    this.postcode = postcode;
    this.longitude = longitude;
    this.latitude = latitude;
  }
}
