<!--<div class="fm-logo-div" *ngIf="overlayFMLogo">-->
<!--  <div style="text-align: right">-->
<!--    <a class="ml-auto" routerLink="/secure/kickarounds/list">-->
<!--      <img class="navbar-brand top-menu-logo" src="../../../../..{{sportConfig.logoSrc}}">-->
<!--    </a>-->
<!--  </div>-->
<!--</div>-->
<!--<div class="org-logo-div" *ngIf="orgLogoUrl">-->
<!--  <div style="text-align: right">-->
<!--    <a class="mr-auto" routerLink="/">-->
<!--      <img class="navbar-brand top-menu-logo" src="{{orgLogoUrl}}" style="margin-top: -10px">-->
<!--    </a>-->
<!--  </div>-->
<!--</div>-->
<fm-top-menu [isTransparent]="true" *ngIf="overlayTopMenu"></fm-top-menu>
<div class="top-banner " [ngClass]="{ 'top-banner-offset': overlayTopMenu, 'so-game-banner': !bannerOverrideUrl}"
     [ngStyle]="bannerOverrideUrl && {'background-image': 'url(' + bannerOverrideUrl + ')'}">
  <div class="game-title-row">
    <div class="top-banner-subtitle-above" *ngIf="subtitleAbove">{{subtitleAbove}}</div>
    <div class="top-banner-title">{{title}}</div>
    <div class="top-banner-subtitle-below" *ngIf="subtitleBelow && kickaround && game">
        <span *ngIf="kickaround.latestGameId === game.id" [ngClass]="{'game-cancelled': game.status=='CANCELLED'}" >
          Next Game: {{game.start | date :'dd/M/yy hh:mm a' }}
        </span>
        <span *ngIf="!(kickaround.latestGameId === game.id)" [ngClass]="{'game-cancelled': game.status=='CANCELLED'}" >
          Game: {{game.start | date :'dd/M/yy hh:mm a' }}
        </span>
        <span *ngIf="game.status=='PENDING'"> ( Pending Invites )</span>
        <span *ngIf="game.status=='CANCELLED'"> ( Cancelled )</span>
        <span *ngIf="game.status=='CONFIRMED'"> ( Confirmed )</span>
    </div>
    <div class="top-banner-subtitle-below" *ngIf="subtitleBelow && !kickaround">{{subtitleBelow}}</div>
  </div>
</div>
<div class="row badge-row" style="" fxHide.lt-lg="" *ngIf="badgeUrl">
  <div class="col-2"></div>
  <div class="col-2">
    <div>
      <img class="badge-logo" [src]="badgeUrl"/>
    </div>
  </div>
</div>
<div class="row badge-row" fxHide.gt-md="" *ngIf="badgeUrl">
  <div class="col-12" style="text-align: center">
    <div>
      <img class="badge-logo" [src]="badgeUrl"/>
    </div>
  </div>
</div>
