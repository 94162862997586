import {Component, Input, OnInit} from '@angular/core';
import {FMSportsDetectorService} from '../../../../shared/services/fm-sports-detector.service';
import {SportConfig} from '../../../../../environments/models/sport.model';
import {Kickaround} from "../../../kickarounds/models/database/kickaround.model";
import {KickaroundGame} from "../../../kickarounds/models/database/kickaround-game.model";

@Component({
  selector: 'fm-top-banner',
  templateUrl: './top-banner.component.html',
  styleUrls: ['./top-banner.component.scss']
})
export class TopBannerComponent implements OnInit {
  @Input()
  title;

  @Input()
  subtitleAbove;

  @Input()
  subtitleBelow;

  @Input()
  bannerOverrideUrl?: string;

  @Input()
  badgeUrl?: string;

  @Input()
  overlayTopMenu = false;

  @Input()
  overlayFMLogo = false;

  @Input()
  orgLogoUrl?: string;

  @Input()
  kickaround?: Kickaround;

  @Input()
  game?: KickaroundGame;

  // config
  sportConfig: SportConfig;

  constructor(public sportsDetectorService: FMSportsDetectorService) {
    this.sportConfig = sportsDetectorService.getSportConfig();
  }

  ngOnInit() {

  }

}
