export const MOCK_USER_DATA = {
  "userId": 2,
  "userType": "Google",
  "user": {
    "id": 2,
    "fullName": "Philip Day",
    "email": "philipmatthewday@gmail.com",
    "profilePicHiRes": "https://cdn.footballmatcher.io/prod/images/user/2/4VNBH_fm_profile_image_low_res",
    "profilePicLowRes": "https://cdn.footballmatcher.io/prod/images/user/2/4VNBH_fm_profile_image_low_res"
  },
  "playerId": 2,
  "player": {
    "id": 2,
    "version": "0.000029",
    "fullName": "Phil Day",
    "profilePicLowRes": "https://cdn.footballmatcher.io/prod/images/user/2/4VNBH_fm_profile_image_low_res",
    "profilePicHiRes": "https://cdn.footballmatcher.io/prod/images/user/2/4VNBH_fm_profile_image_low_res",
    "status": "a",
    "userId": 2,
    "mobile": "07506788205",
    "optInFMTrackNTrace": true
  },
  "features": [{
    "id": 1,
    "featureName": "PAY_ONLINE_ONLY_ANYTIME",
    "insertedOn": "2019-10-09T11:33:26.557674+01:00"
  }, {"id": 2, "featureName": "PAY_ONLINE_ONLY_ANYTIME", "insertedOn": "2019-10-09T12:04:21.063633+01:00"}, {
    "id": 3,
    "featureName": "PAY_ONLINE_ONLY_TO_CONFIRM",
    "insertedOn": "2019-10-09T12:04:24.167151+01:00"
  }, {"id": 4, "featureName": "PAY_ONLINE_CASH_ANYTIME", "insertedOn": "2019-10-09T12:07:21.202035+01:00"}, {
    "id": 5,
    "featureName": "PAY_ONLINE_CASH_ANYTIME",
    "insertedOn": "2019-10-09T12:07:24.069243+01:00"
  }, {"id": 6, "featureName": "PAY_ONLINE_CASH_ANYTIME", "insertedOn": "2020-01-29T20:43:35.887531Z"}, {
    "id": 7,
    "featureName": "PAY_ONLINE_ONLY_ANYTIME",
    "insertedOn": "2019-10-09T12:04:49.307768+01:00"
  }, {"id": 8, "featureName": "CREATE_KICKAROUND", "insertedOn": "2019-07-13T22:44:07.629660+01:00"}, {
    "id": 9,
    "featureName": "CREATE_KICKAROUND",
    "insertedOn": "2019-10-09T11:34:03.783907+01:00"
  }, {
    "id": 10,
    "featureName": "PAY_ONLINE_ONLY_TO_CONFIRM",
    "insertedOn": "2019-10-09T11:34:01.016439+01:00"
  }, {"id": 11, "featureName": "PAY_ONLINE_ONLY_TO_CONFIRM", "insertedOn": "2020-01-29T20:43:35.887531Z"}, {
    "id": 12,
    "featureName": "CREATE_KICKAROUND",
    "insertedOn": "2019-10-09T12:04:54.992398+01:00"
  }, {"id": 13, "featureName": "PAY_ONLINE_ONLY_ANYTIME", "insertedOn": "2020-01-29T20:43:35.887531Z"}, {
    "id": 14,
    "featureName": "CREATE_KICKAROUND",
    "insertedOn": "2019-10-09T12:04:27.131666+01:00"
  }, {
    "id": 15,
    "featureName": "PAY_ONLINE_ONLY_TO_CONFIRM",
    "insertedOn": "2019-10-09T12:04:52.517760+01:00"
  }, {"id": 16, "featureName": "PAY_ONLINE_CASH_ANYTIME", "insertedOn": "2019-10-09T12:07:17.621854+01:00"}]
};
