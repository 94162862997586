
import {Observable} from 'rxjs';
import {BehaviorSubject} from 'rxjs';
import {throwError} from 'rxjs/internal/observable/throwError';
import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError, filter, take, switchMap, finalize} from 'rxjs/operators';
import {LoginService} from '../services/login.service';
import {CognitoService} from '../services/cognito.service';
import {NGXLogger} from 'ngx-logger';
import {VERSION} from '../../../../environments/buildversion';

@Injectable()
export class HttpCognitoTokenInterceptor implements HttpInterceptor {

    isRefreshingToken = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    HTTP_ERROR_UNAUTHENTICATED_401 = 401;
    HTTP_ERROR_UNAUTHENTICATED_403 = 403;
    HTTP_ERROR_UNAUTHENTICATED_500 = 500;

    constructor(
      private cognitoService: CognitoService,
      private authService: LoginService,
      private logger: NGXLogger,
    ) {}

    validSecureUrl(url): boolean {
      return url.indexOf('cognito-idp') === -1 && url.indexOf('/secure/') > -1;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
      this.logger.info('*INTERCEPT* cognito user refetch handler');
      if (this.validSecureUrl(req.url)) {
        req = this.addAuthHeader(req);
      } else {
        req = this.addPublicHeader(req);
      }
      return next.handle(req)
          .pipe(catchError(error => {
              this.logger.debug('*CatchERROR* cognito user refetch handler');
              if (error instanceof HttpErrorResponse) {
                  switch ((<HttpErrorResponse>error).status) {
                      case this.HTTP_ERROR_UNAUTHENTICATED_401:
                          return this.handle401Error(req, next);
                      // case this.HTTP_ERROR_UNAUTHENTICATED_403:
                      //     return this.handle401Error(req, next);
                      default:
                        return throwError(this.handleNonRetryErrors(error));
                  }
              } else {
                throwError(error);
              }
          }));
    }

    handleNonRetryErrors(currentHttpError) {
      if (currentHttpError.status !== 0 && currentHttpError.status === this.HTTP_ERROR_UNAUTHENTICATED_500) {
        this.logger.debug('An error was detected (not eligible for http/cognito retry) status code : ' + currentHttpError.status);
        const error = Error('We had a problem processing your request, please retry later');
        error['error'] = currentHttpError.error;
        error['status'] = currentHttpError.status;
        error['statusText'] = currentHttpError.statusText;
        error['message'] = currentHttpError.message;
        if ( error['error']['message']) {
          error['error']['message'] = 'We had a problem processing your request, please retry later';
        }
        error['url'] = currentHttpError.url;
        // throw error;
        return error;
      }

      if (currentHttpError.status !== 0 && currentHttpError.status !== this.HTTP_ERROR_UNAUTHENTICATED_401 && currentHttpError.status !== this. HTTP_ERROR_UNAUTHENTICATED_403 ) {
        this.logger.debug('An error was detected (not eligible for http/cognito retry) status code : ' + currentHttpError.status);
        const error = Error('We had a problem processing your request, please retry later');
        error['error'] = currentHttpError.error;
        error['status'] = currentHttpError.status;
        error['statusText'] = currentHttpError.statusText;
        error['message'] = currentHttpError.message;

        if (currentHttpError && currentHttpError.error.result) {
          error['error']['message'] = currentHttpError.error.result;
        }

        if (currentHttpError && !error['error']['message']) {
          error['error']['message'] = currentHttpError.statusText ;
        }

        error['url'] = currentHttpError.url;
        // throw error;
        return error;
      }
      return currentHttpError;
    }

    handle401Error(req: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;
            this.tokenSubject.next(null);

            return this.authService.refreshTokenLogin().pipe(
                switchMap((newToken: string) => {
                    if (this.cognitoService.idToken) {
                        this.tokenSubject.next(this.cognitoService.idToken);
                        return next.handle(this.addAuthHeader(req));
                    }

                    return this.logoutUser();
                }),
                catchError(error => {
                    return this.logoutUser();
                }),
                finalize(() => {
                    this.isRefreshingToken = false;
                }));
        } else {
            return this.tokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {
                    return next.handle(this.addAuthHeader(req));
                }));
        }
    }

    logoutUser() {
        return Observable.create('');
    }

    addAuthHeader(request) {
      if (this.cognitoService === undefined ||
        this.cognitoService.idToken === undefined ||
        this.cognitoService.accessToken === undefined) {
        throw new Error('Authorization tokens not set');
      }
      // const timeZone = this.getTimezone();
      // Making timezone fixed to UK time until we save it on the kickaround.
      const timeZone = 'Europe/London';
      return request.clone({
        headers: request.headers
          .set('REVOID', this.cognitoService.idToken)
          .set('REVOACCESS', this.cognitoService.accessToken)
          .set('REVOHASH', VERSION.hash)
          .set('REVOVERSION', VERSION.version)
          .set('REVOTIMEZONE', timeZone)
          .set('Access-Control-Allow-Origin', '*')
      });
    }

    getTimezone() {
      try {
        if (typeof Intl === 'object' && typeof Intl.DateTimeFormat === 'function') {
          const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          if (timeZone == null) {
            return 'NONE';
          } else {
            return timeZone;
          }
        } else {
          return 'NONE';
        }
      } catch  {
        return 'NONE';
      }
    }

    addPublicHeader(request) {
      const timeZone = this.getTimezone();

      if (request.url.indexOf('/auth/user') === -1) {
        return request.clone({
          headers: request.headers
            .set('REVOHASH', VERSION.hash)
            .set('REVOVERSION', VERSION.version)
            .set('REVOTIMEZONE', timeZone)
            .set('Access-Control-Allow-Origin', '*')
        });
      } else {
        return request;
      }
    }
}

