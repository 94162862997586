import {IDMap} from '../interfaces/idmap.interface';

export function filterIDMap(dict: IDMap<any>, field: string, value: string | number): any[] {
  const result = [];
  for (const [id, item] of Object.entries(dict)) {
    if (item[field] === value) {
      result.push(item);
    }
  }
  return result;
}
