import {Inject, Injectable, OnDestroy} from '@angular/core';
import {NavigationEnd, Router, RouterEvent} from '@angular/router';
import {Subject, Subscription} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {WINDOW} from '../../shared/services/fm-windows.service';
import {filter} from 'rxjs/operators';
import {productFruits} from "product-fruits";
import {Store} from "@ngxs/store";
import {User} from "../user/models/user.model";
import {Player} from "../user/models/player.model";
import {UserSelectors} from "../user/state/user.selectors";


export enum OnboardingEvents {
  CreateGroup = 'Create Group',
  ScheduleGame = 'Scheduled Game',
  InviteToGroup = 'Invite To Group',
  CustomizeGroup = 'Customise Group',
  SendGameInvites = 'Send Game Invites',
  ShareGameInviteLink = 'Share Game Invite Link',
  ConfirmGame = 'Confirm Game',
  CancelGame = 'Cancel Game',
  SelectTeams = 'Select Teams',
  SendTeams = 'Send Teams',
  SendGroupMessage = 'Send Group Message',
  PauseGames = 'Pause Games',
  EnableGames = 'Enable Games',
  UpdateBank = 'Update Bank Details',
  EnableAutoPayout = 'Enable Auto Payout',
  EnableManualPayout = 'Enable Manual Payout',
  RecordScore = 'Record Score',
  UpdateProfilePicture = 'Update Profile Picture',
  UpdatePlayerStats = 'Update Player Stats',
  UpdatePlayerAvailability = 'Update Player Availability',
}

declare var $crisp: any;

@Injectable({
  providedIn: 'root'
})
export class FMOnboardingService implements OnDestroy {

  loggedInUser: User;
  loggedInPlayer: Player;
  subscription: Subscription;
  eventSubject = new Subject<OnboardingEvents>()
  checklistItemLaunchedEventsSubject = new Subject<string>()

  constructor(
    private router: Router,
    private logger: NGXLogger,
    private store: Store,
    @Inject(WINDOW) private window
  ) {
    // Subscribe to router events
    this.subscription = router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).subscribe((routerEvent: RouterEvent) => {
        this.recordURLChangeEvent(routerEvent.url);
      });

    this.setupChecklistItemLaunchedEventListening();
  }

  initWithUser() {
    const obj = {};
    this.loggedInUser = this.store.selectSnapshot(UserSelectors.user);
    this.loggedInPlayer = this.store.selectSnapshot(UserSelectors.player);
    productFruits.init(
      'XkEmo74BjgvYbdgY',
      'en', {
        username: this.loggedInUser.email,
      },
{
        disableLocationChangeDetection: true
      }
    );
    if ($crisp) {
      $crisp.push(["do", "chat:hide"])
      console.log('Hide Chat')
    }
  }

  updateUser(extraProp: any) {
    productFruits.safeExec(($productFruits) => {
      const productFruitsUser = window['productFruitsUser'];
      window['productFruits'].identifyUser({...productFruitsUser, props: extraProp });
    });
  }

  setupChecklistItemLaunchedEventListening() {
    console.log('setupChecklistItemLaunchedEventListening');
    productFruits.safeExec(($productFruits) => {
     window['productFruits'].api.checklists.listen('item-launched', (id, internalId) => {
       this.checklistItemLaunchedEventsSubject.next(internalId);
     });
   });
  }

  track(customEventName: OnboardingEvents) {
    console.log(`Tracking event received: ${customEventName}`);
    this.eventSubject.next(customEventName);
    productFruits.safeExec(($productFruits) => {
      window['productFruits'].api.events.track(customEventName);
      console.log(`Tracking event sent: ${customEventName}`);
    });
  }

  // Used for funnels where the location doesn't change
  private recordURLChangeEvent(url: string) {
    try {
      productFruits.safeExec(($productFruits) => {
        $productFruits.push(['pageChanged']);
      });
    } catch (e) {
      this.logger.error('Unable to send recordURLChangeEvent event: ', url);
    }
  }

  markItemAsDone(itemId, expand=false) {
    productFruits.safeExec(($productFruits) => {
      window['productFruits'].api.checklists.markItemAsDone(itemId, expand);
      console.log(`Mark checklist item action sent: ${itemId}`);
    })
  }

  launchTour(tourId) {
    productFruits.safeExec(($productFruits) => {
      window['productFruits'].api.tours.tryStartTour(tourId);
    })
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getEventSubject$() {
    return this.eventSubject.asObservable();
  }

  checklistItemLaunchedEventsSubject$() {
    return this.checklistItemLaunchedEventsSubject.asObservable();
  }

}
