import {Component, Inject, OnInit} from '@angular/core';

import {MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA, MatLegacySnackBarRef as MatSnackBarRef} from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'fm-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent implements OnInit {
  msg: string;
  action: string;
  isDismissable: boolean;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, public snackBarRef: MatSnackBarRef<SnackBarComponent>) {
    this.msg = data['msg'];
    this.action = data['action'];
    this.isDismissable = data['isDismissable'];
  }

  ngOnInit() {}


}
