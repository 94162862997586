import {Component, HostListener, OnInit} from '@angular/core';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import {NGXLogger} from 'ngx-logger';
import {Store} from '@ngxs/store';


import {UserSelectors} from '../../features/user/state/user.selectors';
import {VERSION} from '../../../environments/buildversion';
import {Player} from '../../features/user/models/player.model';
import {User} from '../../features/user/models/user.model';
import {OpenReplayService} from "../../shared/services/fm-openreplay-service";
import {FMAnalyticsService} from "../../features/analytics/fm-analytics.service";
import {FMOnboardingService} from "../../features/analytics/fm-onboarding.service";
import {
  FMGroupOnboardingChecklistService
} from "../../features/analytics/checklists/fm-group-onboarding-checklist.service";

declare const heap;

declare const window;

declare var $crisp: any;

@Component({
  selector: 'fm-secure',
  templateUrl: './secure.component.html',
})
export class SecureComponent implements OnInit {

  loggedInUser: User;
  loggedInPlayer: Player;

  constructor(
    private logger: NGXLogger,
    private store: Store,
    private openReplayService: OpenReplayService,
    private fma: FMAnalyticsService,
    private fmOnboardingService: FMOnboardingService,
    private fmGroupOnboardingChecklistService: FMGroupOnboardingChecklistService,
  ) {
    this.loggedInUser = this.store.selectSnapshot(UserSelectors.user);
    this.loggedInPlayer = this.store.selectSnapshot(UserSelectors.player);
    window.sib.email_id = this.loggedInPlayer.email;
    this.logger.setCustomParams(new HttpParams()
      .set('FMPLAYERID', '' + this.loggedInPlayer.id)
      .set('FETYPE',  'ANGULAR-WEB')
      .set('HASH',  VERSION.hash)
      .set('VERSION',  VERSION.version)
    );
    this.setUserHeapUser();
  }

  ngOnInit() {
    this.fmOnboardingService.initWithUser();
    const obj = {};
    this.fma.identifyUser(this.loggedInPlayer.id.toString());
  }

  setUserHeapUser() {
    try {
      if (this.loggedInPlayer)  {
        // Sentry.setUser({
        //   id: this.loggedInPlayer.id.toString(),
        //   email: 'john.doe@example.com',
        //   username: this.loggedInPlayer.fullName,
        // });
        this.openReplayService.setUserData(
          this.loggedInPlayer.id.toString(),
          this.loggedInPlayer.fullName,
          this.loggedInPlayer.email
        )
        this.fma.identifyUser(this.loggedInPlayer.id.toString());
        heap.identify(this.loggedInPlayer.id);
        heap.addUserProperties({
          'Name': this.loggedInPlayer.fullName,
          'Mobile': (this.loggedInPlayer.mobile) ? this.loggedInPlayer.mobile : '',
          'Email': this.loggedInUser.email,
        });
        this.logger.info('Successfully set heap variables in secure');
      }
    } catch (e) {
      this.logger.error('Unable to set heap variables in secure');
    }
  }
}

