import {User} from '../models/user.model';
import {ResponseError} from '../../../shared/state/fm-state.interfaces';
import {Player} from '../models/player.model';
import {Feature} from '../models/feature.model';
import {FMSocialIdentityProvider, FMSocialRedirectAction} from '../services/social-login.service';

export class SocialLoginUser {
  static readonly type = '[Social Login Page] SocialLoginUser';

  constructor(public provider: FMSocialIdentityProvider,
              public redirectRoute: string,
              public saveRefreshToken: boolean,
              public postRedirectAction: FMSocialRedirectAction,
              public postRedirectRoute: string,
              public postRedirectData: any) {
  }
}

export class SaveSocialLoginPostAuthState {
  static readonly type = '[Social Save State] SaveSocialLoginPostAuthState';

  constructor(public tokens) {
  }
}

export class UploadProfileImage {
  static readonly type = '[Profile Page] UploadProfileImage';

  constructor(public file: File) {
  }
}

export class ClearProfileImage {
  static readonly type = '[Profile Page] ClearProfileImage';

  constructor() {
  }
}

export class SocialLoginSignOut {
  static readonly type = '[Social Login Sign Out Page] SocialLoginSignOut';

  constructor() {
  }
}

export class LoginUser {
  static readonly type = '[Login Page] LoginUser';

  constructor(public user: User, public saveRefreshToken: boolean) {
  }
}

export class StampCampaignAffilateOnPlayer {
  static readonly type = '[Login Page] StampCampaignAffilateOnPlayer';

  constructor(public affiliateToken: string, public campaignToken: string) {
  }
}

export class ReLoginUser {
  static readonly type = '[Login Page] ReLoginUser';

  constructor(public user: User, public saveRefreshToken: boolean) {
  }
}

export class LoginUserSuccess {
  static readonly type = '[Login Page] LoginUserSuccess';

  constructor(public idToken: string, public accessToken: string, public refreshToken: string, public saveRefreshToken: boolean) {
  }
}

export class LoginUserError {
  static readonly type = '[Login Page] LoginUserError';

  constructor(public error: ResponseError) {
  }
}

export class RefreshTokenLogin {
  static readonly type = '[API] RefreshTokenLogin';

  constructor() {
  }
}

export class RefreshTokenLoginSuccess {
  static readonly type = '[API] RefreshTokenLoginSuccess';

  constructor(public idToken: string, public accessToken: string) {
  }
}

export class RefreshTokenLoginError {
  static readonly type = '[API] RefreshTokenLoginError';

  constructor(public error: ResponseError) {
  }
}

export class SaveAttemptedSecureUrl {
  static readonly type = '[API] SaveAttemptedSecureUrl';

  constructor(public attemptedUrl: string) {
  }
}

export class ResetAttemptedSecureUrl {
  static readonly type = '[API] ResetAttemptedSecureUrl';

  constructor() {
  }
}

export class GetOTPCode {
  static readonly type = '[API] GetOTPCode';

  constructor(public currency: string) {
  }
}

export class AuthOTPCode {
  static readonly type = '[API] AuthOTPCode';

  constructor(public otp: string, public currency: string) {
  }
}

export class ChangePassword {
  static readonly type = '[API] ChangePassword';

  constructor(public previousPassword: string, public proposedPassword: string) {
  }
}

// Secure Update Player Details
export class UpdatePlayerDetails {
  static readonly type = '[Secure App] UpdateCOVIDPlayerDetails';

  constructor(public playerId: number, public fullName: string, public mobile: string, public optInFMTrackNTrace?: boolean, public optInSuggestedKickaroundWhatsApps?: boolean, public optInGameNotificationsWhatsApps?: boolean, public optInSuggestedKickaroundEmails?: boolean, public optInFMNewsEmails?: boolean) {
  }
}

// Public Update Player Details
export class UpdatePlayerDetailsViaPublicGamePlayerToken {
  static readonly type = '[Public App] UpdateCOVIDPlayerDetails';

  constructor(public kickaroundId: number, public gameId: number, public playerId: number, public fullName: string, public mobile: string, public token: string) {
  }
}

export class CloseAccount {
  static readonly type = '[Secure App] CloseAccount';

  constructor() {
  }
}


export class GetUserDetails {
  static readonly type = '[Secure App] GetUserDetails';

  constructor() {
  }
}

export class GetUserDetailsSuccess {
  static readonly type = '[Secure App] GetUserDetailsSuccess';

  constructor(public user: User, public player: Player, public features: Feature[]) {
  }
}

export class GetUserDetailsError {
  static readonly type = '[Secure App] GetUserDetailsError';

  constructor(public error: any) {
  }
}

export class Logout {
  static readonly type = '[Top Menu] Logout';

  constructor() {
  }
}


export class SetPublicPlayer {
  static readonly type = '[KA Public] SetPublicPlayer';

  constructor(public playerId: number, public token?: string) {
  }
}

export class SetGoFMToken {
  static readonly type = '[Public/Secure KA] SetGoFMToken';

  constructor(public goFMToken: string) {
  }
}

export class SetGameAction {
  static readonly type = '[Public/Secure KA] SetGameAction';

  constructor(public gameAction: string) {
  }
}

export class SetCampaignToken {
  static readonly type = '[Public/Secure KA] SetCampaignToken';

  constructor(public campaignToken: string) {
  }
}

export class SetAffiliateToken {
  static readonly type = '[Public/Secure KA] SetAffiliateToken';

  constructor(public affiliateToken: string) {
  }
}

export class SetDiscountCode {
  static readonly type = '[Public/Secure KA] SetDiscountCode';

  constructor(public discountCode: string) {
  }
}

export class SetOrganisation {
  static readonly type = '[Public/Secure KA] SetOrganisation';

  constructor(public organisation: string) {
  }
}
