export function extractErrorMessage(error) {
  /* Extracts error message from the range of different error formats coming from backend */
  // TODO standardise errors
  if ('error' in error) {
    const errorBody = error.error;
    if ('detail' in errorBody) {
      return errorBody['detail'];
    } else if ('result' in errorBody) {
      return errorBody['result'];
    } else if ('message' in errorBody) {
      return errorBody['message'];
    } else if ('msg' in errorBody) {
      return errorBody['msg'];
    } else if (typeof errorBody === 'string') {
      return errorBody['msg'];
    }
  } else if ('detail' in error) {
    return error['detail'];
  } else if ('result' in error) {
    return error['result'];
  } else if ('message' in error) {
    return error['message'];
  } else if ('msg' in error) {
    return error['msg'];
  } else if (typeof error === 'string') {
    return error['msg'];
  } else {
    return 'A server error has occurred';
  }
}
