import { Injectable } from '@angular/core';
import { sportConfig } from '../../../environments/sport';

@Injectable({
  providedIn: 'root'
})
export class FMSportsDetectorService {
  constructor() {
  }

  getSportConfig () {
    return sportConfig;
  }
}
