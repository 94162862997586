import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {

  constructor() {
    this.init();
  }

  init(): void {
    mixpanel.init(environment.mixpanelKey,
      {
        track_pageview: true,
        persistence: 'localStorage',
        cross_subdomain_cookie: true
      });
  }

  identify(userId: string) {
    mixpanel.identify(userId);
  }

  addProfileProperties(name: string, email: string) {
    mixpanel.people.set({
      name: name,
      email: email,
    });
  }

  track(actionName: string, properties: any = {}): void {
    mixpanel.track(actionName, properties);
  }
}
