import {SportConfig} from '../models/sport.model';

export const sportConfig: SportConfig = {
  name: 'Football',
  domain: 'footballmatcher.com',
  isFullLanding: true,
  logoSrc: '/assets/sports/football/logos/logo-green.svg',
  smallLogoSrc: '/assets/sports/football/logos/official_logo_man.png',
  styleSrc: '/styles/sports/football.scss',
  footerText: 'Football Matcher LTD',
  spinnerLogoSrc: '/assets/sports/football/logos/logo-black.svg',
  landingBackgroundImageSrc: '/assets/sports/football/backgrounds/landing-page/simple/football-match.jpg',
  kickaroundAlternativeName: 'Kickaround',
  kickaroundBannerImage: '/assets/sports/football/banners/green-banner-2.jpeg',
  hidePitchType: false,
  hidePitchSize: false,
  defaultPitchType: 'ind',
  defaultPitchSize: 5,
  defaultPaymentMethod: 'f',
  defaultFrequency: 'WEEKLY',
};
