export enum PaymentProcessors {
    cybersource = 'CS',
    worldpay = 'WP',
}

export enum AcceptDeclineGameResponse {
    confirm = 'confirm',
    decline = 'decline',
    unsubscribe = 'unsubscribe',
    lookup =  'lookup'
}

export enum GameStatusChoices {
  invites_sent_manually = 'M_INV_SENT',
  invites_sent_automatically = 'A_INV_SENT',
  confirmed = 'CONFIRMED',
  cancelled = 'CANCELLED',
  pending = 'PENDING'
}


export enum GameStatusDisplayChoices {
  M_INV_SENT = 'Invites Sent',
  A_INV_SENT = 'Invites Sent',
  COMPLETED = 'Completed',
  CONFIRMED = 'Confirmed',
  CANCELLED = 'Cancelled',
  PENDING = 'Pending'
}

export enum GamePlayerStatusChoices {
  accepted = 'a',
  waiting = 'w',
  needReplacement = 'r',
  declined = 'n',
  invited = 'i',
}

export enum GamePaymentStatusChoices {
  authorized = 'a',
  captured = 'c',
}

export enum AcceptButtonTextChoices {
  going = 'Going',
  waiting = 'Waiting',
}

export enum InviteStateChoices {
  accept = 'Accept',
  decline = 'Decline',
}

export enum PaymentFeaturesChoices {
  pay_online_to_confirm = 'PAY_ONLINE_ONLY_TO_CONFIRM',
  pay_online_cash_anytime = 'PAY_ONLINE_CASH_ANYTIME',
  pay_online_anytime = 'PAY_ONLINE_ONLY_ANYTIME',
}

export enum KickaroundMQTTNotificationTypes {
  kickaround = 'kickaround',
  player = 'player',
  kickaroundGame = 'kickaround-game',
  kickaroundGamePlayer = 'kickaround-game-player',
  kickaroundGamePlayerList = 'kickaround-game-player-list',
}


export enum PaymentModes {
  payCashOnly= 'c',
  payAnytime = 'a',
  payBeforePlay = 'p'
}

export enum PricingModes {
  fixed = 'f',
  dynamic = 'd',
}

export enum KickaroundStatusActionChoices {
  activate = 'ACTIVATE',
  pause = 'PAUSE',
  delete = 'DELETE'
}

export enum KickaroundStatusChoices {
  active = 'ACTIVE',
  paused = 'PAUSED',
  deleted = 'DELETED'
}

export enum WaitingListModeChoices {
  payToPlay = 'p',
  payAnytime = 'a',
}


export enum ConfirmationModeChoices {
  automatic = 'a',
  manual = 'm',
}
