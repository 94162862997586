import {isNil} from 'lodash-es';
import {keyBy} from 'lodash-es';

import {Kickaround} from '../models/database/kickaround.model';
import {KickaroundGame} from '../models/database/kickaround-game.model';
import {KickaroundGamePlayer} from '../models/database/kickaround-game-player.model';
import {Player} from '../../user/models/player.model';
import {
  AcceptButtonTextChoices,
  GamePaymentStatusChoices,
  GamePlayerStatusChoices, GameStatusChoices,
  GameStatusDisplayChoices,
  InviteStateChoices,
  PaymentModes
} from '../kickarounds.enums';
import {GameUIModel} from '../models/frontend/kickaround-game-ui.model';
import {Feature} from '../../user/models/feature.model';
import {PAYMENT_METHOD_CASH_ONLY, PAYMENT_METHOD_FREE_GAME} from '../modules/forms/form-choices';


function showPaymentButtons(game: KickaroundGame, inviteState, gameStatus, paidOnline, isEligibleForPayments, onWaitingList, isPayBeforePlay, isOrganiser, expiryDate, dateTimeSync, isGameHost, hasUserPrepaid) {
  const playerAccepted = inviteState === InviteStateChoices.accept;
  const gameConfirmed = gameStatus === GameStatusChoices.confirmed;
  const gameCancelled = gameStatus === GameStatusChoices.cancelled;
  const noOnlinePayment = (game.paymentMode === PAYMENT_METHOD_FREE_GAME.value) || (game.paymentMode === PAYMENT_METHOD_CASH_ONLY.value);
  const payToPayInviteExpiredPlayer = !isOrganiser && !isGameHost && isPayBeforePlay && expiryDate && expiryDate.getTime() < dateTimeSync.getTime();
  return !hasUserPrepaid && (playerAccepted && ( gameConfirmed || isPayBeforePlay ) && !isGameHost && !gameCancelled &&  !paidOnline && !onWaitingList && !noOnlinePayment && !payToPayInviteExpiredPlayer);
}

function showPrepaidConfirmReleaseButtons(game: KickaroundGame, inviteState, gameStatus, paidOnline, isEligibleForPayments, onWaitingList, isPayBeforePlay, isOrganiser, expiryDate, dateTimeSync, isGameHost, hasUserPrepaid) {
  const playerAccepted = inviteState === InviteStateChoices.accept;
  const gameConfirmed = gameStatus === GameStatusChoices.confirmed;
  const gameCancelled = gameStatus === GameStatusChoices.cancelled;
  const noOnlinePayment = (game.paymentMode === PAYMENT_METHOD_FREE_GAME.value) || (game.paymentMode === PAYMENT_METHOD_CASH_ONLY.value);
  const prepaidConfirmInviteExpiredPlayer = !isOrganiser && !isGameHost && isPayBeforePlay && expiryDate && expiryDate.getTime() < dateTimeSync.getTime();
  return hasUserPrepaid && (playerAccepted && ( gameConfirmed || isPayBeforePlay ) && !isGameHost && !gameCancelled &&  !paidOnline && !onWaitingList && !noOnlinePayment && !prepaidConfirmInviteExpiredPlayer);
}

function showPrepaidDropoutButton(game: KickaroundGame, inviteState, gameStatus, paidOnline, isEligibleForPayments, onWaitingList, isPayBeforePlay, isOrganiser, expiryDate, dateTimeSync, isGameHost, hasUserPrepaid) {
  const playerAccepted = inviteState === InviteStateChoices.accept;
  const gameConfirmed = gameStatus === GameStatusChoices.confirmed;
  const gameCancelled = gameStatus === GameStatusChoices.cancelled;
  const noOnlinePayment = (game.paymentMode === PAYMENT_METHOD_FREE_GAME.value) || (game.paymentMode === PAYMENT_METHOD_CASH_ONLY.value);
  return hasUserPrepaid && (playerAccepted && ( gameConfirmed || isPayBeforePlay ) && !isGameHost && !gameCancelled && paidOnline && !onWaitingList && !noOnlinePayment);
}

function showAcceptDeclineButtons(inviteState, gameStatus, paidOnline, isEligibleForPayments, onWaitingList, game, isPayBeforePlay, isOrganiser, gamePlayerStatus, expiryDate, dateTimeSync, isGameHost, hasUserPrepaid) {
  const playerAccepted = inviteState === InviteStateChoices.accept;
  const playerPendingReplacement = gamePlayerStatus === GamePlayerStatusChoices.needReplacement;
  const gameConfirmed = gameStatus === GameStatusChoices.confirmed;
  const noOnlinePayment = (game.paymentMode === PAYMENT_METHOD_FREE_GAME.value) || (game.paymentMode === PAYMENT_METHOD_CASH_ONLY.value);
  const payToPayInviteExpiredPlayer = !isOrganiser && !isGameHost && isPayBeforePlay && expiryDate && expiryDate.getTime() < dateTimeSync.getTime();

  if (isGameHost && playerAccepted) {
    return true;
  }
  if (paidOnline || (isPayBeforePlay && playerAccepted && !onWaitingList && !payToPayInviteExpiredPlayer) || playerPendingReplacement) {
    return false;
  }
  if (playerAccepted && gameConfirmed && !paidOnline && isEligibleForPayments && !onWaitingList &&  !noOnlinePayment && !payToPayInviteExpiredPlayer) {
    return false;
  } else if (playerAccepted && gameConfirmed && paidOnline && isEligibleForPayments && !onWaitingList &&  !noOnlinePayment) {
    return false;
  }  else if (gameStatus === GameStatusChoices.cancelled) {
    return false;
  }

  if (game.startDate < new Date()) {
    return false;
  }
  return true;
}

function checkIfEligibleForPayments(loggedInGamePlayer: KickaroundGamePlayer) {
  if ( loggedInGamePlayer && (!loggedInGamePlayer.paymentStatus || loggedInGamePlayer.paymentStatus === 'd')) {
    return loggedInGamePlayer.paymentContext != null && (loggedInGamePlayer.paymentStatus == null || loggedInGamePlayer.paymentStatus === 'd');
  } else {
    return false;
  }
}

function convertGameStatus(game, gameIsInPast) {
  switch (game.status) {
    case 'M_INV_SENT':
      return GameStatusDisplayChoices.M_INV_SENT;
    case 'A_INV_SENT':
      return GameStatusDisplayChoices.A_INV_SENT;
    case 'CONFIRMED':
      return (gameIsInPast) ? GameStatusDisplayChoices.COMPLETED : GameStatusDisplayChoices.CONFIRMED;
    case 'CANCELLED':
      return GameStatusDisplayChoices.CANCELLED;
    default:
      return GameStatusDisplayChoices.PENDING;
  }
}

/* Updates flags on kickaround list and detail pages */
export function generateKickaroundGameUIState(kickaround: Kickaround, game: KickaroundGame, gamePlayers: KickaroundGamePlayer[], loggedInPlayerId: number, userFeatures: Feature[]) {
  const gUI = new GameUIModel();

  const today = new Date();
  gUI.isLoading = false;
  gUI.isUserOrganiser = loggedInPlayerId === kickaround.createdBy;
  gUI.isUserGameHost = game && loggedInPlayerId === game.gameHost;
  gUI.gameHostId = game && game.gameHost;
  // Init Counts
  gUI.numOfGamePlayersConfirmed = 0;
  gUI.numOfGamePlayersAccepted = 0;
  gUI.numOfGamePlayersNeedingReplacement = 0;
  gUI.numOfGamePlayersWaiting = 0;
  gUI.numOfGamePlayers = gamePlayers.length;
  gUI.numOfSpotsRemaining = kickaround.maxRequiredPlayers;
  gUI.showAcceptDeclineButtons = false;

  // If kickaround has latestGame
  if (game) {
    gUI.id = game.id;
    gUI.startTime = new Date(`2019-01-01 ${kickaround.startTime}`);
    gUI.gameInPast = game.start < new Date();
    gUI.gameUrl = kickaround.url && kickaround.url.replace('join', 'latestGame');
    gUI.startDate = game.start;
    gUI.inviteSendDatetime = game.inviteSendDatetime;
    gUI.gameStatus = convertGameStatus(game, gUI.gameInPast);
    gUI.showSendInvitesButton = gUI.isUserOrganiser && gUI.gameStatus === GameStatusDisplayChoices.PENDING;
    gUI.showCancellationButton = gUI.isUserOrganiser && ((gUI.gameStatus === GameStatusDisplayChoices.M_INV_SENT) || (gUI.gameStatus === GameStatusDisplayChoices.A_INV_SENT) ||
       (gUI.gameStatus === GameStatusDisplayChoices.CONFIRMED)) && (game.start.getDate() >= today.getDate() );
    gUI.showConfirmGameButton = gUI.isUserOrganiser && ((gUI.gameStatus === GameStatusDisplayChoices.A_INV_SENT) || (gUI.gameStatus === GameStatusDisplayChoices.CANCELLED));

  // Should show accept/decline/pay online buttons
  gUI.showResponseButtons = (game.start >= new Date()) && !isNil(loggedInPlayerId)
    && ((gUI.gameStatus === GameStatusDisplayChoices.A_INV_SENT) || (gUI.gameStatus === GameStatusDisplayChoices.CONFIRMED));

  }

  // If latestGame invites have been sent
  if (gamePlayers.length) {
    // Kickaround latestGame flags
    gamePlayers.forEach((gp) => {
      if (gp.gameStatus === GamePlayerStatusChoices.accepted || gp.gameStatus ===  GamePlayerStatusChoices.waiting) {
        gUI.numOfGamePlayersConfirmed += 1;

        const gamePlayerisOrganiser = (kickaround.createdBy !== gp.playerId);
        const checkPayment = (game.paymentMode === PaymentModes.payBeforePlay) && (!gamePlayerisOrganiser);

        if (gp.gameStatus ===  GamePlayerStatusChoices.accepted) {
          gUI.numOfGamePlayersAccepted += 1;

          // // Check prepaid player had
          // if (checkPayment) {
          //   if ((gp.paymentStatus === 'c')) {
          //     gUI.numOfGamePlayersAccepted += 1;
          //   }
          // } else  {
          //   gUI.numOfGamePlayersAccepted += 1;
          // }
        } else {
          gUI.numOfGamePlayersWaiting += 1;
        }
      }
      if (gp.gameStatus ===  GamePlayerStatusChoices.needReplacement) {
          gUI.numOfGamePlayersNeedingReplacement += 1;
      }
    });

    // If we have 0 players accepted and all  other records are pending replacement records lets show them
    if (gUI.numOfGamePlayersAccepted ===  0) {
      gUI.numOfGamePlayersAccepted = gUI.numOfGamePlayersAccepted + gUI.numOfGamePlayersNeedingReplacement;
    }

    // Game Flags
    gUI.numOfSpotsRemaining = kickaround.maxRequiredPlayers - gUI.numOfGamePlayersAccepted;
    gUI.gameInvitesActive = (gamePlayers.length > 0) && (game.status !== 'Pending') && (game.status !== 'Cancelled');
    gUI.enableAcceptButton = gUI.gameInvitesActive && ((gUI.gameStatus === GameStatusDisplayChoices.A_INV_SENT) || (gUI.gameStatus === GameStatusDisplayChoices.CONFIRMED));

    // If logged in player Id
    if (loggedInPlayerId) {
      const loggedInGamePlayer = gamePlayers.find(gp => gp.playerId === loggedInPlayerId);

      if (loggedInGamePlayer) {
         const playerGameStatus = loggedInGamePlayer.gameStatus;

        // Invite state
        const inviteState = (playerGameStatus === GamePlayerStatusChoices.waiting || playerGameStatus === GamePlayerStatusChoices.accepted) ? 'Accept' : 'Decline';
        gUI.acceptButtonText = (playerGameStatus === GamePlayerStatusChoices.waiting) ? AcceptButtonTextChoices.waiting : AcceptButtonTextChoices.going;

        // User Flags
        gUI.hasUserPrepaid = ( loggedInGamePlayer.prePaid && loggedInGamePlayer.prePaidRemainingGames > 0 ) || loggedInGamePlayer.organiserDiscountPercentage === 100;
        gUI.isPayBeforePlay = (game.paymentMode === PaymentModes.payBeforePlay) && (loggedInGamePlayer.paymentContext != null);
        gUI.paidOnline = loggedInGamePlayer.paymentStatus === GamePaymentStatusChoices.authorized || loggedInGamePlayer.paymentStatus === GamePaymentStatusChoices.captured;
        gUI.isUserEligibleForPayments = (loggedInGamePlayer.paymentContext != null) && checkIfEligibleForPayments(loggedInGamePlayer);
        gUI.isUserOnWaitingList = loggedInGamePlayer.gameStatus === GamePlayerStatusChoices.waiting;
        gUI.isUserOffWaitingList = loggedInGamePlayer.previousGameStatus === GamePlayerStatusChoices.waiting
          && loggedInGamePlayer.gameStatus ===  GamePlayerStatusChoices.accepted;

        gUI.isUserGoing = loggedInGamePlayer.gameStatus === GamePlayerStatusChoices.accepted;
        gUI.userGamePlayerStatus = loggedInGamePlayer.gameStatus;
        gUI.userGamePlayer = loggedInGamePlayer;
        gUI.showPendingReplacementStateButtons = loggedInGamePlayer.gameStatus === GamePlayerStatusChoices.needReplacement;
        gUI.showPendingRefundStateButtons = loggedInGamePlayer.gameStatus === GamePlayerStatusChoices.declined && ( loggedInGamePlayer.paymentStatus === GamePaymentStatusChoices.authorized || loggedInGamePlayer.paymentStatus === GamePaymentStatusChoices.captured );

        gUI.showAcceptDeclineButtons = showAcceptDeclineButtons(
          inviteState,
          game.status,
          gUI.paidOnline,
          gUI.isUserEligibleForPayments,
          gUI.isUserOnWaitingList,
          game,
          gUI.isPayBeforePlay,
          loggedInGamePlayer.gameStatus,
          gUI.isUserOrganiser,
          loggedInGamePlayer.expiryDate,
          loggedInGamePlayer.lastUpdatedOn,
          gUI.isUserGameHost,
          gUI.hasUserPrepaid
        );
        gUI.showPaymentButtons = showPaymentButtons(
          game,
          inviteState,
          game.status,
          gUI.paidOnline,
          gUI.isUserEligibleForPayments,
          gUI.isUserOnWaitingList,
          gUI.isPayBeforePlay,
          gUI.isUserOrganiser,
          loggedInGamePlayer.expiryDate,
          loggedInGamePlayer.lastUpdatedOn,
          gUI.isUserGameHost,
          gUI.hasUserPrepaid
        );
        gUI.showPrepaidConfirmReleaseButtons = showPrepaidConfirmReleaseButtons(
          game,
          inviteState,
          game.status,
          gUI.paidOnline,
          gUI.isUserEligibleForPayments,
          gUI.isUserOnWaitingList,
          gUI.isPayBeforePlay,
          gUI.isUserOrganiser,
          loggedInGamePlayer.expiryDate,
          loggedInGamePlayer.lastUpdatedOn,
          gUI.isUserGameHost,
          gUI.hasUserPrepaid
        );
        gUI.showPrepaidDropoutButton = showPrepaidDropoutButton(
          game,
          inviteState,
          game.status,
          gUI.paidOnline,
          gUI.isUserEligibleForPayments,
          gUI.isUserOnWaitingList,
          gUI.isPayBeforePlay,
          gUI.isUserOrganiser,
          loggedInGamePlayer.expiryDate,
          loggedInGamePlayer.lastUpdatedOn,
          gUI.isUserGameHost,
          gUI.hasUserPrepaid
        );
        gUI.acceptButtonText = (gUI.isUserOnWaitingList) ? AcceptButtonTextChoices.waiting : AcceptButtonTextChoices.going;
      }
    }
    return gUI;
  }

  return gUI;
}

/* Updates flags on list of all kickarounds */
export function generateAllKickaroundGameUIStates(allKickarounds: Kickaround[], allGames: KickaroundGame[], allGamePlayers: KickaroundGamePlayer[], loggedInPlayer: Player, userFeatures: Feature[]) {
  const uiStates = [];
  allGames.forEach(game => {
    const kickaround = allKickarounds.find(k => k.id === game.kickaroundId);
    const gamePlayers = allGamePlayers.filter(gp => gp.gameId === game.id);
    const uiState = generateKickaroundGameUIState(
      kickaround,
      game,
      gamePlayers,
      loggedInPlayer.id,
      userFeatures
    );
    uiStates.push(uiState);
  });
  return keyBy(uiStates, 'id');
}
