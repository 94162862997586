import {Injectable} from '@angular/core';
import {Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';

import {LoginService} from '../services/login.service';
import {LocalStorageService} from '../services/local-storage.service';
import {Store} from '@ngxs/store';
import {
  SetAffiliateToken,
  SetCampaignToken, SetDiscountCode,
  SetGameAction,
  SetGoFMToken, SetOrganisation,
  StampCampaignAffilateOnPlayer
} from '../state/user.actions';
import {ProcessSocialLoginPostRedirectWorkflow} from '../state/social-login-redirect.actions';
import {SocialLoginRedirectState} from '../state/social-login-redirect.state';
import {SocialLoginService} from '../services/social-login.service';
import {SnackBarService} from '../../../shared/modules/announcements/snackbar/snack-bar.service';
import {SocialLoginTokenExtractService} from '../services/social-login-token-extract.service';

@Injectable({
  providedIn: 'root',
})
export class ExtractTokensGuard implements CanActivate {
  private completedLocalStorageCheck = false;
  private PUBLIC_AUTH_LOGIN_ROUTE = '/auth/login';
  private affiliateToken: string;
  private campaignToken: string;
  private socialLoginToken: string;

  constructor(public loginService: LoginService,
              public socialLoginService: SocialLoginService,
              public socialLoginRedirectState: SocialLoginRedirectState,
              public socialLoginTokenExtractService: SocialLoginTokenExtractService,
              public localStorageService: LocalStorageService,
              public router: Router,
              public store: Store,
              public snackBar: SnackBarService,
  ) {
  }

  extractAndSaveGoFMToken(route) {
    const gofmToken = route.queryParams['gofmToken'];
    const gameAction = route.queryParams['action'];
    const affiliateToken = route.queryParams['at'];
    const campaignToken = route.queryParams['ct'];
    const discountCode = route.queryParams['dc'];
    const organisation = route.queryParams['org'];
    if (!this.completedLocalStorageCheck) {
      this.socialLoginTokenExtractService.extractTokensForPostSocialLoginRedirectRecovery(gofmToken, gameAction, affiliateToken, campaignToken);
    }
    if (gofmToken) {
      this.store.dispatch(new SetGoFMToken(gofmToken));
    } else if (!this.completedLocalStorageCheck) {
      this.recoverGoFmToken();
    }
    if (gameAction) {
      this.store.dispatch(new SetGameAction(gameAction));
    } else if (!this.completedLocalStorageCheck) {
      this.recoverGameAction();
    }
    if (discountCode) {
      this.store.dispatch(new SetDiscountCode(discountCode));
    } else if (!this.completedLocalStorageCheck) {
      this.recoverDiscountCode();
    }
    if (organisation) {
      this.store.dispatch(new SetOrganisation(organisation));
    } else if (!this.completedLocalStorageCheck) {
      this.recoverOrganisation();
    }
    if (affiliateToken) {
      this.store.dispatch(new SetAffiliateToken(affiliateToken));
      this.affiliateToken = affiliateToken;
    } else if (!this.completedLocalStorageCheck) {
      this.recoverAffiliateToken();
    }
    if (campaignToken) {
      this.store.dispatch(new SetCampaignToken(campaignToken));
      this.campaignToken = campaignToken;
    } else if (!this.completedLocalStorageCheck) {
      this.recoverCampaignToken();
    }
    this.completedLocalStorageCheck = true;
  }

  recoverGoFmToken() {
    const key = 'gofmToken';
    const localStorageValuue = this.localStorageService.getValueForKey(key);
    if (localStorageValuue) {
      this.store.dispatch(new SetGoFMToken(localStorageValuue));
      this.localStorageService.removeValueForKey(key);
    }
  }

  recoverGameAction() {
    const key = 'action';
    const localStorageValuue = this.localStorageService.getValueForKey(key);
    if (localStorageValuue) {
      this.store.dispatch(new SetGameAction(localStorageValuue));
      this.affiliateToken = localStorageValuue;
      this.localStorageService.removeValueForKey(key);
    }
  }

  recoverCampaignToken() {
    const key = 'ct';
    const localStorageValuue = this.localStorageService.getValueForKey(key);
    if (localStorageValuue) {
      this.store.dispatch(new SetCampaignToken(localStorageValuue));
      this.campaignToken = localStorageValuue;
      this.localStorageService.removeValueForKey(key);
    }
  }

  recoverAffiliateToken() {
    const key = 'at';
    const localStorageValuue = this.localStorageService.getValueForKey(key);
    if (localStorageValuue) {
      this.store.dispatch(new SetAffiliateToken(localStorageValuue));
      this.affiliateToken = localStorageValuue;
      this.localStorageService.removeValueForKey(key);
    }
  }

  recoverDiscountCode() {
    const key = 'dc';
    const localStorageValuue = this.localStorageService.getValueForKey(key);
    if (localStorageValuue) {
      this.store.dispatch(new SetDiscountCode(localStorageValuue));
      this.localStorageService.removeValueForKey(key);
    }
  }

  recoverOrganisation() {
    const key = 'org';
    const localStorageValuue = this.localStorageService.getValueForKey(key);
    if (localStorageValuue) {
      this.store.dispatch(new SetOrganisation(localStorageValuue));
      this.localStorageService.removeValueForKey(key);
    }
  }

  // Extract and store any query params used acrosss the app (like token)
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.extractAndSaveGoFMToken(route);
    const isPublicAuthRoute = state.url.indexOf(this.PUBLIC_AUTH_LOGIN_ROUTE) !== -1;
    if (isPublicAuthRoute) {
      this.socialLoginToken = route.queryParams.code;
      if (this.socialLoginToken) {
        this.continueSocialLogin();
      }
    }
    return true;
  }

  continueSocialLogin() {
    const redirectRoute = this.localStorageService.getValueForKey('OATH2_REDIRECT');
    this.socialLoginService.getCognitoTokens(this.socialLoginToken, redirectRoute).subscribe(tokens => {
      this.handleSocialLoginSuccess();
    }, error1 => {
      if (this.socialLoginService.validForRetry()) {
        this.socialLoginService.retrySocialSignIn();
      } else {
        this.snackBar.open('We could not log you in, please try again. Error code (' + error1.error.error + ')');
      }
    });
  }

  handleSocialLoginSuccess() {
    if (this.socialLoginToken) {
      if (this.campaignToken || this.affiliateToken) {
        this.store.dispatch(new StampCampaignAffilateOnPlayer(this.affiliateToken, this.campaignToken)).toPromise().then(rslt => {
          this.snackBar.open('Log in successful, loading page');
          this.store.dispatch(new ProcessSocialLoginPostRedirectWorkflow()).subscribe(redirectData => {
            const redirectRoute = redirectData.socialLoginRedirectState.redirectRoute;
            const extras = redirectData.socialLoginRedirectState.redirectRouteExtras;
            this.router.navigate(redirectRoute, extras);
          });
        });
      } else {
        this.snackBar.open('Log in successful, loading page');
        this.store.dispatch(new ProcessSocialLoginPostRedirectWorkflow()).subscribe(redirectData => {
          const redirectRoute = redirectData.socialLoginRedirectState.redirectRoute;
          const extras = redirectData.socialLoginRedirectState.redirectRouteExtras;
          this.router.navigate(redirectRoute, extras);
        });
      }
    }
  }
}
