<div class="submenu">
  <div fxLayout="row" >
    <div *ngFor="let navItem of navItems; let first = first; let last = last;" class="subNavItem">
      <fa-icon [icon]="faFutbol" *ngIf="first && navItem.label === 'Your Kickarounds'" class="firstIcon"></fa-icon>
      <fa-icon [icon]="faCoins" *ngIf="first && navItem.label === 'Your Payments'" class="firstIcon"></fa-icon>
      <fa-icon [icon]="faUser" *ngIf="first && navItem.label === 'Your Profile'" class="firstIcon"></fa-icon>

      <a [routerLink]="navItem.route" class="link-with-no-background">{{navItem.label}}</a>
      <fa-icon *ngIf="!last" [icon]="faAngleRight" class="hover" size="sm" class="subNavIcon"></fa-icon>
    </div>
  </div>
</div>
