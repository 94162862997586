import { Buffer } from 'buffer';

import 'zone.js';
import 'zone.js/dist/task-tracking';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import * as Sentry from "@sentry/angular-ivy";

import '@angular/localize/init';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {enableProdMode} from '@angular/core';
import Tracker from '@openreplay/tracker';
import trackerAssist from '@openreplay/tracker-assist';
import type {Options as NetworkOptions} from "@openreplay/tracker/lib/modules/network";


window['Buffer'] = window['Buffer'] || Buffer;
// @ts-ignore
window['global'] = {}

// if (environment.production) {
//   Sentry.init({
//     dsn: 'https://641896123ba84aaa9c4b14cea1ba16df@o4505210407092224.ingest.sentry.io/4505210534166528',
//     debug: !environment.production,
//     environment: environment.name,
//     integrations: [
//       new Sentry.BrowserTracing({
//         routingInstrumentation: Sentry.routingInstrumentation,
//         tracingOrigins: ['*'],
//       }),
//       new Sentry.Replay(),
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   });
// }
if (environment.production) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(
  AppModule, {
  providers: [
    ]
  }
);

bootstrap().then(() => {
 // if ('serviceWorker' in navigator) {
 //   navigator.serviceWorker.register('/ngsw-worker.js');
 // }
}).catch(err => console.log(err));





