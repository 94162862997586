// @ts-ignore
import {Injectable} from '@angular/core';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {VERSION} from '../../../environments/buildversion';

// declare let registerInitialStat: Function;
// declare let registerFinalStat: Function;
// declare let uuidv4: Function;

@Injectable()
export class PerformanceService {
  private url;
  private prevUrl;
  private sessionId;
  private changeDetectionCount = 0;
  private angularInitTime;
  constructor(private router: Router) {
    // this.sessionId = uuidv4();
  }

  processRouterEvent(routerEvent) {
    // if (routerEvent instanceof NavigationStart) {
    //   this.changeDetectionCount = 0;
    //   if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().previousNavigation
    //     && this.router.getCurrentNavigation().previousNavigation.finalUrl) {
    //     this.prevUrl = this.router.getCurrentNavigation().previousNavigation.finalUrl.toString();
    //     if (this.url === undefined || routerEvent.url !== this.url) {
    //       registerInitialStat(routerEvent.url, this.router.getCurrentNavigation().previousNavigation.finalUrl.toString(), VERSION.hash, VERSION.version, this.changeDetectionCount);
    //     }
    //   }
    // }

    // if (routerEvent instanceof NavigationEnd ||
    //   routerEvent instanceof NavigationCancel ||
    //   routerEvent instanceof NavigationError) {
    //   if (this.url === undefined || routerEvent.url !== this.url) {
    //     const initialTime = window['startUrlStartTime'];
    //     const timeToInteractive = new Date();
    //     registerFinalStat(routerEvent.url, this.prevUrl, VERSION.hash, VERSION.version, initialTime, timeToInteractive, this.changeDetectionCount, this.angularInitTime, this.sessionId);
    //     this.url = routerEvent.url;
    //   }
    // }
  }

  incrementChangeDetectionCount() {
    // this.changeDetectionCount++;
  }

  registerAngularInitTime() {
    // this.angularInitTime = new Date();
  }
}
