import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {ErrorService} from '../../../routes/public/error/error-service';
import {LoginService} from '../../user/services/login.service';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {Deserialize, Serialize} from 'cerialize';
import {catchError, map} from 'rxjs/operators';
import {SuggestedKickaround} from '../models/database/suggested-kickaround.model';

@Injectable({
  providedIn: 'root'
})
export class SuggestedKickaroudsService {
  public apiUrl;
  public baseUrl;

  constructor(private http: HttpClient,
              private router: Router,
              private errorService: ErrorService,
              private loginService: LoginService) {
    this.apiUrl = environment.api;
    this.baseUrl = environment.baseUrl;
  }

  getSuggestedKickarounds(): Observable<SuggestedKickaround[]> {
      const endpoint =  `${this.apiUrl}/secure/suggestedKickarounds`;
      return this.http.get(endpoint).pipe(
        map((suggestedKickarounds) => {
        return Deserialize(suggestedKickarounds, SuggestedKickaround);
        })
      );
    }
}
