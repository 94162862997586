export enum InvitePriorityDBChoices {
  regularPlayer = 1,
  normalPlayer  = 2,
}

export enum InvitePriorityUIChoices {
  regularPlayer = 'Regular',
  normalPlayer  = 'Normal',
}

export const GroupVisibilitySelectChoices = [
  { label: InvitePriorityUIChoices.regularPlayer, value: InvitePriorityDBChoices.regularPlayer},
  { label: InvitePriorityUIChoices.normalPlayer, value: InvitePriorityDBChoices.normalPlayer},
];
