import {Buffer} from 'buffer';
import 'crypto-js/lib-typedarrays';
import SHA256 from 'crypto-js/sha256';
import CryptoJS from 'crypto-js/core';
import Base64 from 'crypto-js/enc-base64';
import HmacSHA256 from 'crypto-js/hmac-sha256';

const randomBytes = function (nBytes) {
  return Buffer.from(CryptoJS.lib.WordArray.random(nBytes).toString(), 'hex');
};

import BigInteger from './BigInteger';

const initN =
  'FFFFFFFFFFFFFFFFC90FDAA22168C234C4C6628B80DC1CD1' +
  '29024E088A67CC74020BBEA63B139B22514A08798E3404DD' +
  'EF9519B3CD3A431B302B0A6DF25F14374FE1356D6D51C245' +
  'E485B576625E7EC6F44C42E9A637ED6B0BFF5CB6F406B7ED' +
  'EE386BFB5A899FA5AE9F24117C4B1FE649286651ECE45B3D' +
  'C2007CB8A163BF0598DA48361C55D39A69163FA8FD24CF5F' +
  '83655D23DCA3AD961C62F356208552BB9ED529077096966D' +
  '670C354E4ABC9804F1746C08CA18217C32905E462E36CE3B' +
  'E39E772C180E86039B2783A2EC07A28FB5C55DF06F4C52C9' +
  'DE2BCBF6955817183995497CEA956AE515D2261898FA0510' +
  '15728E5A8AAAC42DAD33170D04507A33A85521ABDF1CBA64' +
  'ECFB850458DBEF0A8AEA71575D060C7DB3970F85A6E1E4C7' +
  'ABF5AE8CDB0933D71E8C94E04A25619DCEE3D2261AD2EE6B' +
  'F12FFA06D98A0864D87602733EC86A64521F2B18177B200C' +
  'BBE117577A615D6C770988C0BAD946E208E24FA074E5AB31' +
  '43DB5BFCE0FD108E4B82D120A93AD2CAFFFFFFFFFFFFFFFF';

const newPasswordRequiredChallengeUserAttributePrefix = 'userAttributes.';


export default class SRPMaths {
  constructor(PoolName) {
    this.N = new BigInteger(initN, 16);
    this.g = new BigInteger('2', 16);
    this.k = new BigInteger(
      this.hexHash(`00${this.N.toString(16)}0${this.g.toString(16)}`),
      16
    );
    this.smallAValue = this.generateRandomSmallA();
    this.getLargeAValue(() => {
    });
    this.infoBits = Buffer.from('Caldera Derived Key', 'utf8');
    this.poolName = PoolName;
  }

  getSmallAValue() {
    return this.smallAValue;
  }

  getLargeAValue(callback) {
    if (this.largeAValue) {
      callback(null, this.largeAValue);
    } else {
      this.calculateA(this.smallAValue, (err, largeAValue) => {
        if (err) {
          callback(err, null);
        }

        this.largeAValue = largeAValue;
        callback(null, this.largeAValue);
      });
    }
  }

  generateRandomSmallA() {
    const hexRandom = randomBytes(128).toString('hex');

    const randomBigInt = new BigInteger(hexRandom, 16);
    const smallABigInt = randomBigInt.mod(this.N);

    return smallABigInt;
  }

  generateRandomString() {
    return randomBytes(40).toString('base64');
  }

  getRandomPassword() {
    return this.randomPassword;
  }

  getSaltDevices() {
    return this.SaltToHashDevices;
  }

  getVerifierDevices() {
    return this.verifierDevices;
  }

  generateHashDevice(deviceGroupKey, username, callback) {
    this.randomPassword = this.generateRandomString();
    const combinedString = `${deviceGroupKey}${username}:${this.randomPassword}`;
    const hashedString = this.hash(combinedString);

    const hexRandom = randomBytes(16).toString('hex');
    this.SaltToHashDevices = this.padHex(new BigInteger(hexRandom, 16));

    this.g.modPow(
      new BigInteger(this.hexHash(this.SaltToHashDevices + hashedString), 16),
      this.N,
      (err, verifierDevicesNotPadded) => {
        if (err) {
          callback(err, null);
        }

        this.verifierDevices = this.padHex(verifierDevicesNotPadded);
        callback(null, null);
      }
    );
  }

  calculateA(a, callback) {
    this.g.modPow(a, this.N, (err, A) => {
      if (err) {
        callback(err, null);
      }

      if (A.mod(this.N).equals(BigInteger.ZERO)) {
        callback(new Error('Illegal paramater. A mod N cannot be 0.'), null);
      }

      callback(null, A);
    });
  }

  calculateU(A, B) {
    this.UHexHash = this.hexHash(this.padHex(A) + this.padHex(B));
    const finalU = new BigInteger(this.UHexHash, 16);

    return finalU;
  }

  hash(buf) {
    const str =
      buf instanceof Buffer ? CryptoJS.lib.WordArray.create(buf) : buf;
    const hashHex = SHA256(str).toString();

    return new Array(64 - hashHex.length).join('0') + hashHex;
  }

  hexHash(hexStr) {
    return this.hash(Buffer.from(hexStr, 'hex'));
  }

  computehkdf(ikm, salt) {
    const infoBitsWordArray = CryptoJS.lib.WordArray.create(
      Buffer.concat([
        this.infoBits,
        Buffer.from(String.fromCharCode(1), 'utf8'),
      ])
    );
    const ikmWordArray =
      ikm instanceof Buffer ? CryptoJS.lib.WordArray.create(ikm) : ikm;
    const saltWordArray =
      salt instanceof Buffer ? CryptoJS.lib.WordArray.create(salt) : salt;

    const prk = HmacSHA256(ikmWordArray, saltWordArray);
    const hmac = HmacSHA256(infoBitsWordArray, prk);
    return Buffer.from(hmac.toString(), 'hex').slice(0, 16);
  }

  getPasswordAuthenticationKey(
    username,
    password,
    serverBValue,
    salt,
    callback
  ) {
    if (password == null) {
      password = 'hash$$'
    }
    if (serverBValue.mod(this.N).equals(BigInteger.ZERO)) {
      throw new Error('B cannot be zero.');
    }

    this.UValue = this.calculateU(this.largeAValue, serverBValue);

    if (this.UValue.equals(BigInteger.ZERO)) {
      throw new Error('U cannot be zero.');
    }

    const usernamePassword = `${this.poolName}${username}:${password}`;
    const usernamePasswordHash = this.hash(usernamePassword);

    const xValue = new BigInteger(
      this.hexHash(this.padHex(salt) + usernamePasswordHash),
      16
    );
    this.calculateS(xValue, serverBValue, (err, sValue) => {
      if (err) {
        callback(err, null);
      }

      const hkdf = this.computehkdf(
        Buffer.from(this.padHex(sValue), 'hex'),
        Buffer.from(this.padHex(this.UValue.toString(16)), 'hex')
      );

      callback(null, hkdf);
    });
  }

  calculateS(xValue, serverBValue, callback) {
    this.g.modPow(xValue, this.N, (err, gModPowXN) => {
      if (err) {
        callback(err, null);
      }

      const intValue2 = serverBValue.subtract(this.k.multiply(gModPowXN));
      intValue2.modPow(
        this.smallAValue.add(this.UValue.multiply(xValue)),
        this.N,
        (err2, result) => {
          if (err2) {
            callback(err2, null);
          }

          callback(null, result.mod(this.N));
        }
      );
    });
  }

  getNewPasswordRequiredChallengeUserAttributePrefix() {
    return newPasswordRequiredChallengeUserAttributePrefix;
  }

  padHex(bigInt) {
    let hashStr = bigInt.toString(16);
    if (hashStr.length % 2 === 1) {
      hashStr = `0${hashStr}`;
    } else if ('89ABCDEFabcdef'.indexOf(hashStr[0]) !== -1) {
      hashStr = `00${hashStr}`;
    }
    return hashStr;
  }


  getSRPA() {
    return this.largeAValue.toString(16);
  }

  generateChallengeResponsesFromSRPA(hkdf, userPoolId, username, challengeParameters, deviceKey) {
    const dateNow = this.getNowString();
    const message = CryptoJS.lib.WordArray.create(
      Buffer.concat([
        Buffer.from(userPoolId.split('_')[1], 'utf8'),
        Buffer.from(username, 'utf8'),
        Buffer.from(challengeParameters.SECRET_BLOCK, 'base64'),
        Buffer.from(dateNow, 'utf8'),
      ])
    );
    const key = CryptoJS.lib.WordArray.create(hkdf);
    const signatureString = Base64.stringify(HmacSHA256(message, key));
    const challengeResponses = {};

    challengeResponses.USERNAME = username;
    challengeResponses.PASSWORD_CLAIM_SECRET_BLOCK = challengeParameters.SECRET_BLOCK;
    challengeResponses.TIMESTAMP = dateNow;
    challengeResponses.PASSWORD_CLAIM_SIGNATURE = signatureString;

    if (deviceKey != null) {
      challengeResponses.DEVICE_KEY = deviceKey;
    }
    return challengeResponses;
  }

  getNowString() {
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const weekNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const now = new Date();

    const weekDay = weekNames[now.getUTCDay()];
    const month = monthNames[now.getUTCMonth()];
    const day = now.getUTCDate();

    let hours = now.getUTCHours();
    if (hours < 10) {
      hours = `0${hours}`;
    }

    let minutes = now.getUTCMinutes();
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }

    let seconds = now.getUTCSeconds();
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }

    const year = now.getUTCFullYear();

    // ddd MMM D HH:mm:ss UTC YYYY
    const dateNow = `${weekDay} ${month} ${day} ${hours}:${minutes}:${seconds} UTC ${year}`;

    return dateNow;
  }
}
