import { deserialize } from 'cerialize';

export class PricingModel {
  @deserialize id: number;
  @deserialize name: string;

  /* Auto generated constructor from IDE */
  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}
