import {
  autoserialize,
  serialize,
  deserialize,
  deserializeAs,
  autoserializeAs, serializeAs,
} from 'cerialize';

import {FMLocation} from '../../../location/models/location.model';
import {OpenDataSite} from '../../../location/models/open-data-site.model';
import {PricingModel} from '../../../payments/models/database/pricing.model';
import {KickaroundGame} from './kickaround-game.model';
import {KickaroundPlayer} from './kickaround-player.model';
import {GroupVisibilityDBChoices} from '../../enums/group-visibility-types.enum';
import {ConfirmationModeChoices, PaymentProcessors, WaitingListModeChoices} from '../../kickarounds.enums';

export class Kickaround {

  // Read Only
  @deserialize version?: number;
  @deserialize adminToken?: string;
  @deserialize createdBy?: number;
  @deserialize firstGameScheduled?: boolean;
  @deserialize latestRelativeGameId?: number;
  @deserialize gameHost?: number;
  @deserialize url?: string;
  @deserialize finalPerPlayerPriceMin?: number;
  @deserialize finalPerPlayerPriceMax?: number;
  @deserializeAs(Date) dateTimeSync?: Date;
  @deserialize latestGameId?: number;
  @deserialize openDataSiteId?: number;
  @deserialize pricingModel_id?: number;
  @deserialize pricingModelId?: number;
  @deserializeAs(KickaroundGame) latestGame?: KickaroundGame;
  @deserializeAs(KickaroundPlayer) players?: KickaroundPlayer[];
  @deserializeAs(OpenDataSite) openDataSite?: OpenDataSite;
  @deserializeAs(PricingModel) pricingModel?: PricingModel;
  @deserialize location_id?: number;
  @deserialize token?: string;
  @deserialize gofmLink?: string;
  @deserialize gofmGameConfirmLink?: string;
  @deserialize prePaidRemainingGames?: number;
  @deserialize prePaid?: boolean;
  @deserialize paymentProcessor?: PaymentProcessors;

  // Write only
  @serialize feeOverrideQuoteId?: string;
  @serializeAs(Date) startDateTime?: Date | string;
  @serializeAs(FMLocation) newLocation?: FMLocation;
  @serialize organiserFirstName?: string;
  @serialize organiserSurName?: string;
  @serialize organiserEmail?: string;
  @serialize affiliateToken?: string;
  @serialize campaignToken?: string;
  @serialize organiserMobileNumber?: string;
  @serialize nextGameDate?: Date | string;

  // Read and write
  @autoserialize sportsType?: string;
  @autoserialize groupType?: string;
  @autoserialize startTime?: string;
  @autoserialize dayOfWeek?: string;
  @autoserialize pricingModelCoupon?: string;
  @autoserialize id?: number;
  @autoserialize name: string;
  @autoserialize price: number;
  @autoserialize currency: string;
  @autoserialize locationId?: number;
  @autoserializeAs (FMLocation) location?: FMLocation;
  @autoserialize openDataSite_id?: number;
  @autoserialize duration: number;
  @autoserialize minRequiredPlayers: number;
  @autoserialize maxRequiredPlayers: number;
  @autoserialize minPlayerAge: number;
  @autoserialize maxPlayerAge: number;
  @autoserialize pitchType: string;
  @autoserialize pitchPlayersPerSide: number;
  @autoserialize invitePreGameSendDays: number;
  @autoserialize noRefundPeriodBeforeGameHours?: number;
  @autoserialize inviteSendTime: string;
  @autoserialize fixedPlayerPrice: number;
  @autoserialize playerPricingMode: string;
  @autoserialize paymentMode: string;
  @autoserialize frequency: string;
  @autoserialize level: string;
  @autoserialize gender: string;
  @autoserialize status: string;
  @autoserialize whatsAppGroupStatus: string;
  @autoserialize whatsAppUrl: string;
  @autoserialize requireMobileAndFullName?: boolean;
  @autoserialize visibilityMode: GroupVisibilityDBChoices;
  @autoserialize bannerUrl?: string;
  @autoserialize logoUrl?: string;
  @autoserialize badgeUrl?: string;
  @autoserializeAs(ConfirmationModeChoices) confirmGameMode?: ConfirmationModeChoices;
  @autoserialize autoConfirmBeforeGameDelayHours?: number;
  @autoserializeAs(WaitingListModeChoices) waitingListMode?: WaitingListModeChoices;
  @autoserialize waitingLeadTimeMins?: number;
  @autoserialize waitingListPay2PlayExpiryMins?: number;
  @autoserialize waitingListBeforeGameClearHours?: number;
  @autoserialize priorityInviteExpiryHours?: number;
  @autoserialize pay2PlayExpiryMins?: number;
  @autoserialize neverIssueRefund?: boolean;
}
