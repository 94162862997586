import {Inject, Injectable, OnInit} from '@angular/core';

import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService implements OnInit {
  constructor(@Inject(LOCAL_STORAGE) private localStorage: StorageService) {
  }

  ngOnInit(): void {
  }

  deleteRefreshToken() {
    this.localStorage.remove('KEY_REFRESH_TOKEN');
    this.localStorage.remove('KEY_REFRESH_USER');
  }

  saveRefreshToken(refreshToken: string) {
    this.localStorage.set('KEY_REFRESH_TOKEN', refreshToken);
  }

  getRefreshToken(): string {
    return this.localStorage.get('KEY_REFRESH_TOKEN');

  }

  hasRefreshToken(): boolean {
    return this.localStorage.get('KEY_REFRESH_TOKEN') != null;
  }

  getValueForKey(key: string): string {
    return this.localStorage.get(key);
  }

  removeValueForKey(key: string): void {
    this.localStorage.remove(key);
  }

  saveValueForKey(key: string, value: string): void {
    this.localStorage.set(key, value);
  }
}
