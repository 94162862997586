import {AcceptButtonTextChoices, GamePlayerStatusChoices, GameStatusDisplayChoices} from '../../kickarounds.enums';
import {KickaroundGamePlayer} from '../database/kickaround-game-player.model';

export class GameUIModel {
  id: number;

  // Game Specific
  isLoading = false;
  gameUrl: string;
  gameInPast: boolean;
  startTime: Date;
  startDate: Date;
  inviteSendDatetime: Date;
  acceptButtonText: AcceptButtonTextChoices | string;
  gameStatus: GameStatusDisplayChoices | string;
  gameInvitesActive: boolean;
  numOfGamePlayers: number;
  numOfGamePlayersAccepted: number;
  numOfGamePlayersWaiting: number;
  numOfGamePlayersNeedingReplacement: number;
  numOfSpotsRemaining: number;
  numOfGamePlayersConfirmed: number;

  // Player specific
  isUserGoing: boolean;
  isUserOrganiser: boolean;
  isUserGameHost: boolean;
  gameHostId: number;
  isPayBeforePlay: boolean;
  hasUserPrepaid: boolean;
  isUserEligibleForPayments: boolean;
  isUserOnWaitingList: boolean;
  isUserOffWaitingList: boolean;
  userGamePlayer: KickaroundGamePlayer;
  userGamePlayerStatus: GamePlayerStatusChoices | string;

  // UI Specific
  showPendingReplacementStateButtons: boolean;
  showPendingRefundStateButtons: boolean;
  showResponseButtons: boolean;
  enableAcceptButton: boolean;
  paidOnline: boolean;
  showAcceptDeclineButtons: boolean;
  showPaymentButtons: boolean;
  showPrepaidConfirmReleaseButtons: boolean;
  showPrepaidDropoutButton: boolean;
  showSendInvitesButton: boolean;
  showConfirmGameButton: boolean;
  showCancellationButton: boolean;
}
