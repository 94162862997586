import {deserialize, deserializeAs} from 'cerialize';

export class Payment {
  @deserialize id: number;
  @deserialize eventType: string;
  @deserialize eventId: number;
  @deserialize groupType: string;
  @deserialize groupId: number;
  @deserialize payeeType: string;
  @deserialize payeeId: number;
  @deserialize payeeEmail: string;
  @deserialize beneficiaryType: string;
  @deserialize beneficiaryId: number;
  @deserialize beneficiaryEmail: string;
  @deserialize eventJson: any;
  @deserializeAs(Date) recordInsertedOn: Date;
  @deserialize wpRef: string;
  @deserialize wpCardTokenHash: string;
  @deserialize wpCardTokenType: string;
  @deserialize wpCardDetailHash: string;
  @deserialize wpCardType: string;
  @deserialize wpOrderDescription: string;
  @deserialize wpCustomerOrderCode: string;
  @deserialize wpAuthAmount: number;
  @deserialize wpAuthCurrency: string;
  @deserialize wpAuthStatus: string;
  @deserializeAs(Date) wpAuthStatusUpdatedOn: Date;
  @deserializeAs(Date)  wpAutoCaptureDatetime: Date;
  @deserialize wpCaptureAmount: number;
  @deserialize wpCaptureCurrency: string;
  @deserialize wpCaptureStatus: string;
  @deserializeAs(Date) wpCaptureStatusUpdatedOn: Date;
  @deserialize wpBatchNo: number;
  @deserialize twPayoutSourceAccountId: string;
  @deserialize twPayoutSourceBalance: number;
  @deserialize twPayoutDestinationAccountId: string;
  @deserialize twPayoutAmount: number;
  @deserialize twPayoutCurrency: string;
  @deserialize twTransferId: string;
  @deserialize twPayoutStatus: string;
  @deserialize twPayoutStatusUpdatedOn: string;
  @deserializeAs(Date) estimatedPayoutDatetime: Date;

  /* Auto generated constructor from IDE */
  constructor(id: number, eventType: string, eventId: number, groupType: string, groupId: number, payeeType: string, payeeId: number, payeeEmail: string, beneficiaryType: string, beneficiaryId: number, beneficiaryEmail: string, eventJson: any, recordInsertedOn: Date, wpRef: string, wpCardTokenHash: string, wpCardTokenType: string, wpCardDetailHash: string, wpCardType: string, wpOrderDescription: string, wpCustomerOrderCode: string, wpAuthAmount: number, wpAuthCurrency: string, wpAuthStatus: string, wpAuthStatusUpdatedOn: Date, wpAutoCaptureDatetime: Date, wpCaptureAmount: number, wpCaptureCurrency: string, wpCaptureStatus: string, wpCaptureStatusUpdatedOn: Date, wpBatchNo: number, twPayoutSourceAccountId: string, twPayoutSourceBalance: number, twPayoutDestinationAccountId: string, twPayoutAmount: number, twPayoutCurrency: string, twTransferId: string, twPayoutStatus: string, twPayoutStatusUpdatedOn: string, estimatedPayoutDatetime: Date) {
    this.id = id;
    this.eventType = eventType;
    this.eventId = eventId;
    this.groupType = groupType;
    this.groupId = groupId;
    this.payeeType = payeeType;
    this.payeeId = payeeId;
    this.payeeEmail = payeeEmail;
    this.beneficiaryType = beneficiaryType;
    this.beneficiaryId = beneficiaryId;
    this.beneficiaryEmail = beneficiaryEmail;
    this.eventJson = eventJson;
    this.recordInsertedOn = recordInsertedOn;
    this.wpRef = wpRef;
    this.wpCardTokenHash = wpCardTokenHash;
    this.wpCardTokenType = wpCardTokenType;
    this.wpCardDetailHash = wpCardDetailHash;
    this.wpCardType = wpCardType;
    this.wpOrderDescription = wpOrderDescription;
    this.wpCustomerOrderCode = wpCustomerOrderCode;
    this.wpAuthAmount = wpAuthAmount;
    this.wpAuthCurrency = wpAuthCurrency;
    this.wpAuthStatus = wpAuthStatus;
    this.wpAuthStatusUpdatedOn = wpAuthStatusUpdatedOn;
    this.wpAutoCaptureDatetime = wpAutoCaptureDatetime;
    this.wpCaptureAmount = wpCaptureAmount;
    this.wpCaptureCurrency = wpCaptureCurrency;
    this.wpCaptureStatus = wpCaptureStatus;
    this.wpCaptureStatusUpdatedOn = wpCaptureStatusUpdatedOn;
    this.wpBatchNo = wpBatchNo;
    this.twPayoutSourceAccountId = twPayoutSourceAccountId;
    this.twPayoutSourceBalance = twPayoutSourceBalance;
    this.twPayoutDestinationAccountId = twPayoutDestinationAccountId;
    this.twPayoutAmount = twPayoutAmount;
    this.twPayoutCurrency = twPayoutCurrency;
    this.twTransferId = twTransferId;
    this.twPayoutStatus = twPayoutStatus;
    this.twPayoutStatusUpdatedOn = twPayoutStatusUpdatedOn;
    this.estimatedPayoutDatetime = estimatedPayoutDatetime;
  }
}
