import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';

import {Deserialize, Serialize} from 'cerialize';
import {catchError, map } from 'rxjs/operators';

import {environment} from '../../../../environments/environment';
import {LoginService} from '../../user/services/login.service';
import {ErrorService} from '../../../routes/public/error/error-service';
import {Kickaround} from '../models/database/kickaround.model';
import {KickaroundGame} from '../models/database/kickaround-game.model';
import {FMLocation} from '../../location/models/location.model';
import {KickaroundUserData} from '../models/endpoints/kickaround-user-data.model';
import {AcceptDeclineGameResponse, KickaroundStatusActionChoices} from '../kickarounds.enums';
import {KickaroundPlayer} from '../models/database/kickaround-player.model';
import {ConfirmDeclineGameInvitationData} from '../models/endpoints/confirm-decline-game.model';
import {KICKAROUND_USER_DATA} from './data/dummy-kickaround-data';
import {Observable, of} from 'rxjs';
import {KickaroundExtraData} from "../models/database/kickaround-data.model";
import {FMAnalyticsService} from "../../analytics/fm-analytics.service";
import {
  ORGANISER_ACTIONS_EDIT_GROUP,
  ORGANISER_ACTIONS_REMOVE_PLAYER,
  ORGANISER_ACTIONS_SEND_MESSAGE_TO_ONE,
  ORGANISER_ACTIONS_UPDATE_DESCRIPTION, PLAYER_ACTIONS_RSVP_CHECK_NUMBERS, PLAYER_ACTIONS_RSVP_CHECK_NUMBERS_APP,
  PLAYER_ACTIONS_RSVP_CHECK_NUMBERS_VIA_EMAIL,
  PLAYER_ACTIONS_RSVP_NO, PLAYER_ACTIONS_RSVP_NO_VIA_APP,
  PLAYER_ACTIONS_RSVP_NO_VIA_EMAIL,
  PLAYER_ACTIONS_RSVP_YES, PLAYER_ACTIONS_RSVP_YES_VIA_APP,
  PLAYER_ACTIONS_RSVP_YES_VIA_EMAIL, PLAYER_ACTIONS_UNSUBSCRIBE
} from "../../analytics/mixpanel.constants";

const UK_LOCALE = 'en-GB'

@Injectable({
  providedIn: 'root'
})
export class KickaroundsService {
  public apiUrl;
  public baseUrl;

  constructor(private http: HttpClient,
              private router: Router,
              private errorService: ErrorService,
              private loginService: LoginService,
              private fmas: FMAnalyticsService,
              ) {
    this.apiUrl = environment.api;
    this.baseUrl = environment.baseUrl;
  }

  genFullEndpointUrl(endpointUrl, hasSecureEndpoint?) {
    if (this.loginService.isLoggedOn && hasSecureEndpoint) {
      return `${this.apiUrl}/secure` + endpointUrl;
    } else {
      return `${this.apiUrl}/public` + endpointUrl;
    }
  }

  /* Get all kickaourn data assoicated with user */
  getLatestKickaroundUserData(): Observable<KickaroundUserData> {
    const endpoint = `/secure/kickarounds/userData`;
    if (environment.mockUserData) {
      return of(Deserialize(KICKAROUND_USER_DATA, KickaroundUserData));
    }
    return this.http.get(this.apiUrl + endpoint).pipe(catchError((error) => {
        const errorMessage = 'Could not load kickarounds';
        this.errorService.handleAndRouteAfterError(this.router, errorMessage);
        throw error;
      }),
      map((data) => {
        return Deserialize(data, KickaroundUserData);
      })
    );
  }

  getKickaroundData(kickaroundId: number): Observable<KickaroundExtraData> {
    const endpoint = `/kickarounds/${kickaroundId}/data`;
    return this.http.get(this.genFullEndpointUrl(endpoint, true));
  }

  postKickaroundData(kickaroundId: number, fieldName: 'description' | 'rules', value: string): Observable<KickaroundExtraData> {
    const endpoint = `/kickarounds/${kickaroundId}/data`;
    // this.fmas.recordV2Event(ORGANISER_ACTIONS_UPDATE_DESCRIPTION);
    return this.http.post(this.genFullEndpointUrl(endpoint, true), {[fieldName]: value}).pipe(catchError((error) => {
        const errorMessage = 'Error updating group info.';
        this.errorService.handleAndRouteAfterError(this.router, errorMessage);
        throw error;
      }),
    );
  }


  getKickaround(kickaroundId: number, token?: string, confirmToken?: string): Observable<Kickaround> {
    let params = {};
    if (token) {
        params = new HttpParams().set('token', token);
    }
    if (confirmToken) {
        params = new HttpParams().set('confirmToken', confirmToken);
    }
    const endpoint = `/kickarounds/${kickaroundId}`;
    return this.http.get(this.genFullEndpointUrl(endpoint, true), {params: params}).pipe(catchError((error) => {
        const errorMessage = 'Could not load kickaround, please check you are subscribed to this kickaround.';
        this.errorService.handleAndRouteAfterError(this.router, errorMessage);
        throw error;
      }),
      map((kickaround) => {
        const ka: Kickaround = Deserialize(kickaround, Kickaround);
        return Deserialize(kickaround, Kickaround);
      })
    );
  }

  createKickaround(newKickaround): Observable<Kickaround> {
    const endpoint = `/secure/kickarounds/`;
    return this.http.post<Kickaround>(this.apiUrl + endpoint, Serialize(newKickaround, Kickaround)).pipe(
      map((kickaround) => {
        const ka: Kickaround = Deserialize(kickaround, Kickaround);
        return Deserialize(kickaround, Kickaround);
      }));
  }

  createPendingKickaround(newKickaround): Observable<Kickaround> {
    const endpoint = `/public/kickarounds/pending/`;
    return this.http.post<Kickaround>(this.apiUrl + endpoint, Serialize(newKickaround, Kickaround)).pipe(
      map((kickaround) => {
        const ka: Kickaround = Deserialize(kickaround, Kickaround);
        return Deserialize(kickaround, Kickaround);
      }));
  }

 sendGamePlayerListToWhatsApp(gameId) {
    const endpoint = `/secure/whatsapp/sendEventPlayerList`;
    const data = {
      'eventId': gameId,
      'eventType': 'KG'
    };
    return this.http.post(this.apiUrl + endpoint, data);
  }

  updateKickaround(ka: Kickaround, applyEditToGame): Observable<any> {
    const endpoint = `/secure/kickarounds/${ka.id}`;
    const payload = Serialize(ka, Kickaround);
    const params = {applyEditToGame: applyEditToGame};
    // this.fmas.recordV2Event(ORGANISER_ACTIONS_EDIT_GROUP);
    return this.http.put(this.apiUrl + endpoint, payload, {params: params}).pipe(
      map((kickaround) => {
        const updatedKa: Kickaround = Deserialize(kickaround, Kickaround);
        return Deserialize(updatedKa, Kickaround);
      }));
  }

  updateKickaroundStatus(kickaroundId: number, status: KickaroundStatusActionChoices, reason: string, startDateTime: string): Observable<any> {
    const endpoint = `/secure/kickarounds/${kickaroundId}/updateStatus`;
    const body = {status: status, reason: reason, startDateTime: startDateTime};
    return this.http.post(this.apiUrl + endpoint, body);
  }

  deleteKickaround(kickaroundId: number): Observable<any> {
    const endpoint = `/secure/kickarounds/${kickaroundId}`;
    return this.http.delete(this.apiUrl + endpoint);
  }

  getKickaroundGame(kickaroundId: number, kickaroundGameId: number, apimode: string, playerId: number, token?: string): Observable<KickaroundGame> {
    const endpoint = `/${apimode}/kickarounds/${kickaroundId}/games/${kickaroundGameId}/`;
    if (token && playerId) {
      const params = new HttpParams()
        .set('token', token)
        .set('playerId', `${playerId}`);
      return this.http.get(this.apiUrl + endpoint, {params: params}).pipe(catchError((error) => {
          const errorMessage = 'Could not load kickaround latestGame, please check you are subscribed to this kickaround.';
          this.errorService.handleAndRouteAfterError(this.router, errorMessage);
          throw error;
        }),
        map((games) => {
          // #fixme this endpoint shouldn't return a list of games
          const kag: KickaroundGame = Deserialize((Array.isArray(games)) ? games[0] : games, KickaroundGame);
          return kag;
        })
      );
    } else {
      return this.http.get(this.apiUrl + endpoint).pipe(catchError((error) => {
          const errorMessage = 'Could not load kickaround latestGame, please check you are subscribed to this kickaround.';
          this.errorService.handleAndRouteAfterError(this.router, errorMessage);
          throw error;
        }),
        map((games) => {
          // #fixme this endpoint shouldn't return a list of games
          const kag: KickaroundGame = Deserialize((Array.isArray(games)) ? games[0] : games, KickaroundGame);
          return kag;
        })
      );
    }
  }

  createNextGame(kickaroundId: number, nextGameDate?): Observable<KickaroundGame> {
    const endpoint = `/secure/kickarounds/${kickaroundId}/createNextGame`;
    let data = {};
    if (nextGameDate !== undefined) {
      data = {nextGameDate: nextGameDate.toLocaleDateString(UK_LOCALE) + ' ' + nextGameDate.toLocaleTimeString()};
    }
    return this.http.post<KickaroundGame>(this.apiUrl + endpoint, data).pipe(
      map((games) => {
        return Deserialize(games, KickaroundGame);
      })
    );
  }

  confirmGame(kickaroundId: number): Observable<any> {
    const endpoint = `/secure/kickarounds/${kickaroundId}/updateLatestGameStatus/confirm`;
    return this.http.post(this.apiUrl + endpoint, {});
  }

  cancelGame(kickaroundId: number, reason?: String): Observable<any> {
    const data = {'reason': reason || 'Not enough players'};
    const endpoint = `/secure/kickarounds/${kickaroundId}/updateLatestGameStatus/cancel`;
    return this.http.post(this.apiUrl + endpoint, data);
  }

  sendInvites(kickaroundId: number): Observable<any> {
    const endpoint = `/secure/kickarounds/${kickaroundId}/sendInvites`;
    return this.http.post(this.apiUrl + endpoint, {});
  }

  confirmDeclineLatestGameInvite(kickaroundId: number, gameId: number, action: AcceptDeclineGameResponse, gofm?: string): Observable<ConfirmDeclineGameInvitationData> {
    const endpoint = `/secure/kickarounds/${kickaroundId}/${action}/games/${gameId}`;
    if (action == AcceptDeclineGameResponse.confirm) {
      // this.fmas.recordV2Event(PLAYER_ACTIONS_RSVP_YES);
      // this.fmas.recordV2Event(PLAYER_ACTIONS_RSVP_YES_VIA_APP);
    } else if (action == AcceptDeclineGameResponse.decline) {
      // this.fmas.recordV2Event(PLAYER_ACTIONS_RSVP_NO);
      // this.fmas.recordV2Event(PLAYER_ACTIONS_RSVP_NO_VIA_APP);
    } else if (action == AcceptDeclineGameResponse.lookup) {
      // this.fmas.recordV2Event(PLAYER_ACTIONS_RSVP_CHECK_NUMBERS);
      // this.fmas.recordV2Event(PLAYER_ACTIONS_RSVP_CHECK_NUMBERS_APP);
    } else {
      // this.fmas.recordV2Event(PLAYER_ACTIONS_UNSUBSCRIBE);
    }
    const params = { action: action, ...(gofm) ? {gofm: gofm} : {} };
    return this.http.post(this.apiUrl + endpoint, {}, { params : params }).pipe(
      map((data) => {
        return Deserialize(data, ConfirmDeclineGameInvitationData);
      })
    );
  }

  unsubscribeKickaround(kickaroundId: number, playerId: number, token: string): Observable<any> {
    const params = new HttpParams().set('token', token);
    const endpoint = `/public/kickarounds/${kickaroundId}/unsubscribe/players/${playerId}/`;
    const data = {};
    // this.fmas.recordV2Event(PLAYER_ACTIONS_UNSUBSCRIBE);
    return this.http.post(this.apiUrl + endpoint, data, {params: params});
  }

  unsubscribePlayerMatcherGroup(playermatcherGroupId: number, playerId: number, token: string): Observable<any> {
    const params = new HttpParams().set('token', token);
    const endpoint = `/public/playermatcher/groups/${playermatcherGroupId}/unsubscribe/player/${playerId}/`;
    const data = {};
    return this.http.post(this.apiUrl + endpoint, data, {params: params});
  }

  publicConfirmDeclineKickaroundGameInvite(kickaroundId: number, kickaroundGameId: number, playerId: number, token: string, action: AcceptDeclineGameResponse): Observable<any> {
    const params = new HttpParams().set('token', token);

    if (action == AcceptDeclineGameResponse.confirm) {
      // this.fmas.recordV2Event(PLAYER_ACTIONS_RSVP_YES);
      // this.fmas.recordV2Event(PLAYER_ACTIONS_RSVP_YES_VIA_EMAIL);
    } else if (action == AcceptDeclineGameResponse.decline) {
      // this.fmas.recordV2Event(PLAYER_ACTIONS_RSVP_NO);
      // this.fmas.recordV2Event(PLAYER_ACTIONS_RSVP_NO_VIA_EMAIL);
    } else if (action == AcceptDeclineGameResponse.lookup) {
      // this.fmas.recordV2Event(PLAYER_ACTIONS_RSVP_CHECK_NUMBERS);
      // this.fmas.recordV2Event(PLAYER_ACTIONS_RSVP_CHECK_NUMBERS_VIA_EMAIL);
    } else {
      // this.fmas.recordV2Event(PLAYER_ACTIONS_UNSUBSCRIBE);
    }

    const endpoint = `/public/kickarounds/${kickaroundId}/${action}/games/${kickaroundGameId}/players/${playerId}`;
    const data = {};
    return this.http.post(this.apiUrl + endpoint, data, {params: params});
  }

  getPlayersForKickaroundGame(kickaroundId: number, kickaroundGameId: number): Observable<any> {
    const endpoint = `/public/kickarounds/${kickaroundId}/games/${kickaroundGameId}/players`;
    const data = {};
    return this.http.get(this.apiUrl + endpoint);
  }

  /* MISC Services - #todo need to move to separate services*/
  createLocation(newLocation): Observable<FMLocation> {
    const endpoint = `/secure/kickarounds/locations`;
    return this.http.post<FMLocation>(this.apiUrl + endpoint, Serialize(newLocation, FMLocation));
  }

  searchOpenDataSites(searchTerm: number): Observable<any> {
    const endpoint = `/public/sites?search=${searchTerm}`;
    return this.http.get(this.apiUrl + endpoint);
  }

  addFeedback(fullName: string, email: string, type: string, feedbackText: string): Observable<any> {
    const endpoint = `/public/feedback`;
    const data = {
      'fullName': fullName,
      'email': email,
      'type': type,
      'feedbackText': feedbackText,
    };
    return this.http.post(this.apiUrl + endpoint, data);
  }

  activateKickaround(kickaroundId): Observable<any> {
    const params = new HttpParams().set('kickaroundId', kickaroundId);
    const endpoint = `/secure/kickarounds/activate`;
    return this.http.post(this.apiUrl + endpoint, {}, {params: params});
  }

  sendMessage(kickaroundId: number, message: string, toJustConfirmed: boolean): Observable<any> {
    const endpoint = `/secure/kickarounds/${kickaroundId}/sendMessage/`;
    const data = {'message': message, 'toJustConfirmed': toJustConfirmed};
    return this.http.post(this.apiUrl + endpoint, data);
  }

  updateDisplayName(kickaroundId: number, kickaroundPlayerId: number, displayName: string, onBehalfOfDisplayName): Observable<any> {
    const endpoint = `/secure/kickarounds/${kickaroundId}/players/${kickaroundPlayerId}`;
    const data = {'displayName': displayName, 'onBehalfOfDisplayName': onBehalfOfDisplayName};
    return this.http.put(this.apiUrl + endpoint, data);
  }

  joinKickaround(kickaround: Kickaround, latestGame: KickaroundGame, token: string, email: string, name: string, affiliateToken: string, campaignToken: string ): Observable<any> {
    const params = new HttpParams().set('token', token);
    const endpoint = `/public/kickarounds/${kickaround.id}/join`;
    const data = {
      'name': name,
      'email': email,
      'affiliateToken': affiliateToken,
      'campaignToken': campaignToken
    };
    return this.http.post(this.apiUrl + endpoint, data, {params: params}).pipe(
      map(response => {
          let transformedResponse;
          if (response['result'] === 'PLAYER_JOINED_KICKAROUND') {
            transformedResponse = {
              'name': name,
              'message': this.generateJoinMessage(kickaround, latestGame),
              'hasJoined': true,
              'isError': false,
              'players': Deserialize(response['players'], KickaroundPlayer)
            };

          } else {
            const message = name + ' is already a member of this kickaround.';
            transformedResponse = {
              'name': name,
              'message': this.generateJoinMessage(kickaround, latestGame),
              'hasJoined': true,
              'isError': false,
              'players': Deserialize(response['players'], KickaroundPlayer)
            };
          }
          return transformedResponse;
        },
      (error) => {
          const message = 'Your Kickaround Identifer and/or Token are invalid';
          const transformedResponse = {
            'name': name,
            'message': message,
            'hasJoined': false,
            'isError': true
          };
      })
    );
  }

  joinKickaroundSecure(kickaroundId: number, token: string ): Observable<any> {
    const params = new HttpParams().set('token', token);
    const endpoint = `/secure/kickarounds/${kickaroundId}/join`;
    const data = {
    };
    return this.http.post(this.apiUrl + endpoint, data, {params: params}).pipe(
      map(response => {
          let transformedResponse;
          if (response['result'] === 'PLAYER_JOINED_KICKAROUND') {
            transformedResponse = {
              'name': name,
              'message': 'Joined successfully',
              'hasJoined': true,
              'isError': false,
              'players': Deserialize(response['players'], KickaroundPlayer)
            };

          } else {
            const message = name + ' is already a member of this kickaround.';
            transformedResponse = {
              'name': name,
              'message': message,
              'hasJoined': true,
              'isError': false,
              'players': Deserialize(response['players'], KickaroundPlayer)
            };
          }
          return transformedResponse;
        },
      (error) => {
          const message = 'Your Kickaround Identifer and/or Token are invalid';
          const transformedResponse = {
            'name': name,
            'message': message,
            'hasJoined': false,
            'isError': true
          };
      })
    );
  }

  generateJoinMessage(kickaround: Kickaround, game: KickaroundGame): string {
    let message;
    if (game) {
      if (game.status === 'PENDING') {
        const inviteSendDatetime = new Date(game.inviteSendDatetime).toLocaleString();
        message = `You have been successfully added to ${kickaround.name}.` +
        ` The next game invitation will be sent to your email at ${inviteSendDatetime}. (Check your spam folder)`;
      } else {
        message = `You have been successfully added to ${kickaround.name}.` +
      ` The next game invitation has been sent to your email. (Check your spam folder)`;
      }

    } else {
      message = `You have been successfully added to ${kickaround.name}.` +
      ` An invitation will be sent to your email a few days before the next game. (Check your spam folder)`;
    }
    return message;
  }

  basicUnsubscribe(kickaroundId: number): Observable<any> {
    const endpoint = `/secure/kickarounds/${kickaroundId}/unsubscribe`;
    return this.http.post(this.apiUrl + endpoint, {});
  }

  savePlayerAvailability(playerAvailability): Observable<any> {
    const endpoint = `/public/players/availability`;
    return this.http.post(this.apiUrl + endpoint, {playerAvailability});
  }

  sendGameParticipationDetailsTrackNTraceEmail(kickaroundId, gameId, optionalEmailAddress?: string): Observable<any> {
    const endpoint = `/secure/kickarounds/${kickaroundId}/games/${gameId}/sendGameAttendanceTrackNTraceEmail`;
    const data = (optionalEmailAddress) ? {'optionalEmailAddress': optionalEmailAddress} : {};
    return this.http.post(this.apiUrl + endpoint, data);
  }

  organiserAction(kickaroundId, gameId, playerId, action: 'declineInvite'): Observable<any> {
    const endpoint = `/secure/kickarounds/${kickaroundId}/games/${gameId}/players/${playerId}/organiserAction?action=${action}`;
    // this.fmas.recordV2Event(ORGANISER_ACTIONS_REMOVE_PLAYER);
    return this.http.post(this.apiUrl + endpoint, {})
  }

  sendSinglePlayerMessage(kickaroundId, gameId, playerId, messageHtml, subject, preview, action: 'sendMessage'): Observable<any> {
    // this.fmas.recordV2Event(ORGANISER_ACTIONS_SEND_MESSAGE_TO_ONE);
    const endpoint = `/secure/kickarounds/${kickaroundId}/games/${gameId}/players/${playerId}/organiserAction?action=${action}`;
    return this.http.post(this.apiUrl + endpoint, {
      messageHtml: messageHtml,
      preview: preview,
      subject: subject,
    })
  }

}
