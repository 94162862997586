import {Injectable} from '@angular/core';
import {Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';

import {LoginService} from '../services/login.service';
import {LocalStorageService} from '../services/local-storage.service';
import {Store} from '@ngxs/store';
import {RefreshTokenLogin, SaveAttemptedSecureUrl, SetGoFMToken} from '../state/user.actions';
import {
  EVENT_CATEGORY_USER,
  USER_NORMAL_AUTO_TOKEN_LOGIN,
  USER_NORMAL_RESET_PASSWORD_CONFIRM
} from '../../analytics/fm-analytics.constants';
import {FMAnalyticsService} from '../../analytics/fm-analytics.service';


@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(public loginService: LoginService,
              public localStorageService: LocalStorageService,
              public router: Router,
              public store: Store,
              private fmas: FMAnalyticsService,
  ) {
  }

  // recursively check children's params
  extractParamFrmChild(child, paramKey) {
    if (paramKey in child.params) {
      return child.params[paramKey];
    } else {
      if (child.children.length) {
        return this.extractParamFrmChild(child.children[0], paramKey);
      } else {
        return null;
      }
    }
  }

  extractParam(route, paramKey) {
    if (paramKey in route.params) {
      return route.params[paramKey];
    } else {
      if (route.children.length) {
        return this.extractParamFrmChild(route.children[0], paramKey);
      } else {
        return null;
      }
    }
  }

  rerouteBaseOnRequestedURL(url, route) {
    // Extract params
    const kickaroundId = this.extractParam(route, 'kickaroundId');
    const kickaroundGameId = this.extractParam(route, 'kickaroundGameId');
    const isLatestGame = kickaroundGameId === 'latest';

    // If default and not logged in, go to landing page
    if (url === '/secure/kickarounds/list') {
      this.router.navigate(['/landing']);

      // If latest game of kickaround was accessed
    } else if (kickaroundId && !kickaroundGameId && url.includes('latestGame')) {
      this.router.navigate(
        [
          'kickarounds',
          kickaroundId,
          'latestGame',
        ], {queryParams: route.queryParams}
      );
      // If kickaround game was accessed
    } else if (kickaroundId && kickaroundGameId) {

      // Reroute to game login
      this.router.navigate(
        [
          'kickarounds',
          kickaroundId,
          'games',
          kickaroundGameId,
          'login-to-confirm'
        ], {queryParams: route.queryParams}
      );
      // Otherwise secure page other than default attempted
    } else {
      this.router.navigate(['/landing']);
    }
  }

  /**
   * Redirects to landing page if user is not logged in if requested page is the default kickaround list
   * Otherwise, if the requested url is of a specific secure page (like a kickaround), redirect to the login page.
   * After logging in, the user will navigate back to the original requested secure url.
   */
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    let canAccess;
    if (!this.loginService.isLoggedOn && !this.localStorageService.hasRefreshToken()) {
      this.store.dispatch(new SaveAttemptedSecureUrl(state.url));
      this.rerouteBaseOnRequestedURL(state.url, route);
    }

    if (!this.loginService.isLoggedOn && this.localStorageService.hasRefreshToken()) {
      canAccess = false;

      // Login with refetch token
      await this.store.dispatch(new RefreshTokenLogin()).toPromise()
        .then(r => {
          canAccess = true;
          // Record UX Event
          this.fmas.recordEvent(
            EVENT_CATEGORY_USER,
            USER_NORMAL_AUTO_TOKEN_LOGIN
          );
        })
        .catch(e => {
          canAccess = false;
          this.store.dispatch(new SaveAttemptedSecureUrl(state.url));
          this.rerouteBaseOnRequestedURL(state.url, route);
        });

      // // Get user details
      // await this.store.dispatch(new GetUserDetails()).toPromise()
      //   .then(r => canAccess = true)

      return canAccess;
    } else {
      return true;
    }
  }
}
