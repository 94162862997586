import {Component, Input, OnInit} from '@angular/core';
import {faAngleDoubleRight} from '@fortawesome/free-solid-svg-icons/faAngleDoubleRight';
import {faFutbol} from '@fortawesome/free-solid-svg-icons/faFutbol';
import {faCoins} from '@fortawesome/free-solid-svg-icons/faCoins';
import {faUser} from '@fortawesome/free-solid-svg-icons/faUser';

export class SubNavItem {
  label: string;
  route: any[];
}

@Component({
  selector: 'fm-top-submenu',
  templateUrl: './top-submenu.component.html',
  styleUrls: ['./top-submenu.component.scss']
})
export class TopSubmenuComponent implements OnInit {

  @Input()
  navItems: SubNavItem[];

  faAngleRight = faAngleDoubleRight;
  faFutbol = faFutbol;
  faCoins = faCoins;
  faUser = faUser;

  constructor() { }

  ngOnInit() {

  }

}
