import BigInteger from './BigInteger';
import {Buffer} from 'buffer';
import CryptoJS from 'crypto-js/core';
import Base64 from 'crypto-js/enc-base64';
import HmacSHA256 from 'crypto-js/hmac-sha256';
import SRPMaths from './SRPMaths';
import {HttpClient} from '@angular/common/http';
import {LocalStorageService} from './local-storage.service';
import {CognitoAction, CognitoService} from './cognito.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Subject} from 'rxjs';

;
@Injectable({
  providedIn: 'root',
})
export default class DeviceAuthService {
  private tokensReceived = new Subject<any>();

  constructor(private http: HttpClient,
              private localStorageService: LocalStorageService,
              private cognitoService: CognitoService) {
  }

  authDevice(username, deviceKey, deviceGroupKey, clientId): Observable<any> {
    return new Observable((observer) => {
      try {
        this.getDeviceResponse(username, deviceKey, deviceGroupKey, clientId);
        this.tokensReceived.asObservable().subscribe(tokens => {
            observer.next(tokens);
            observer.complete();
          },
          error1 => {
            observer.error(error1);
            observer.complete();
          });
      } catch (err) {
        observer.error(err);
        observer.complete();
      }
    });
  }

  getDeviceResponse(username, deviceKey, deviceGroupKey, clientId) {
    const srpMaths = new SRPMaths(deviceGroupKey);
    const authParameters: any = {};
    const randomPassword = this.localStorageService.getValueForKey('dkp');
    authParameters.USERNAME = username;
    authParameters.DEVICE_KEY = deviceKey;
    srpMaths.getLargeAValue((errAValue, aValue) => {
      authParameters.SRP_A = aValue.toString(16);
      const jsonReq = {
        ChallengeName: 'DEVICE_SRP_AUTH',
        ClientId: clientId,
        ChallengeResponses: authParameters
      };

      this.http.post(this.cognitoService.BASE_URL,
        jsonReq,
        {headers: this.cognitoService.getCommonHeaders(CognitoAction.ACTION_RESPOND_TO_AUTH_CHALLENGE)}).subscribe(
        (data: any) => {
          const challengeParameters = data.ChallengeParameters;
          const serverBValue = new BigInteger(challengeParameters.SRP_B, 16);
          const salt = new BigInteger(challengeParameters.SALT, 16);

          srpMaths.getPasswordAuthenticationKey(
            deviceKey,
            randomPassword,
            serverBValue,
            salt,
            (errHkdf, hkdf) => {
              const dateNow = srpMaths.getNowString();
              const message = CryptoJS.lib.WordArray.create(
                Buffer.concat([
                  Buffer.from(deviceGroupKey, 'utf8'),
                  Buffer.from(deviceKey, 'utf8'),
                  Buffer.from(challengeParameters.SECRET_BLOCK, 'base64'),
                  Buffer.from(dateNow, 'utf8'),
                ])
              );
              const key = CryptoJS.lib.WordArray.create(hkdf);
              const signatureString = Base64.stringify(HmacSHA256(message, key));
              const challengeResponses: any = {};
              challengeResponses.USERNAME = username;
              challengeResponses.PASSWORD_CLAIM_SECRET_BLOCK = challengeParameters.SECRET_BLOCK;
              challengeResponses.TIMESTAMP = dateNow;
              challengeResponses.PASSWORD_CLAIM_SIGNATURE = signatureString;
              challengeResponses.DEVICE_KEY = deviceKey;

              const jsonReqResp = {
                ChallengeName: 'DEVICE_PASSWORD_VERIFIER',
                ClientId: clientId,
                ChallengeResponses: challengeResponses,
                Session: data.Session,
              };

              this.http.post(this.cognitoService.BASE_URL,
                jsonReqResp,
                {headers: this.cognitoService.getCommonHeaders(CognitoAction.ACTION_RESPOND_TO_AUTH_CHALLENGE)}).subscribe(
                (tokens: any) => {
                  this.tokensReceived.next(tokens);
                }, (error: any) => {
                  this.tokensReceived.error(error);
                }
              );
              return undefined;
            }
          );
          return undefined;
        }, error1 => {
          this.tokensReceived.error(error1);
        });
    });
  }

  processSMSVerifierCode(confirmationCode, username, clientId, session, userPoolId) {
    const challengeResponses: any = {};
    challengeResponses.USERNAME = username;
    challengeResponses.SMS_MFA_CODE = confirmationCode;

    const jsonReq = {
      ChallengeName: 'SMS_MFA',
      ChallengeResponses: challengeResponses,
      ClientId: clientId,
      Session: session,
    };

    this.http.post(this.cognitoService.BASE_URL,
      jsonReq,
      {headers: this.cognitoService.getCommonHeaders(CognitoAction.ACTION_RESPOND_TO_AUTH_CHALLENGE)}).subscribe(
      dataResponse => {
        this.confirmNewDevice(username, dataResponse, userPoolId);
      }, err => {
      });
  }

  confirmNewDevice(username, dataAuthenticate, userPoolId) {
    const authenticationHelper = new SRPMaths(userPoolId.split('_')[1]);
    authenticationHelper.generateHashDevice(
      dataAuthenticate.AuthenticationResult.NewDeviceMetadata.DeviceGroupKey,
      dataAuthenticate.AuthenticationResult.NewDeviceMetadata.DeviceKey,
      (errGenHash) => {
        const deviceSecretVerifierConfig = {
          Salt: new Buffer(
            authenticationHelper.getSaltDevices(), 'hex'
          ).toString('base64'),
          PasswordVerifier: new Buffer(
            authenticationHelper.getVerifierDevices(), 'hex'
          ).toString('base64'),
        };
        const accessToken = dataAuthenticate.AuthenticationResult.AccessToken;
        const jsonReq = {
          DeviceKey: dataAuthenticate.AuthenticationResult.NewDeviceMetadata.DeviceKey,
          AccessToken: accessToken,
          DeviceSecretVerifierConfig: deviceSecretVerifierConfig,
          DeviceName: navigator.userAgent,
        };
        this.http.post(this.cognitoService.BASE_URL, jsonReq,
          {headers: this.cognitoService.getCommonHeaders(CognitoAction.ACTION_CONFIRM_DEVICE)}).subscribe(
          dataResponse => {
            this.localStorageService.saveValueForKey('dgk', dataAuthenticate.AuthenticationResult.NewDeviceMetadata.DeviceGroupKey);
            this.localStorageService.saveValueForKey('dk', dataAuthenticate.AuthenticationResult.NewDeviceMetadata.DeviceKey);
            this.localStorageService.saveValueForKey('dkp', authenticationHelper.getRandomPassword());
          }, err => {
          });
      });
  }
}


