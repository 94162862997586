import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, Scroll} from '@angular/router';
import {VERSION} from '../environments/buildversion';
import {ProgressService} from './features/progress/services/progress.service';
import {Subscription} from 'rxjs';

import {SportConfig} from '../environments/models/sport.model';
import {sportConfig} from '../environments/sport';
import {DOCUMENT, ViewportScroller} from '@angular/common';
import {LocalStorageService} from './features/user/services/local-storage.service';
import {NGXLogger} from 'ngx-logger';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import {FMAnalyticsService} from './features/analytics/fm-analytics.service';
import {OpenReplayService} from "./shared/services/fm-openreplay-service";
import {FMOnboardingService} from "./features/analytics/fm-onboarding.service";
import {MixpanelService} from "./features/analytics/mixpanel.service";

declare let fbq: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy, OnInit {
  title = 'app';
  subscriptions: Subscription[] = [];

  // Progress Spinner
  show = false;
  message = '';

  // Add sport specific css
  sportConfig: SportConfig;

  // Used for knowing when to restore scroll position
  firstRouteLoaded = false;

  // Used for cookie consent:
  angularLoaded = false;
  cookieAccepted = false;

  constructor(
    private router: Router,
    private logger: NGXLogger,
    private localStorageService: LocalStorageService,
    // private swUpdater: FMSWUpdaterService,
    @Inject(DOCUMENT) private document: any,
    public loadingProgressService: ProgressService,
    public cd: ChangeDetectorRef,
    private fmAnalytics: FMAnalyticsService,
    private fmOnboardingService: FMOnboardingService,
    private openReplayService: OpenReplayService,
  ) {
    // Set up route listening event handling
    // this.subscriptions.push(router.events.subscribe((routerEvent) => {this.checkRouterEvent(routerEvent); }));

    // Set up Sport specific CSS
    this.sportConfig = sportConfig;

    // // Correct scroll position on landing after scully transfer state.
    // this.router.events.pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd)
    // ).subscribe((e) => {
    //   if ( e.url === '/') {
    //     try {
    //       this.viewportScroller.scrollToPosition(window['app-component-state'].scrollPosition);
    //     } catch (e) {
    //       this.logger.error('Unable to correct scroll position');
    //     }
    //   }
    // });
    this.openReplayService.start()
    this.logger.setCustomParams(new HttpParams()
      .set('FMPLAYERID', 'NONE')
      .set('FETYPE',  'ANGULAR-WEB')
      .set('HASH',  VERSION.hash)
      .set('VERSION',  VERSION.version)
    );
  }

  ngOnInit(): void {
    // this.performanceService.registerAngularInitTime();
    this.subscriptions.push(this.loadingProgressService.getUpdate().subscribe(d => {
      this.message = d.message;
      this.show = d.show;
      this.cd.detectChanges();
    }));

    // Fire off event to google analytics
    this.cookieAccepted = this.localStorageService.getValueForKey('COOKIE_WARNING_ACCEPTED') === 'YES';
    this.fmAnalytics.init();
  }

  checkRouterEvent(routerEvent): void {
    // if (routerEvent instanceof NavigationStart) {
    //   this.loadingProgressService.showLoadingSpinner('loading data ...');
    // }

    if (routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError) {
      // this.loadingProgressService.hideLoadingSpinner();

      // // Scroll to top on router navigation (Due to scully can't use restoreScrollPostion on router)
      // if (this.firstRouteLoaded) {
      //   window.scrollTo(0, 0);
      // }
      // this.firstRouteLoaded = true;

      // Update Trengo
      // if (window['Trengo'] && window['Trengo']['Api']) {
      //   window['Trengo'].Api.Widget.url_updated();
      // }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onCookieAccepted(cookieAcceptedStatus): void {
    this.cookieAccepted = cookieAcceptedStatus === true;
    cookieAcceptedStatus = cookieAcceptedStatus === true ? 'YES' : 'NO';
    this.localStorageService.saveValueForKey('COOKIE_WARNING_ACCEPTED', cookieAcceptedStatus);
  }

  // ngAfterViewChecked() {
  //   this.performanceService.incrementChangeDetectionCount();
  // }

}

