import {deserialize} from 'cerialize';

export class OpenDataSite {
  @deserialize id: number;
  @deserialize name: string;
  @deserialize distance: string;
  @deserialize address: string;
  @deserialize posttown: string;
  @deserialize postcode: string;
  @deserialize longitude: number;
  @deserialize latitude: number;
  @deserialize email: string;
  @deserialize website: string;
  @deserialize phone: string;
  @deserialize easting?: number;
  @deserialize northing?: number;
  @deserialize halllength?: number;
  @deserialize hallwidth?: number;
  @deserialize hallarea?: number;
  @deserialize hallcount?: number;
  @deserialize odauditedon?: number;
  @deserialize updatedon?: number;
  @deserialize sitejson?: any;
  @deserialize grasspitchcount?: number;
  @deserialize artificialgrasspitchcount?: number;

  constructor(id: number, name: string, distance: string, address: string, posttown: string, postcode: string, longitude: number, latitude: number, email: string, website: string, phone: string, easting: number, northing: number, halllength: number, hallwidth: number, hallarea: number, hallcount: number, odauditedon: number, updatedon: number, sitejson: any, grasspitchcount: number, artificialgrasspitchcount: number) {
    this.id = id;
    this.name = name;
    this.distance = distance;
    this.address = address;
    this.posttown = posttown;
    this.postcode = postcode;
    this.longitude = longitude;
    this.latitude = latitude;
    this.email = email;
    this.website = website;
    this.phone = phone;
    this.easting = easting;
    this.northing = northing;
    this.halllength = halllength;
    this.hallwidth = hallwidth;
    this.hallarea = hallarea;
    this.hallcount = hallcount;
    this.odauditedon = odauditedon;
    this.updatedon = updatedon;
    this.sitejson = sitejson;
    this.grasspitchcount = grasspitchcount;
    this.artificialgrasspitchcount = artificialgrasspitchcount;
  }
}











