import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Router} from '@angular/router';

import {Observable} from 'rxjs';
import {delay, retryWhen, scan} from 'rxjs/operators';

import {LoginService} from '../../features/user/services/login.service';
import {ProgressService} from '../../features/progress/services/progress.service';
import {NGXLogger} from 'ngx-logger';

@Injectable()
export class HttpTimeoutInterceptor implements HttpInterceptor {

  RETRY_INTERVAL_MS = 5000;
  MAX_NUMBER_OF_RETRIES = 3;
  HTTP_ERROR_NO_INTERNET = 0;

  constructor(private loginService: LoginService,
              private router: Router,
              private loadingProgressService: ProgressService,
              private logger: NGXLogger) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   this.logger.info('*INTERCEPT* http timeout handler');
   return next.handle(req).pipe(
     retryWhen(
    (httpError) => {
          return httpError.pipe(
            scan(
          (attemptCount: number, currentHttpError: HttpErrorResponse) => {
            if (currentHttpError.status === this.HTTP_ERROR_NO_INTERNET) {
                this.logger.info('Intercepted no internet http response code : ' + currentHttpError.status);
                attemptCount++;
                if (attemptCount <= this.MAX_NUMBER_OF_RETRIES) {
                  if (!this.loadingProgressService.loadingInProgress) {
                    this.loadingProgressService.showLoadingSpinner('Having trouble accessing the internet, retrying (attempt ' + attemptCount + ' of ' + this.MAX_NUMBER_OF_RETRIES + ') ...');
                  } else {
                    this.loadingProgressService.updateMessage('Having trouble accessing the internet, retrying (attempt  ' + attemptCount + ' of ' + this.MAX_NUMBER_OF_RETRIES + ') ...');
                  }
                  return attemptCount;
                } else {
                    this.loadingProgressService.displayMessage = 'We couldn\'t connect to the internet, please try refreshing the page when you have access.';
                    currentHttpError['error']['message'] = 'No internet, please retry later';
                    throw currentHttpError;
                }
              } else {
                throw currentHttpError;
              }
            }, 0),
            delay(this.RETRY_INTERVAL_MS),
          );
        }
      )
    );
  }

  logout() {
   this.logger.info('simulated logout');
    // hook for logging out when cognito refetch authentication fails (most likely when refetch token expires)
  }
}


