import { Injectable } from '@angular/core';
import {MatLegacySnackBar as MatSnackBar, MatLegacySnackBarConfig as MatSnackBarConfig} from '@angular/material/legacy-snack-bar';

import { SnackBarComponent } from './snack-bar.component';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(public snackBar: MatSnackBar) { }

  open(msg: string, action?: string) {
    this.snackBar.openFromComponent(SnackBarComponent, {
        data: {msg: msg, action: action, isDismissable: false },
        duration: 2000,
      });
  }

  openContinuous(msg: string, action?: string) {
    this.snackBar.openFromComponent(SnackBarComponent, {
        data: {msg: msg, action: action, isDismissable: true },
      });
  }

  openWithAction(msg: string, action: string) {
    const config: MatSnackBarConfig = {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      duration: 3000,
    };
    return this.snackBar.open(msg, action, config);
  }

  dismis() {
    this.snackBar.dismiss();
  }
}
